import { AppReducer } from 'store';
import { AddressModel } from 'services/api/ApiClient';

export enum WizardStep {
    BasicInfo = 'BasicInfo',
    Location = 'Location',
    RoomConfiguration = 'RoomConfiguration',
}

const unloadedState = () => {
    return {
        currentStep: WizardStep.BasicInfo as WizardStep,
        name: '' as string,
        description: '' as string,
        date: undefined as Date | undefined,
        customUrl: undefined as string | undefined,
        address: undefined as AddressModel | undefined,
        proDomainId: undefined as number | undefined,
        templateId: undefined as number | undefined,
        themeId: 1 as number,
        isFetching: false as boolean,
    } as const;
};

export type EventWizardState = ReturnType<typeof unloadedState>;

export const EventWizardReducer: AppReducer<EventWizardState> = (state = unloadedState(), action) => {
    switch (action.type) {
        case '@EVENT_WIZARD/GO_TO_STEP':
            return {
                ...state,
                currentStep: action.step,
            };

        case '@EVENT_WIZARD/MERGE_PROPERTIES':
            return {
                ...state,
                ...action.properties,
            };

        case '@EVENT_WIZARD/CREATE_EVENT_REQUEST':
            return {
                ...state,
                isFetching: true,
            };

        case '@EVENT_WIZARD/CREATE_EVENT_SUCCESS':
            return {
                ...state,
                ...unloadedState(),
            };

        case '@EVENT_WIZARD/CREATE_EVENT_FAILURE':
            return {
                ...state,
                isFetching: false,
            };

        case '@PRO_DOMAINS/SEARCH_PRO_DOMAINS_SUCCESS':
            return {
                ...state,
                address: undefined,
            };

        default:
            return state;
    }
};
