import { normalize } from 'normalizr';
import { MenuApi } from 'services/api';
import { JsonMenu } from 'services/api/ApiClient';
import { ApiSchema } from 'services/api/ApiSchema';
import { Logger } from 'services/Logger';
import { PromiseStore } from 'services/PromiseStore';
import { SSRService } from 'services/ServerSideRenderingService';
import { AppThunkAction } from 'store';
import { mergeEntities } from 'store/normalizr/actions';
import {
    createGetMenuRequestAction,
    createGetMenuRequestFailureAction,
    createGetMenuRequestSuccessAction,
    createUpdateMenuRequestAction,
    createUpdateMenuRequestFailureAction,
    createUpdateMenuRequestSuccessAction,
} from './actions';

export const getMenu = (eventId: number): AppThunkAction<boolean> => (dispatch, getState) => {
    const state = getState();

    const request = state.menus.menuDetailsRequests[eventId];
    if (request && (request.isFetching || !request.didInvalidate)) {
        return PromiseStore.get('getMenu', eventId);
    }

    dispatch(createGetMenuRequestAction(eventId));

    const fetchTask = MenuApi
        .load(eventId)
        .then((data) => {
            const normalizedData = normalize(data, ApiSchema.JsonMenuSchema);
            dispatch(mergeEntities(normalizedData.entities));
            dispatch(createGetMenuRequestSuccessAction(eventId, normalizedData.result));
            return !data.title
            && !data.appetizer1
            && !data.appetizer2
            && !data.starter1
            && !data.starter2
            && !data.starter3
            && !data.mainCourse1
            && !data.mainCourse2
            && !data.mainCourse3
            && !data.dessert1
            && !data.dessert2
            && !data.dessert3
            && !data.drink1
            && !data.drink2
            && !data.drink3
            && !data.drink4;
        })
        .catch((error: Error) => {
            dispatch(createGetMenuRequestFailureAction(eventId));
            Logger.logError(error);
            throw error;
        });

    SSRService.addTask(fetchTask, 'getMenu');
    PromiseStore.set(fetchTask, 'getMenu', eventId);

    return fetchTask;
};

export const updateMenu = (eventId: number, menu: JsonMenu): AppThunkAction => (dispatch, getState) => {
    const state = getState();

    const request = state.menus.menuUpdateRequests[eventId];
    if (request) {
        return PromiseStore.get('updateMenu', eventId);
    }

    dispatch(createUpdateMenuRequestAction(eventId));

    menu.dinnerId = eventId;
    const fetchTask = MenuApi
        .save(eventId, menu)
        .then((data) => {
            menu.id = data.id;
            const normalizedData = normalize(menu, ApiSchema.JsonMenuSchema);
            dispatch(mergeEntities(normalizedData.entities));
            dispatch(createUpdateMenuRequestSuccessAction(eventId, menu.id));
        })
        .catch((error: Error) => {
            dispatch(createUpdateMenuRequestFailureAction(eventId));
            Logger.logError(error);
            throw error;
        });

    SSRService.addTask(fetchTask, 'updateMenu');
    PromiseStore.set(fetchTask, 'updateMenu', eventId);

    return fetchTask;
};
