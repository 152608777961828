import { SPResolverGroupType } from 'services/api/ApiClient';
import { AppReducer } from 'store';
import { IRequest } from 'store/SharedModels';

export enum ErrorLevel {
    Critical = 'Critical',
    High = 'High',
    Low = 'Low',
}

export interface IGroupRequest extends IRequest {
    readonly groups: number[];
}


export interface IImproveRequest extends IRequest {
    readonly improvementResult?: string;
}

const unloadedState = () => {
    return {
        constraintReportsRequests: {} as { readonly [eventId: number]: IRequest },
        fetchConfigurationRequests: {} as { readonly [eventId: number]: IRequest },
        saveConfigurationRequests: {} as { readonly [eventId: number]: boolean },
        nextUsageDate: undefined as Date | undefined,
        displayedErrorLevels: [ErrorLevel.Critical, ErrorLevel.High, ErrorLevel.Low] as ErrorLevel[],
        collapsedErrorList: [] as string[],
        fetchGroupsRequests: {} as { readonly [eventId: number]: IGroupRequest },
        collapsedGroupList: [SPResolverGroupType.InCouple] as SPResolverGroupType[],
        updateGroupRequests: {} as { readonly [groupId: number]: boolean },
        createGroupRequests: {} as { readonly [eventId: number]: boolean },
        deleteGroupRequests: {} as { readonly [groupId: number]: boolean },
        improveRequests: {} as { readonly [eventId: number]: IImproveRequest },
        updateGuestAffinityRequests: {} as { readonly [guestId: number]: boolean },
    } as const;
};

export type ConstraintReportsState = ReturnType<typeof unloadedState>;

export const ConstraintReportsReducer: AppReducer<ConstraintReportsState> = (state = unloadedState(), action) => {
    switch (action.type) {
        case '@CONSTRAINT_REPORTS/GET_CONSTRAINT_REPORTS_REQUEST':
            return {
                ...state,
                constraintReportsRequests: {
                    ...state.constraintReportsRequests,
                    [action.eventId]: {
                        ...state.constraintReportsRequests[action.eventId],
                        didInvalidate: state.constraintReportsRequests[action.eventId]?.didInvalidate || false,
                        isFetching: true,
                    },
                },
            };

        case '@CONSTRAINT_REPORTS/INVALIDATE_CONSTRAINT_REPORTS_REQUEST':
            return {
                ...state,
                constraintReportsRequests: {
                    ...state.constraintReportsRequests,
                    [action.eventId]: {
                        didInvalidate: true,
                        isFetching: false,
                    },
                },
            };

        case '@CONSTRAINT_REPORTS/GET_CONSTRAINT_REPORTS_SUCCESS':
            return {
                ...state,
                constraintReportsRequests: {
                    ...state.constraintReportsRequests,
                    [action.eventId]: {
                        ...state.constraintReportsRequests[action.eventId],
                        isFetching: false,
                        didInvalidate: false,
                    },
                },
                nextUsageDate: action.nextUsageDate,
            };

        case '@CONSTRAINT_REPORTS/GET_CONSTRAINT_REPORTS_FAILURE':
        case '@TEMPLATES/APPLY_TEMPLATE_SUCCESS':
        case '@SEATING_PLAN/DELETE_TABLE_SUCCESS':
        case '@SEATING_PLAN/CREATE_TABLE_SUCCESS':
        case '@SEATING_PLAN/UPDATE_TABLE_SUCCESS':
        case '@GUESTS/DELETE_GUEST_SUCCESS':
        case '@SEATING_PLAN/DELETE_TABLE_MODIFY_SUCCESS':
        case '@SEATING_PLAN/CREATE_TABLE_MODIFY_SUCCESS':
        case '@SEATING_PLAN/UPDATE_TABLE_MODIFY_SUCCESS':
            return {
                ...state,
                constraintReportsRequests: {
                    ...state.constraintReportsRequests,
                    [action.eventId]: {
                        ...state.constraintReportsRequests[action.eventId],
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        case '@CONSTRAINT_REPORTS/TOGGLE_ERROR_LEVEL':
            {
                let displayedErrorLevels = [...state.displayedErrorLevels];
                if (displayedErrorLevels.includes(action.level)) {
                    displayedErrorLevels = displayedErrorLevels.filter((l) => l !== action.level);
                } else {
                    displayedErrorLevels.push(action.level);
                }
                return {
                    ...state,
                    displayedErrorLevels,
                };
            }

        case '@CONSTRAINT_REPORTS/TOGGLE_ERROR_LIST':
            {
                let collapsedErrorList = [...state.collapsedErrorList];
                if (collapsedErrorList.includes(action.listName)) {
                    collapsedErrorList = collapsedErrorList.filter((l) => l !== action.listName);
                } else {
                    collapsedErrorList.push(action.listName);
                }
                return {
                    ...state,
                    collapsedErrorList,
                };
            }

        case '@CONSTRAINT_REPORTS/GET_CONFIGURATION_REQUEST':
            return {
                ...state,
                fetchConfigurationRequests: {
                    ...state.fetchConfigurationRequests,
                    [action.eventId]: {
                        ...state.fetchConfigurationRequests[action.eventId],
                        didInvalidate: state.fetchConfigurationRequests[action.eventId]?.didInvalidate || false,
                        isFetching: true,
                    },
                },
            };

        case '@CONSTRAINT_REPORTS/GET_CONFIGURATION_SUCCESS':
            return {
                ...state,
                fetchConfigurationRequests: {
                    ...state.fetchConfigurationRequests,
                    [action.eventId]: {
                        ...state.fetchConfigurationRequests[action.eventId],
                        isFetching: false,
                        didInvalidate: false,
                    },
                },
            };

        case '@CONSTRAINT_REPORTS/GET_CONFIGURATION_FAILURE':
            return {
                ...state,
                fetchConfigurationRequests: {
                    ...state.fetchConfigurationRequests,
                    [action.eventId]: {
                        ...state.fetchConfigurationRequests[action.eventId],
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        case '@CONSTRAINT_REPORTS/SAVE_CONFIGURATION_REQUEST':
            return {
                ...state,
                saveConfigurationRequests: {
                    ...state.saveConfigurationRequests,
                    [action.eventId]: true,
                },
            };

        case '@CONSTRAINT_REPORTS/SAVE_CONFIGURATION_SUCCESS':
            return {
                ...state,
                saveConfigurationRequests: {
                    ...state.saveConfigurationRequests,
                    [action.eventId]: false,
                },
            };

        case '@CONSTRAINT_REPORTS/SAVE_CONFIGURATION_FAILURE':
            return {
                ...state,
                saveConfigurationRequests: {
                    ...state.saveConfigurationRequests,
                    [action.eventId]: false,
                },
            };

        case '@CONSTRAINT_REPORTS/GET_AFFINITY_GROUPS_REQUEST':
            return {
                ...state,
                fetchGroupsRequests: {
                    ...state.fetchGroupsRequests,
                    [action.eventId]: {
                        ...state.fetchGroupsRequests[action.eventId],
                        groups: state.fetchGroupsRequests[action.eventId]?.groups || [],
                        didInvalidate: state.fetchGroupsRequests[action.eventId]?.didInvalidate || false,
                        isFetching: true,
                    },
                },
            };

        case '@CONSTRAINT_REPORTS/GET_AFFINITY_GROUPS_SUCCESS':
            return {
                ...state,
                fetchGroupsRequests: {
                    ...state.fetchGroupsRequests,
                    [action.eventId]: {
                        ...state.fetchGroupsRequests[action.eventId],
                        isFetching: false,
                        didInvalidate: false,
                        groups: action.groups,
                    },
                },
            };

        case '@CONSTRAINT_REPORTS/GET_AFFINITY_GROUPS_FAILURE':
            return {
                ...state,
                fetchGroupsRequests: {
                    ...state.fetchGroupsRequests,
                    [action.eventId]: {
                        ...state.fetchGroupsRequests[action.eventId],
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        case '@CONSTRAINT_REPORTS/TOGGLE_GROUP_LIST':
            {
                let collapsedGroupList = [...state.collapsedGroupList];
                if (collapsedGroupList.includes(action.listType)) {
                    collapsedGroupList = collapsedGroupList.filter((l) => l !== action.listType);
                } else {
                    collapsedGroupList.push(action.listType);
                }
                return {
                    ...state,
                    collapsedGroupList,
                };
            }

        case '@CONSTRAINT_REPORTS/UPDATE_GROUP_REQUEST':
            return {
                ...state,
                updateGroupRequests: {
                    ...state.updateGroupRequests,
                    [action.groupId]: true,
                },
            };

        case '@CONSTRAINT_REPORTS/UPDATE_GROUP_SUCCESS':
            return {
                ...state,
                updateGroupRequests: {
                    ...state.updateGroupRequests,
                    [action.groupId]: false,
                },
            };

        case '@CONSTRAINT_REPORTS/UPDATE_GROUP_FAILURE':
            return {
                ...state,
                updateGroupRequests: {
                    ...state.updateGroupRequests,
                    [action.groupId]: false,
                },
            };

        case '@CONSTRAINT_REPORTS/CREATE_GROUP_REQUEST':
            return {
                ...state,
                createGroupRequests: {
                    ...state.createGroupRequests,
                    [action.eventId]: true,
                },
            };

        case '@CONSTRAINT_REPORTS/CREATE_GROUP_SUCCESS':
            return {
                ...state,
                createGroupRequests: {
                    ...state.createGroupRequests,
                    [action.eventId]: false,
                },
                fetchGroupsRequests: {
                    ...state.fetchGroupsRequests,
                    [action.eventId]: {
                        ...state.fetchGroupsRequests[action.eventId],
                        groups: [action.groupId, ...state.fetchGroupsRequests[action.eventId].groups],
                    },
                },
            };

        case '@CONSTRAINT_REPORTS/CREATE_GROUP_FAILURE':
            return {
                ...state,
                createGroupRequests: {
                    ...state.createGroupRequests,
                    [action.eventId]: false,
                },
            };

        case '@CONSTRAINT_REPORTS/DELETE_GROUP_REQUEST':
            return {
                ...state,
                deleteGroupRequests: {
                    ...state.deleteGroupRequests,
                    [action.groupId]: true,
                },
            };

        case '@CONSTRAINT_REPORTS/DELETE_GROUP_SUCCESS':
            return {
                ...state,
                deleteGroupRequests: {
                    ...state.deleteGroupRequests,
                    [action.groupId]: false,
                },
                fetchGroupsRequests: {
                    ...state.fetchGroupsRequests,
                    [action.eventId]: {
                        ...state.fetchGroupsRequests[action.eventId],
                        groups: state.fetchGroupsRequests[action.eventId].groups.filter((id) => id !== action.groupId),
                    },
                },
            };

        case '@CONSTRAINT_REPORTS/DELETE_GROUP_FAILURE':
            return {
                ...state,
                deleteGroupRequests: {
                    ...state.deleteGroupRequests,
                    [action.groupId]: false,
                },
            };

        case '@CONSTRAINT_REPORTS/IMPROVE_SEATING_PLAN_REQUEST':
            return {
                ...state,
                improveRequests: {
                    ...state.improveRequests,
                    [action.eventId]: {
                        ...state.improveRequests[action.eventId],
                        didInvalidate: state.improveRequests[action.eventId]?.didInvalidate || false,
                        isFetching: true,
                    },
                },
            };

        case '@CONSTRAINT_REPORTS/IMPROVE_SEATING_PLAN_SUCCESS':
            return {
                ...state,
                improveRequests: {
                    ...state.improveRequests,
                    [action.eventId]: {
                        ...state.improveRequests[action.eventId],
                        isFetching: false,
                        didInvalidate: false,
                        improvementResult: action.improvementResult,
                    },
                },
                nextUsageDate: action.nextUsageDate,
            };

        case '@CONSTRAINT_REPORTS/IMPROVE_SEATING_PLAN_FAILURE':
            return {
                ...state,
                improveRequests: {
                    ...state.improveRequests,
                    [action.eventId]: {
                        ...state.improveRequests[action.eventId],
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        case '@CONSTRAINT_REPORTS/INVALIDATE_IMPROVEMENT_RESULT':
            return {
                ...state,
                improveRequests: {
                    ...state.improveRequests,
                    [action.eventId]: {
                        ...state.improveRequests[action.eventId],
                        didInvalidate: true,
                    },
                },
            };

        case '@CONSTRAINT_REPORTS/ADD_GUEST_AFFINITY_REQUEST':
        case '@CONSTRAINT_REPORTS/REMOVE_GUEST_AFFINITY_REQUEST':
            return {
                ...state,
                updateGuestAffinityRequests: {
                    ...state.updateGuestAffinityRequests,
                    [action.guestId]: true,
                },
            };

        case '@CONSTRAINT_REPORTS/ADD_GUEST_AFFINITY_SUCCESS':
        case '@CONSTRAINT_REPORTS/REMOVE_GUEST_AFFINITY_SUCCESS':
            return {
                ...state,
                updateGuestAffinityRequests: {
                    ...state.updateGuestAffinityRequests,
                    [action.guestId]: false,
                },
                fetchGroupsRequests: {
                    ...state.fetchGroupsRequests,
                    [action.eventId]: {
                        ...state.fetchGroupsRequests[action.eventId],
                        didInvalidate: true,
                    },
                },
            };

        case '@CONSTRAINT_REPORTS/ADD_GUEST_AFFINITY_FAILURE':
        case '@CONSTRAINT_REPORTS/REMOVE_GUEST_AFFINITY_FAILURE':
            return {
                ...state,
                updateGuestAffinityRequests: {
                    ...state.updateGuestAffinityRequests,
                    [action.guestId]: false,
                },
            };

        case '@SIGNALR/CONSTRAINT_REPORT_CONFIGURATION_UPDATED':
            return {
                ...state,
                constraintReportsRequests: {
                    ...state.constraintReportsRequests,
                    [action.payload.eventId]: {
                        ...state.constraintReportsRequests[action.payload.eventId],
                        didInvalidate: true,
                    },
                },
                fetchConfigurationRequests: {
                    ...state.fetchConfigurationRequests,
                    [action.payload.eventId]: {
                        ...state.fetchConfigurationRequests[action.payload.eventId],
                        didInvalidate: true,
                    },
                },
            };

        case '@SIGNALR/CONSTRAINT_REPORT_GROUP_UPDATED':
        case '@SIGNALR/CONSTRAINT_REPORT_GROUP_CREATED':
        case '@SIGNALR/CONSTRAINT_REPORT_GROUP_DELETED':
            return {
                ...state,
                constraintReportsRequests: {
                    ...state.constraintReportsRequests,
                    [action.payload.eventId]: {
                        ...state.constraintReportsRequests[action.payload.eventId],
                        didInvalidate: true,
                    },
                },
                fetchGroupsRequests: {
                    ...state.fetchGroupsRequests,
                    [action.payload.eventId]: {
                        ...state.fetchGroupsRequests[action.payload.eventId],
                        didInvalidate: true,
                    },
                },
            };

        default:
            return state;
    }
};
