import {
    CustomUrlEventInfo,
    DonutIndicator,
    GuestConstraint,
    Info,
    JsonChecklist,
    JsonInformation,
    PaymentRegistration,
    RightsModel,
    SeatingPlanConfiguration,
} from './ApiClient';
import { ObjectsWithoutIdProperty, ObjectsWithoutIdPropertyEnum } from './ApiSchema';

export class BaseApiSchema {
    protected static getId(value: ObjectsWithoutIdProperty, type: ObjectsWithoutIdPropertyEnum): string {
        switch (type) {
            case ObjectsWithoutIdPropertyEnum.CustomUrlEventInfo:
                return `${(value as CustomUrlEventInfo)?.eventId}-${(value as CustomUrlEventInfo)?.customUrl}`;

            case ObjectsWithoutIdPropertyEnum.DonutIndicator:
                return `${(value as DonutIndicator)?.dinnerId}-${(value as DonutIndicator)?.indicatorType}`;

            case ObjectsWithoutIdPropertyEnum.GuestConstraint:
                return `${(value as GuestConstraint)?.type}-${(value as GuestConstraint)?.guestId}-${(value as GuestConstraint)?.relatedGuestId}`;

            case ObjectsWithoutIdPropertyEnum.Info:
                return (value as Info)?.elementId?.toString() || '-';

            case ObjectsWithoutIdPropertyEnum.JsonChecklist:
                return (value as JsonChecklist)?.eventId?.toString() || '-';

            case ObjectsWithoutIdPropertyEnum.JsonInformation:
                return (value as JsonInformation)?.eventId?.toString() || '-';

            case ObjectsWithoutIdPropertyEnum.PaymentLineConfiguration:
                return '-';

            case ObjectsWithoutIdPropertyEnum.PaymentRegistration:
                return (value as PaymentRegistration).paymentIntentId || '-';

            case ObjectsWithoutIdPropertyEnum.RightsModel:
                return `${(value as RightsModel)?.eventId || ''}${(value as RightsModel)?.accessKey ? '-' + (value as RightsModel)?.accessKey : ''}` || '-';

            case ObjectsWithoutIdPropertyEnum.SeatingPlanConfiguration:
                return (value as SeatingPlanConfiguration).eventId.toString() || '-';
        }

        const exhaustiveCheck: never = type;
        return exhaustiveCheck;
    }
}
