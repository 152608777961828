import { createBrowserHistory, createMemoryHistory, History } from 'history';
import { StaticRouterContext } from 'react-router';

export class RouterService {
    public static staticContext: StaticRouterContext = {};
    public static history: History;

    public static createBrowserHistory() {
        RouterService.history = createBrowserHistory();
        return RouterService.history;
    }

    public static createMemoryHistory(urlAfterBasename: string) {
        RouterService.history = createMemoryHistory({
            initialEntries: [urlAfterBasename],
        });
        return RouterService.history;
    }
}
