import i18n from 'i18next';
import { compile } from 'path-to-regexp';
import { getConfiguration } from 'config/constants';
import { ItemType, PresenceType } from 'services/api/ApiClient';

export const RoutePaths = {
    About: {
        route: (lng: string) => `${getConfiguration().externalLinks.mesHotesAccount}${RoutePaths.route()}/${t('Routing:About', lng)}`,
        url: () => RoutePaths.url(RoutePaths.About.route),
    },
    Account: {
        Login: {
            route: (lng: string) => `${RoutePaths.Account.route()}/${t('Routing:Login', lng)}`,
            url: () => RoutePaths.url(RoutePaths.Account.Login.route),
        },
        Logout: {
            route: (lng: string) => `${RoutePaths.Account.route()}/${t('Routing:LogOff', lng)}`,
            url: () => RoutePaths.url(RoutePaths.Account.Logout.route),
        },
        Register: {
            route: (lng: string) => `${RoutePaths.Account.route()}/${t('Routing:Register', lng)}`,
            url: () => RoutePaths.url(RoutePaths.Account.Register.route),
        },
        route: () => `${getConfiguration().externalLinks.mesHotesAccount}${RoutePaths.route()}`,
    },
    Blog: {
        Article: {
            route: (lng: string) => `${RoutePaths.Blog.route(lng)}/:articleKey`,
            url: (articleKey: string) => RoutePaths.url(RoutePaths.Blog.Article.route, { articleKey }),
        },
        route: (lng: string) => `${RoutePaths.route()}/${t('Routing:Blog', lng)}`,
        url: () => RoutePaths.url(RoutePaths.Blog.route),
    },
    Callback: {
        route: (lng: string) => `/${t('Routing:Callback', lng)}`,
        url: () => RoutePaths.url(RoutePaths.Callback.route),
    },
    Contact: {
        route: (lng: string) => `${getConfiguration().externalLinks.mesHotesAccount}${RoutePaths.route()}/${t('Routing:Contact', lng)}`,
        url: () => RoutePaths.url(RoutePaths.Contact.route),
    },
    Errors: {
        NotFound: {
            route: () => {
                return '*';
            },
            url: () => RoutePaths.url(RoutePaths.Errors.NotFound.route),
        },
    },
    Events: {
        Checklist: {
            Add: {
                route: (lng: string) => {
                    return `${RoutePaths.Events.route(lng)}/${t('Routing:Checklist', lng)}/${t('Routing:Add', lng)}`;
                },
                url: (eventId: number) => RoutePaths.url(RoutePaths.Events.Checklist.Add.route, { eventId }),
            },
            AddPredefinedTasks: {
                route: (lng: string) => {
                    return `${RoutePaths.Events.route(lng)}/${t('Routing:Checklist', lng)}/${t('Routing:AddPredefinedTasks', lng)}`;
                },
                url: (eventId: number) => RoutePaths.url(RoutePaths.Events.Checklist.AddPredefinedTasks.route, { eventId }),
            },
            Config: {
                route: (lng: string) => {
                    return `${RoutePaths.Events.route(lng)}/${t('Routing:Checklist', lng)}/${t('Routing:Configuration', lng)}`;
                },
                url: (eventId: number) => RoutePaths.url(RoutePaths.Events.Checklist.Config.route, { eventId }),
            },
            route: (lng: string) => {
                return `${RoutePaths.Events.route(lng)}/${t('Routing:Checklist', lng)}/:itemId(\\d+)?`;
            },
            url: (eventId: number, itemId?: number, customUrl?: string, demoId?: string) => RoutePaths.url(RoutePaths.Events.Checklist.route, { eventId, itemId, customUrl, demoId }),
        },
        Create: {
            route: (lng: string) => `${RoutePaths.route()}/${t('Routing:Events', lng)}/${t('Routing:Create', lng)}`,
            url: (eventId?: number) => RoutePaths.url(RoutePaths.Events.Create.route, { eventId }),
        },
        Delete: {
            route: (lng: string) => `${RoutePaths.Events.route(lng)}/${t('Routing:Delete', lng)}`,
            url: (eventId?: number) => RoutePaths.url(RoutePaths.Events.Delete.route, { eventId }),
        },
        Edit: {
            route: (lng: string) => `${RoutePaths.Events.route(lng)}/${t('Routing:Edit', lng)}`,
            url: (eventId?: number) => RoutePaths.url(RoutePaths.Events.Edit.route, { eventId }),
        },
        GuestAffinities: {
            route: (lng: string) => {
                return `${RoutePaths.Events.route(lng)}/${t('Routing:GuestsCustomizeAffinitiesGroupIndex', lng)}`;
            },
            url: (eventId: number, customUrl?: string) => RoutePaths.url(RoutePaths.Events.GuestAffinities.route, { eventId, customUrl }),
        },
        Guests: {
            Config: {
                route: (lng: string) => {
                    return `${RoutePaths.Events.route(lng)}/${t('Routing:Guest', lng)}/${t('Routing:Configuration', lng)}`;
                },
                url: (eventId: number) => RoutePaths.url(RoutePaths.Events.Guests.Config.route, { eventId }),
            },
            Create: {
                route: (lng: string) => {
                    return `${RoutePaths.Events.route(lng)}/${t('Routing:Guest', lng)}/${t('Routing:Add', lng)}`;
                },
                url: (eventId: number) => RoutePaths.url(RoutePaths.Events.Guests.Create.route, { eventId }),
            },
            Import: {
                Csv: {
                    route: (lng: string) => {
                        return `${RoutePaths.Events.route(lng)}/${t('Routing:Guest', lng)}/${t('Routing:Import', lng)}/csv`;
                    },
                    url: (eventId: number) => RoutePaths.url(RoutePaths.Events.Guests.Import.Csv.route, { eventId }),
                },
                Google: {
                    route: (lng: string) => {
                        return `${RoutePaths.Events.route(lng)}/${t('Routing:Guest', lng)}/${t('Routing:Import', lng)}/google`;
                    },
                    url: (eventId: number) => RoutePaths.url(RoutePaths.Events.Guests.Import.Google.route, { eventId }),
                },
                Microsoft: {
                    route: (lng: string) => {
                        return `${RoutePaths.Events.route(lng)}/${t('Routing:Guest', lng)}/${t('Routing:Import', lng)}/microsoft`;
                    },
                    url: (eventId: number) => RoutePaths.url(RoutePaths.Events.Guests.Import.Microsoft.route, { eventId }),
                },
            },
            RequestPlacementPreferences: {
                route: (lng: string) => {
                    return `${RoutePaths.Events.route(lng)}/${t('Routing:Guest', lng)}/${t('Routing:RequestPlacementPreferences', lng)}`;
                },
                url: (eventId: number) => RoutePaths.url(RoutePaths.Events.Guests.RequestPlacementPreferences.route, { eventId }),
            },
            SendInvitations: {
                route: (lng: string) => {
                    return `${RoutePaths.Events.route(lng)}/${t('Routing:Guest', lng)}/${t('Routing:SendInvitations', lng)}`;
                },
                url: (eventId: number) => RoutePaths.url(RoutePaths.Events.Guests.SendInvitations.route, { eventId }),
            },
            route: (lng: string) => {
                return `${RoutePaths.Events.route(lng)}/${t('Routing:Guest', lng)}/:guestId(\\d+)?`;
            },
            url: (eventId: number, guestId?: number, customUrl?: string, demoId?: string) => RoutePaths.url(RoutePaths.Events.Guests.route, { eventId, guestId, customUrl, demoId }),
        },
        Information: {
            AddPlace: {
                route: (lng: string) => {
                    return `${RoutePaths.Events.route(lng)}/${t('Routing:Information', lng)}/${t('Routing:Places', lng)}/${t('Routing:Add', lng)}`;
                },
                url: (eventId: number) => RoutePaths.url(RoutePaths.Events.Information.AddPlace.route, { eventId }),
            },
            Config: {
                route: (lng: string) => {
                    return `${RoutePaths.Events.route(lng)}/${t('Routing:Information', lng)}/${t('Routing:Configuration', lng)}`;
                },
                url: (eventId: number) => RoutePaths.url(RoutePaths.Events.Information.Config.route, { eventId }),
            },
            Edit: {
                route: (lng: string) => {
                    return `${RoutePaths.Events.route(lng)}/${t('Routing:Information', lng)}/${t('Routing:Edit', lng)}`;
                },
                url: (eventId: number) => RoutePaths.url(RoutePaths.Events.Information.Edit.route, { eventId }),
            },
            EditPlace: {
                route: (lng: string) => {
                    return `${RoutePaths.Events.route(lng)}/${t('Routing:Information', lng)}/${t('Routing:Places', lng)}/:placeId(\\d+)`;
                },
                url: (eventId: number, placeId: number) => RoutePaths.url(RoutePaths.Events.Information.EditPlace.route, { eventId, placeId }),
            },
            route: (lng: string) => {
                return `${RoutePaths.Events.route(lng)}/${t('Routing:Information', lng)}`;
            },
            url: (eventId: number, customUrl?: string, demoId?: string) => RoutePaths.url(RoutePaths.Events.Information.route, { eventId, customUrl, demoId }),
        },
        Invitation: {
            Answer: {
                route: (lng: string) => {
                    return `${RoutePaths.Events.Invitation.route(lng)}/${t('Routing:Answer', lng)}/:presence`;
                },
                url: (eventId: number, presence?: PresenceType.Present | PresenceType.NotPresent | PresenceType.PresentOnlyReception) => RoutePaths.url(RoutePaths.Events.Invitation.Answer.route, { eventId, presence }),
            },
            route: (lng: string) => {
                return `${RoutePaths.Events.route(lng)}/${t('Routing:Invitation', lng)}`;
            },
            url: (eventId: number) => RoutePaths.url(RoutePaths.Events.Invitation.route, { eventId }),
        },
        List: {
            route: (lng: string) => `${RoutePaths.Events.route(lng)}?`,
            url: (eventId?: number, customUrl?: string, demoId?: string) => RoutePaths.url(RoutePaths.Events.List.route, { eventId, customUrl, demoId }),
        },
        Menu: {
            Config: {
                route: (lng: string) => {
                    return `${RoutePaths.Events.route(lng)}/${t('Routing:Menu', lng)}/${t('Routing:Configuration', lng)}`;
                },
                url: (eventId: number) => RoutePaths.url(RoutePaths.Events.Menu.Config.route, { eventId }),
            },
            Edit: {
                route: (lng: string) => {
                    return `${RoutePaths.Events.route(lng)}/${t('Routing:Menu', lng)}/${t('Routing:Edit', lng)}`;
                },
                url: (eventId: number) => RoutePaths.url(RoutePaths.Events.Menu.Edit.route, { eventId }),
            },
            route: (lng: string) => {
                return `${RoutePaths.Events.route(lng)}/${t('Routing:Menu', lng)}`;
            },
            url: (eventId: number, customUrl?: string, demoId?: string) => RoutePaths.url(RoutePaths.Events.Menu.route, { eventId, customUrl, demoId }),
        },
        SeatingPlan: {
            Object: {
                Add: {
                    route: (lng: string) => {
                        return `${RoutePaths.Events.route(lng)}/${t('Routing:SeatingPlan', lng)}/${t('Routing:Objects', lng)}/${t('Routing:Add', lng)}`;
                    },
                    url: (eventId: number) => RoutePaths.url(RoutePaths.Events.SeatingPlan.Object.Add.route, { eventId }),
                },
                route: (lng: string) => {
                    return `${RoutePaths.Events.route(lng)}/${t('Routing:SeatingPlan', lng)}/${t('Routing:Objects', lng)}/:objectId(\\d+)`;
                },
                url: (eventId: number, objectId: number) => RoutePaths.url(RoutePaths.Events.SeatingPlan.Object.route, { eventId, objectId }),
            },
            Print: {
                route: (lng: string) => {
                    return `${RoutePaths.Events.route(lng)}/${t('Routing:SeatingPlan', lng)}/${t('Routing:Print', lng)}`;
                },
                url: (eventId: number) => RoutePaths.url(RoutePaths.Events.SeatingPlan.Print.route, { eventId }),
            },
            Room: {
                Configuration: {
                    route: (lng: string) => {
                        return `${RoutePaths.Events.route(lng)}/${t('Routing:SeatingPlan', lng)}/${t('Routing:Room', lng)}/${t('Routing:Configuration', lng)}`;
                    },
                    url: (eventId: number) => RoutePaths.url(RoutePaths.Events.SeatingPlan.Room.Configuration.route, { eventId }),
                },
            },
            RoomPlan: {
                Search: {
                    route: (lng: string) => {
                        return `${RoutePaths.Events.route(lng)}/${t('Routing:SeatingPlan', lng)}/${t('Routing:RoomPlan', lng)}/${t('Routing:Search', lng)}`;
                    },
                    url: (eventId: number) => RoutePaths.url(RoutePaths.Events.SeatingPlan.RoomPlan.Search.route, { eventId }),
                },
            },
            Seating: {
                Automatic: {
                    AffinityConfiguration: {
                        route: (lng: string) => {
                            return `${RoutePaths.Events.route(lng)}/${t('Routing:SeatingPlan', lng)}/${t('Routing:Seating', lng)}/${t('Routing:Automatic', lng)}/${t('Routing:Configuration', lng)}/${t('Routing:Affinities', lng)}`;
                        },
                        url: (eventId: number) => RoutePaths.url(RoutePaths.Events.SeatingPlan.Seating.Automatic.AffinityConfiguration.route, { eventId }),
                    },
                    Configuration: {
                        route: (lng: string) => {
                            return `${RoutePaths.Events.route(lng)}/${t('Routing:SeatingPlan', lng)}/${t('Routing:Seating', lng)}/${t('Routing:Automatic', lng)}/${t('Routing:Configuration', lng)}`;
                        },
                        url: (eventId: number) => RoutePaths.url(RoutePaths.Events.SeatingPlan.Seating.Automatic.Configuration.route, { eventId }),
                    },
                    PlanPrices: {
                        route: (lng: string) => {
                            return `${RoutePaths.Events.route(lng)}/${t('Routing:SeatingPlan', lng)}/${t('Routing:Seating', lng)}/${t('Routing:Automatic', lng)}/${t('Routing:PlanPrices', lng)}`;
                        },
                        url: (eventId: number) => RoutePaths.url(RoutePaths.Events.SeatingPlan.Seating.Automatic.PlanPrices.route, { eventId }),
                    },
                    route: (lng: string) => {
                        return `${RoutePaths.Events.route(lng)}/${t('Routing:SeatingPlan', lng)}/${t('Routing:Seating', lng)}/${t('Routing:Automatic', lng)}`;
                    },
                    url: (eventId: number) => RoutePaths.url(RoutePaths.Events.SeatingPlan.Seating.Automatic.route, { eventId }),
                },
                Manual: {
                    route: (lng: string) => {
                        return `${RoutePaths.Events.route(lng)}/${t('Routing:SeatingPlan', lng)}/${t('Routing:Seating', lng)}/${t('Routing:Manual', lng)}/:tableId([\\d.]+)?/:chairId([\\d.]+)?`;
                    },
                    url: (eventId: number, tableId?: number, chairId?: number) => RoutePaths.url(RoutePaths.Events.SeatingPlan.Seating.Manual.route, { eventId, tableId, chairId }),
                },
            },
            Templates: {
                route: (lng: string) => {
                    return `${RoutePaths.Events.route(lng)}/${t('Routing:SeatingPlan', lng)}/${t('Routing:Templates', lng)}/:templateId([\\d.]+)?`;
                },
                url: (eventId: number, templateId?: number) => RoutePaths.url(RoutePaths.Events.SeatingPlan.Templates.route, { eventId, templateId }),
            },
            Table: {
                Add: {
                    route: (lng: string) => {
                        return `${RoutePaths.Events.route(lng)}/${t('Routing:SeatingPlan', lng)}/${t('Routing:Tables', lng)}/${t('Routing:Add', lng)}/:left([\\d.]+)?/:top([\\d.]+)?`;
                    },
                    url: (eventId: number, left?: number, top?: number) => RoutePaths.url(RoutePaths.Events.SeatingPlan.Table.Add.route, { eventId, left, top }),
                },
                route: (lng: string) => {
                    return `${RoutePaths.Events.route(lng)}/${t('Routing:SeatingPlan', lng)}/${t('Routing:Tables', lng)}/:tableId(\\d+)`;
                },
                url: (eventId: number, tableId: number) => RoutePaths.url(RoutePaths.Events.SeatingPlan.Table.route, { eventId, tableId }),
            },
            Config: {
                route: (lng: string) => {
                    return `${RoutePaths.Events.route(lng)}/${t('Routing:SeatingPlan', lng)}/${t('Routing:Configuration', lng)}`;
                },
                url: (eventId: number) => RoutePaths.url(RoutePaths.Events.SeatingPlan.Config.route, { eventId }),
            },
            route: (lng: string) => {
                return `${RoutePaths.Events.route(lng)}/${t('Routing:SeatingPlan', lng)}`;
            },
            url: (eventId: number, customUrl?: string, demoId?: string) => RoutePaths.url(RoutePaths.Events.SeatingPlan.route, { eventId, customUrl, demoId }),
        },
        route: (lng: string) => {
            return `${RoutePaths.route()}/${t('Routing:Events', lng)}/:eventId(\\d+)`;
        },
    },
    Forum: {
        route: () => `${getConfiguration().externalLinks.mesHotesForum}`,
        url: () => RoutePaths.url(RoutePaths.Forum.route),
    },
    Help: {
        route: (lng: string) => `${getConfiguration().externalLinks.mesHotesAccount}${RoutePaths.route()}/${t('Routing:Help', lng)}`,
        url: () => RoutePaths.url(RoutePaths.Help.route),
    },
    Home: {
        route: () => `${RoutePaths.route()}?`,
        url: () => RoutePaths.url(RoutePaths.Home.route),
    },
    Legal: {
        LegalNotice: {
            route: (lng: string) => `${RoutePaths.Legal.route(lng)}/${t('Routing:LegalNotices', lng)}`,
            url: () => RoutePaths.url(RoutePaths.Legal.LegalNotice.route),
        },
        Privacy: {
            route: (lng: string) => `${RoutePaths.Legal.route(lng)}/${t('Routing:Privacy', lng)}`,
            url: () => RoutePaths.url(RoutePaths.Legal.Privacy.route),
        },
        TermsOfSales: {
            route: (lng: string) => `${RoutePaths.Legal.route(lng)}/${t('Routing:TermsOfSales', lng)}`,
            url: () => RoutePaths.url(RoutePaths.Legal.TermsOfSales.route),
        },
        TermsOfSalesPro: {
            route: (lng: string) => `${RoutePaths.Legal.route(lng)}/${t('Routing:Pro', lng)}/${t('Routing:TermsOfSales', lng)}`,
            url: () => RoutePaths.url(RoutePaths.Legal.TermsOfSalesPro.route),
        },
        TermsOfUse: {
            route: (lng: string) => `${RoutePaths.Legal.route(lng)}/${t('Routing:TermsOfUse', lng)}`,
            url: () => RoutePaths.url(RoutePaths.Legal.TermsOfUse.route),
        },
        TermsOfUsePro: {
            route: (lng: string) => `${RoutePaths.Legal.route(lng)}/${t('Routing:Pro', lng)}/${t('Routing:TermsOfUse', lng)}`,
            url: () => RoutePaths.url(RoutePaths.Legal.TermsOfUsePro.route),
        },
        route: (lng: string) => `${getConfiguration().externalLinks.mesHotesAccount}${RoutePaths.route()}/${t('Routing:Legal', lng)}`,
    },
    Manage: {
        EmailSubscription: {
            route: (lng: string) => `${RoutePaths.Manage.route()}/${t('Routing:Manage', lng)}/${t('Routing:EmailSubscription', lng)}`,
            url: () => RoutePaths.url(RoutePaths.Manage.EmailSubscription.route),
        },
        MyPurchases: {
            route: (lng: string) => `${RoutePaths.Manage.route()}/${t('Routing:Manage', lng)}/${t('Routing:MyPurchases', lng)}`,
            url: () => RoutePaths.url(RoutePaths.Manage.MyPurchases.route),
        },
        PaymentInformation: {
            route: (lng: string) => `${RoutePaths.Manage.route()}/${t('Routing:Manage', lng)}/${t('Routing:PaymentInformation', lng)}`,
            url: () => RoutePaths.url(RoutePaths.Manage.PaymentInformation.route),
        },
        Profile: {
            route: () => `${RoutePaths.Manage.route()}`,
            url: () => RoutePaths.url(RoutePaths.Manage.Profile.route),
        },
        route: () => `${getConfiguration().externalLinks.mesHotesAccount}${RoutePaths.route()}`,
        url: () => RoutePaths.url(RoutePaths.Manage.route),
    },
    Maps: {
        Directory: {
            Pro: {
                Domain: {
                    route: (lng: string) => `${RoutePaths.Maps.route()}/${t('Routing:Pro', lng)}/${t('Routing:Domain', lng)}/:country/:city/:name/:id(\\d+)`,
                    url: (country: string, city: string, name: string, id: number) => RoutePaths.url(RoutePaths.Maps.Directory.Pro.Domain.route, { country, city, name, id }),
                },
                route: (lng: string) => `${RoutePaths.Maps.route()}/${t('Routing:Pro', lng)}/:featureType/:country/:city/:name/:id(\\d+)`,
                url: (featureType: string, country: string, city: string, name: string, id: number) => RoutePaths.url(RoutePaths.Maps.Directory.Pro.route, { featureType, country, city, name, id }),
            },
            route: (lng: string) => `${RoutePaths.Maps.route()}/${t('Routing:Pro', lng)}/:featureType/:country?/:region?/:county?`,
            url: (featureType: string, country?: string, region?: string, county?: string) => RoutePaths.url(RoutePaths.Maps.Directory.route, { featureType, country, region, county }),
        },
        Map: {
            route: () => `${RoutePaths.Maps.route()}/:mapCenter?`,
            url: (mapCenter?: string) => RoutePaths.url(RoutePaths.Maps.Map.route, { mapCenter }),
        },
        route: () => `${getConfiguration().externalLinks.mesHotesMaps}${RoutePaths.route()}`,
        url: () => RoutePaths.url(RoutePaths.Maps.route),
    },
    Press: {
        route: (lng: string) => `${getConfiguration().externalLinks.mesHotesAccount}${RoutePaths.route()}/${t('Routing:Press', lng)}`,
        url: () => RoutePaths.url(RoutePaths.Press.route),
    },
    Purchase: {
        TransferFund: {
            route: (lng: string) => `${RoutePaths.route()}/${t('Routing:Purchase', lng)}/${t('Routing:TransferFund', lng)}/:itemType/:payerId(\\d+)/:eventId(\\d+)?`,
            url: (itemType: ItemType, payerId: number, eventId?: number) => RoutePaths.url(RoutePaths.Purchase.TransferFund.route, { itemType, payerId, eventId }),
        },
        route: (lng: string) => {
            return `${RoutePaths.route()}/${t('Routing:Purchase', lng)}/:itemType/:itemId(\\d+)/:eventId(\\d+)?`;
        },
        url: (itemType: ItemType, itemId: number, eventId?: number) => RoutePaths.url(RoutePaths.Purchase.route, { itemType, itemId, eventId }),
    },
    RedirectUris: {
        AzureActiveDirectory: {
            route: (lng: string) => `/${t('Routing:AadCallback', lng)}`,
            url: () => RoutePaths.url(RoutePaths.RedirectUris.AzureActiveDirectory.route),
        },
    },
    ServicesPricing: {
        route: (lng: string) => `${getConfiguration().externalLinks.mesHotesAccount}${RoutePaths.route()}/${t('Routing:ServicesPricing', lng)}`,
        url: () => RoutePaths.url(RoutePaths.ServicesPricing.route),
    },

    route: () => {
        return '/:lang([a-zA-Z]{2})';
    },

    url: (route: (lng: string) => string, parameters?: { [id: string]: unknown }) => {
        const { language } = i18n;
        return RoutePaths.urlForLang(language, route, parameters).toLowerCase();
    },

    urlForLang: (language: string, route: (lng: string) => string, parameters?: { [id: string]: unknown }) => {
        let localizedRoute = route(language);
        const routingEvent: string = i18n.t('Routing:Events', { lng: language });
        if (parameters?.demoId) {
            localizedRoute = localizedRoute.replace(`/${routingEvent}/:eventId(\\d+)`, `/demo/:demoId`);
        }
        else if (parameters?.customUrl) {
            localizedRoute = localizedRoute.replace(`/${routingEvent}/:eventId(\\d+)`, `/public/:customUrl`);
        }

        if (localizedRoute.startsWith('http')) {
            localizedRoute = localizedRoute.replace(/(https?):\/\/([a-z.]*):?([\d]+)/i, '$1\\://$2\\:$3');
            localizedRoute = localizedRoute.replace(/(https?):\/\//, '$1\\://');
        }

        return compile(localizedRoute)({
            lang: language,
            ...parameters,
        });
    },
};

const t = (key: string, lng: string) => i18n.t(key, { lng });
