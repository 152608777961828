import { SPResolverGroupType } from 'services/api/ApiClient';
import { ErrorLevel } from './reducer';

export const createGetConstraintReportsRequestAction = (eventId: number) => {
    return {
        type: '@CONSTRAINT_REPORTS/GET_CONSTRAINT_REPORTS_REQUEST',
        eventId,
    } as const;
};

export const createInvalidateConstraintReportsRequestAction = (eventId: number) => {
    return {
        type: '@CONSTRAINT_REPORTS/INVALIDATE_CONSTRAINT_REPORTS_REQUEST',
        eventId,
    } as const;
};

export const createGetConstraintReportsRequestSuccessAction =
    (eventId: number, nextUsageDate?: Date) => {
        return {
            type: '@CONSTRAINT_REPORTS/GET_CONSTRAINT_REPORTS_SUCCESS',
            eventId,
            nextUsageDate,
        } as const;
    };

export const createGetConstraintReportsRequestFailureAction = (eventId: number) => {
    return {
        type: '@CONSTRAINT_REPORTS/GET_CONSTRAINT_REPORTS_FAILURE',
        eventId,
    } as const;
};

export const createToggleErrorLevelAction = (level: ErrorLevel) => {
    return {
        type: '@CONSTRAINT_REPORTS/TOGGLE_ERROR_LEVEL',
        level,
    } as const;
};

export const createToggleErrorListAction = (listName: string) => {
    return {
        type: '@CONSTRAINT_REPORTS/TOGGLE_ERROR_LIST',
        listName,
    } as const;
};

export const createGetConfigurationRequestAction = (eventId: number) => {
    return {
        type: '@CONSTRAINT_REPORTS/GET_CONFIGURATION_REQUEST',
        eventId,
    } as const;
};

export const createGetConfigurationRequestSuccessAction =
    (eventId: number) => {
        return {
            type: '@CONSTRAINT_REPORTS/GET_CONFIGURATION_SUCCESS',
            eventId,
        } as const;
    };

export const createGetConfigurationRequestFailureAction = (eventId: number) => {
    return {
        type: '@CONSTRAINT_REPORTS/GET_CONFIGURATION_FAILURE',
        eventId,
    } as const;
};

export const createSaveConfigurationRequestAction = (eventId: number) => {
    return {
        type: '@CONSTRAINT_REPORTS/SAVE_CONFIGURATION_REQUEST',
        eventId,
    } as const;
};

export const createSaveConfigurationRequestSuccessAction =
    (eventId: number) => {
        return {
            type: '@CONSTRAINT_REPORTS/SAVE_CONFIGURATION_SUCCESS',
            eventId,
        } as const;
    };

export const createSaveConfigurationRequestFailureAction = (eventId: number) => {
    return {
        type: '@CONSTRAINT_REPORTS/SAVE_CONFIGURATION_FAILURE',
        eventId,
    } as const;
};

export const createGetAffinityGroupsRequestAction = (eventId: number) => {
    return {
        type: '@CONSTRAINT_REPORTS/GET_AFFINITY_GROUPS_REQUEST',
        eventId,
    } as const;
};

export const createGetAffinityGroupsRequestSuccessAction = (eventId: number, groups: number[]) => {
    return {
        type: '@CONSTRAINT_REPORTS/GET_AFFINITY_GROUPS_SUCCESS',
        eventId,
        groups,
    } as const;
};

export const createGetAffinityGroupsRequestFailureAction = (eventId: number) => {
    return {
        type: '@CONSTRAINT_REPORTS/GET_AFFINITY_GROUPS_FAILURE',
        eventId,
    } as const;
};

export const createToggleGroupListAction = (listType: SPResolverGroupType) => {
    return {
        type: '@CONSTRAINT_REPORTS/TOGGLE_GROUP_LIST',
        listType,
    } as const;
};

export const createUpdateGroupAction = (eventId: number, groupId: number) => {
    return {
        type: '@CONSTRAINT_REPORTS/UPDATE_GROUP_REQUEST',
        eventId,
        groupId,
    } as const;
};

export const createUpdateGroupSuccessAction = (eventId: number, groupId: number) => {
    return {
        type: '@CONSTRAINT_REPORTS/UPDATE_GROUP_SUCCESS',
        eventId,
        groupId,
    } as const;
};

export const createUpdateGroupFailureAction = (eventId: number, groupId: number) => {
    return {
        type: '@CONSTRAINT_REPORTS/UPDATE_GROUP_FAILURE',
        eventId,
        groupId,
    } as const;
};

export const createCreateGroupRequestAction = (eventId: number) => {
    return {
        type: '@CONSTRAINT_REPORTS/CREATE_GROUP_REQUEST',
        eventId,
    } as const;
};

export const createCreateGroupRequestSuccessAction = (eventId: number, groupId: number) => {
    return {
        type: '@CONSTRAINT_REPORTS/CREATE_GROUP_SUCCESS',
        eventId,
        groupId,
    } as const;
};

export const createCreateGroupRequestFailureAction = (eventId: number) => {
    return {
        type: '@CONSTRAINT_REPORTS/CREATE_GROUP_FAILURE',
        eventId,
    } as const;
};

export const createDeleteGroupRequestAction = (eventId: number, groupId: number) => {
    return {
        type: '@CONSTRAINT_REPORTS/DELETE_GROUP_REQUEST',
        eventId,
        groupId,
    } as const;
};

export const createDeleteGroupRequestSuccessAction = (eventId: number, groupId: number) => {
    return {
        type: '@CONSTRAINT_REPORTS/DELETE_GROUP_SUCCESS',
        eventId,
        groupId,
    } as const;
};

export const createDeleteGroupRequestFailureAction = (eventId: number, groupId: number) => {
    return {
        type: '@CONSTRAINT_REPORTS/DELETE_GROUP_FAILURE',
        eventId,
        groupId,
    } as const;
};

export const createImproveSeatingPlanRequestAction = (eventId: number) => {
    return {
        type: '@CONSTRAINT_REPORTS/IMPROVE_SEATING_PLAN_REQUEST',
        eventId,
    } as const;
};

export const createImproveSeatingPlanSuccessAction = (eventId: number, improvementResult: string, nextUsageDate?: Date) => {
    return {
        type: '@CONSTRAINT_REPORTS/IMPROVE_SEATING_PLAN_SUCCESS',
        eventId,
        improvementResult,
        nextUsageDate,
    } as const;
};

export const createImproveSeatingPlanFailureAction = (eventId: number) => {
    return {
        type: '@CONSTRAINT_REPORTS/IMPROVE_SEATING_PLAN_FAILURE',
        eventId,
    } as const;
};

export const createInvalidateImprovementResultAction = (eventId: number) => {
    return {
        type: '@CONSTRAINT_REPORTS/INVALIDATE_IMPROVEMENT_RESULT',
        eventId,
    } as const;
};

export const createAddGuestAffinityRequestAction = (eventId: number, guestId: number) => {
    return {
        type: '@CONSTRAINT_REPORTS/ADD_GUEST_AFFINITY_REQUEST',
        eventId,
        guestId,
    } as const;
};

export const createAddGuestAffinityRequestSuccessAction = (eventId: number, guestId: number) => {
    return {
        type: '@CONSTRAINT_REPORTS/ADD_GUEST_AFFINITY_SUCCESS',
        eventId,
        guestId,
    } as const;
};

export const createAddGuestAffinityRequestFailureAction = (eventId: number, guestId: number) => {
    return {
        type: '@CONSTRAINT_REPORTS/ADD_GUEST_AFFINITY_FAILURE',
        eventId,
        guestId,
    } as const;
};

export const createRemoveGuestAffinityRequestAction = (eventId: number, guestId: number) => {
    return {
        type: '@CONSTRAINT_REPORTS/REMOVE_GUEST_AFFINITY_REQUEST',
        eventId,
        guestId,
    } as const;
};

export const createRemoveGuestAffinityRequestSuccessAction = (eventId: number, guestId: number) => {
    return {
        type: '@CONSTRAINT_REPORTS/REMOVE_GUEST_AFFINITY_SUCCESS',
        eventId,
        guestId,
    } as const;
};

export const createRemoveGuestAffinityRequestFailureAction = (eventId: number, guestId: number) => {
    return {
        type: '@CONSTRAINT_REPORTS/REMOVE_GUEST_AFFINITY_FAILURE',
        eventId,
        guestId,
    } as const;
};

export type ConstraintReportsAction =
    | ReturnType<typeof createGetConstraintReportsRequestAction>
    | ReturnType<typeof createGetConstraintReportsRequestSuccessAction>
    | ReturnType<typeof createGetConstraintReportsRequestFailureAction>
    | ReturnType<typeof createInvalidateConstraintReportsRequestAction>
    | ReturnType<typeof createGetConfigurationRequestAction>
    | ReturnType<typeof createGetConfigurationRequestSuccessAction>
    | ReturnType<typeof createGetConfigurationRequestFailureAction>
    | ReturnType<typeof createSaveConfigurationRequestAction>
    | ReturnType<typeof createSaveConfigurationRequestSuccessAction>
    | ReturnType<typeof createSaveConfigurationRequestFailureAction>
    | ReturnType<typeof createToggleErrorLevelAction>
    | ReturnType<typeof createToggleErrorListAction>
    | ReturnType<typeof createGetAffinityGroupsRequestAction>
    | ReturnType<typeof createGetAffinityGroupsRequestSuccessAction>
    | ReturnType<typeof createGetAffinityGroupsRequestFailureAction>
    | ReturnType<typeof createToggleGroupListAction>
    | ReturnType<typeof createUpdateGroupAction>
    | ReturnType<typeof createUpdateGroupSuccessAction>
    | ReturnType<typeof createUpdateGroupFailureAction>
    | ReturnType<typeof createCreateGroupRequestAction>
    | ReturnType<typeof createCreateGroupRequestSuccessAction>
    | ReturnType<typeof createCreateGroupRequestFailureAction>
    | ReturnType<typeof createDeleteGroupRequestAction>
    | ReturnType<typeof createDeleteGroupRequestSuccessAction>
    | ReturnType<typeof createDeleteGroupRequestFailureAction>
    | ReturnType<typeof createImproveSeatingPlanRequestAction>
    | ReturnType<typeof createImproveSeatingPlanSuccessAction>
    | ReturnType<typeof createImproveSeatingPlanFailureAction>
    | ReturnType<typeof createInvalidateImprovementResultAction>
    | ReturnType<typeof createAddGuestAffinityRequestAction>
    | ReturnType<typeof createAddGuestAffinityRequestSuccessAction>
    | ReturnType<typeof createAddGuestAffinityRequestFailureAction>
    | ReturnType<typeof createRemoveGuestAffinityRequestAction>
    | ReturnType<typeof createRemoveGuestAffinityRequestSuccessAction>
    | ReturnType<typeof createRemoveGuestAffinityRequestFailureAction>;
