import { ISignalRUser } from './reducer';

export const createStartSignalRAction = () => {
    return {
        type: '@SIGNALR/START',
        payload: {},
    } as const;
};

export interface ITableUpdated {
    type: '@SIGNALR/TABLE_UPDATED';
    payload: {
        eventId: number;
        tableId: number;
    };
}

export interface ITableDeleted {
    type: '@SIGNALR/TABLE_DELETED';
    payload: {
        eventId: number;
        tableId: number;
    };
}

export interface ITableCreated {
    type: '@SIGNALR/TABLE_CREATED';
    payload: {
        eventId: number;
        tableId: number;
    };
}

export interface ISeatingPlanUpdated {
    type: '@SIGNALR/SEATING_PLAN_UPDATED';
    payload: {
        eventId: number;
    };
}

export interface ISeatingPlanConfigurationUpdated {
    type: '@SIGNALR/SEATING_PLAN_CONFIGURATION_UPDATED';
    payload: {
        eventId: number;
    };
}

export interface IObjectUpdated {
    type: '@SIGNALR/OBJECT_UPDATED';
    payload: {
        eventId: number;
        objectId: number;
    };
}

export interface IObjectDeleted {
    type: '@SIGNALR/OBJECT_DELETED';
    payload: {
        eventId: number;
        objectId: number;
    };
}

export interface IObjectCreated {
    type: '@SIGNALR/OBJECT_CREATED';
    payload: {
        eventId: number;
        objectId: number;
    };
}

export interface IMenuUpdated {
    type: '@SIGNALR/MENU_UPDATED';
    payload: {
        eventId: number;
    };
}

export interface IInformationUpdated {
    type: '@SIGNALR/INFORMATION_UPDATED';
    payload: {
        eventId: number;
    };
}

export interface IGuestUpdated {
    type: '@SIGNALR/GUEST_UPDATED';
    payload: {
        eventId: number;
        guestId: number;
    };
}

export interface IGuestDeleted {
    type: '@SIGNALR/GUEST_DELETED';
    payload: {
        eventId: number;
        guestId: number;
    };
}

export interface IGuestCreated {
    type: '@SIGNALR/GUEST_CREATED';
    payload: {
        eventId: number;
        guestId: number;
    };
}

export interface IConstraintReportGroupUpdated {
    type: '@SIGNALR/CONSTRAINT_REPORT_GROUP_UPDATED';
    payload: {
        eventId: number;
        groupId: number;
    };
}

export interface IConstraintReportGroupDeleted {
    type: '@SIGNALR/CONSTRAINT_REPORT_GROUP_DELETED';
    payload: {
        eventId: number;
        groupId: number;
    };
}

export interface IConstraintReportGroupCreated {
    type: '@SIGNALR/CONSTRAINT_REPORT_GROUP_CREATED';
    payload: {
        eventId: number;
        groupId: number;
    };
}

export interface IConstraintReportConfigurationUpdated {
    type: '@SIGNALR/CONSTRAINT_REPORT_CONFIGURATION_UPDATED';
    payload: {
        eventId: number;
    };
}

export interface IChecklistItemUpdated {
    type: '@SIGNALR/CHECKLIST_ITEM_UPDATED';
    payload: {
        eventId: number;
        checklistItemId: number;
    };
}

export interface IChecklistItemDeleted {
    type: '@SIGNALR/CHECKLIST_ITEM_DELETED';
    payload: {
        eventId: number;
        checklistItemId: number;
    };
}

export interface IChecklistItemCreated {
    type: '@SIGNALR/CHECKLIST_ITEM_CREATED';
    payload: {
        eventId: number;
        checklistItemId: number;
    };
}

export interface IEventGroupUserListUpdated {
    type: '@SIGNALR/EVENT_GROUP_USER_LIST_UPDATED';
    payload: {
        eventId: number;
        users: ISignalRUser[];
    };
}

export type SignalRAction =
    | ReturnType<typeof createStartSignalRAction>
    | ITableCreated
    | ITableDeleted
    | ITableUpdated
    | IObjectCreated
    | IObjectDeleted
    | IObjectUpdated
    | IGuestCreated
    | IGuestDeleted
    | IGuestUpdated
    | IChecklistItemCreated
    | IChecklistItemDeleted
    | IChecklistItemUpdated
    | ISeatingPlanUpdated
    | ISeatingPlanConfigurationUpdated
    | IMenuUpdated
    | IInformationUpdated
    | IConstraintReportConfigurationUpdated
    | IConstraintReportGroupUpdated
    | IConstraintReportGroupDeleted
    | IConstraintReportGroupCreated
    | IEventGroupUserListUpdated;
