import { schema } from 'normalizr';
import { IEntities } from 'services/api/ApiSchema';

interface IMergePayload {
    [x: string]: {
        [id: string]: IEntities;
    } | undefined;
}

export const mergeEntities = (payload: IMergePayload) => {
    return {
        type: '@NORMALIZR/MERGE_ENTITIES',
        payload,
    } as const;
};

export const removeEntities = (entitySchema: schema.Entity, entityIds: Array<string | number>) => {
    const payload = {
        [entitySchema.key]: entityIds ? entityIds.reduce<{ [id: string]: null }>(
            (prev, id) => {
                prev[id] = null;
                return prev;
            },
            {}) : {},
    };
    return {
        type: '@NORMALIZR/REMOVE_ENTITIES',
        payload,
    } as const;
};

export type NormalizrAction =
    | ReturnType<typeof mergeEntities>
    | ReturnType<typeof removeEntities>;
