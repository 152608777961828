import { AppReducer } from 'store';

const unloadedState = () => {
    return {} as { readonly [modalId: string]: boolean };
};

export type ModalsState = ReturnType<typeof unloadedState>;

export const ModalsReducer: AppReducer<ModalsState> = (state = unloadedState(), action) => {
    switch (action.type) {
        case '@MODAL/TOGGLE':
            return {
                ...state,
                [action.modalId]: !state[action.modalId],
            };

        default:
            return state;
    }
};
