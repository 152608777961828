import { Middleware } from 'redux';
import { Logger } from 'services/Logger';

export const errorLoggingMiddleware: Middleware = () => {
    return (next) => (action) => {
        try {
            return next(action);
        } catch (err) {
            Logger.logError(err);
            throw err;
        }
    };
};
