import { faPowerOff } from '@fortawesome/free-solid-svg-icons/faPowerOff';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { boundMethod } from 'autobind-decorator';
import { RoutePaths } from 'components/Routes/RoutePaths';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { IUserMenuProps } from './UserMenuModels';
import { getConfiguration } from 'config/constants';

class UserMenuComponent extends React.PureComponent<IUserMenuProps & WithTranslation> {
    public componentDidMount() {
        const { onInit } = this.props;
        onInit();
    }

    public render() {
        return (
            <>
                <ul className="nav nav-pills">
                    {this.renderAuthenticatedMenu()}
                    {this.renderAnonymousMenu()}
                </ul>
                {this.renderProMenu()}
            </>
        );
    }

    private renderAuthenticatedMenu() {
        const { isAuthenticated, t, loggedUser } = this.props;
        return isAuthenticated ? (
            <li className="nav-item">
                <UncontrolledDropdown inNavbar={true} className="d-flex">
                    <DropdownToggle caret={true} color="" className="nav-link d-none d-md-inline flex-grow-1">
                        {t('Common:Hi__0__', { 0: loggedUser ? loggedUser.userName : '' })}
                    </DropdownToggle>
                    <DropdownToggle caret={true} color="" className="nav-link d-md-none flex-grow-1">
                        {t('Common:Hi')}
                    </DropdownToggle>
                    <DropdownMenu right={true}>
                        {this.renderUserMenu()}
                        {this.renderGuestMenu()}
                        <div className="dropdown-divider" />
                        <DropdownItem onClick={this.handleLogout} className="nav-link">
                            <FontAwesomeIcon icon={faPowerOff} className="pull-right" /> {t('Common:Logout')}
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </li>
        ) : null;
    }

    private renderUserMenu() {
        const { isAuthenticated, t } = this.props;
        return isAuthenticated ? (
            <React.Fragment>
                <DropdownItem href={RoutePaths.Manage.Profile.url()} className="nav-link">
                    {t('Account:Manage_account')}
                </DropdownItem>
                <DropdownItem href={RoutePaths.Manage.MyPurchases.url()} className="nav-link">
                    {t('Sitemap:MyPurchases')}
                </DropdownItem>
                <DropdownItem href={RoutePaths.Manage.PaymentInformation.url()} className="nav-link">
                    {t('Sitemap:PaymentInformation')}
                </DropdownItem>
                <DropdownItem href={RoutePaths.Manage.EmailSubscription.url()} className="nav-link">
                    {t('Sitemap:EmailSubscription')}
                </DropdownItem>
            </React.Fragment>
        ) : null;
    }

    private renderGuestMenu() {
        const { isGuest, t } = this.props;
        return isGuest ? (
            <DropdownItem href={RoutePaths.Account.Register.url()} className="nav-link">
                {t('Account:ExternalLoginConfirmation_Execute_Register')}
            </DropdownItem>
        ) : null;
    }

    private renderProMenu() {
        const { isProManager, t } = this.props;
        const configuration = getConfiguration();
        return isProManager ? (
            <ul className="nav nav-pills">
                <li className="nav-item">
                    <a href={configuration.externalLinks.mesHotesPro} className="nav-link">
                        {t('Account:ProAccess')}
                    </a>
                </li>
            </ul>
        ) : null;
    }

    private renderAnonymousMenu() {
        const { isAuthenticated, t } = this.props;
        return !isAuthenticated ? (
            <React.Fragment>
                <li className="nav-item">
                    <a href={RoutePaths.Account.Register.url()} className="nav-link">
                        {t('Account:ExternalLoginConfirmation_Execute_Register')}
                    </a>
                </li>
                <li className="nav-item">
                    <a href={RoutePaths.Account.Login.url()} onClick={this.handleLogin} className="nav-link">
                        {t('Account:Log_in')}
                    </a>
                </li>
            </React.Fragment>
        ) : null;
    }

    @boundMethod
    private handleLogin(e: React.MouseEvent<HTMLAnchorElement>): void {
        const { onSignIn } = this.props;
        e.preventDefault();
        onSignIn();
    }

    @boundMethod
    private handleLogout(e: React.MouseEvent<HTMLAnchorElement>): void {
        const { onSignOut, userIdToken } = this.props;
        e.preventDefault();
        onSignOut(userIdToken);
    }
}

export const UserMenu = withTranslation()(UserMenuComponent);
