
import { AppReducer } from 'store';
import { IImportedContact } from 'store/SharedModels';

const unloadedState = () => {
    return {
        isImporting: false as boolean,
        searchTerms: '' as string,
        contacts: [] as IImportedContact[],
    } as const;
};

export type GuestsGoogleImportState = ReturnType<typeof unloadedState>;

export const GuestsGoogleImportReducer: AppReducer<GuestsGoogleImportState> = (state = unloadedState(), action) => {
    switch (action.type) {
        case '@GUESTS_GOOGLE_IMPORT/IMPORT_CONTACTS':
            return {
                ...state,
                isImporting: true,
            };

        case '@GUESTS_GOOGLE_IMPORT/IMPORT_CONTACTS_SUCCESS':
            return {
                ...state,
                isImporting: false,
                contacts: action.contacts,
            };

        case '@GUESTS_GOOGLE_IMPORT/IMPORT_CONTACTS_FAILURE':
            return {
                ...state,
                isImporting: false,
            };

        case '@GUESTS_GOOGLE_IMPORT/SET_SEARCH_TERMS':
            return {
                ...state,
                searchTerms: action.terms || '',
            };

        default:
            return state;
    }
};
