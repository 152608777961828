import { normalize } from 'normalizr';
import { AppContextApi } from 'services/api';
import { AppContextModel, LanguageModel } from 'services/api/ApiClient';
import { ApiSchema } from 'services/api/ApiSchema';
import { Logger } from 'services/Logger';
import { PromiseStore } from 'services/PromiseStore';
import { SSRService } from 'services/ServerSideRenderingService';
import { AppThunkAction } from 'store';
import { mergeEntities } from 'store/normalizr/actions';
import { Selectors } from 'store/normalizr/selectors';
import {
    createChangeLanguageRequestAction,
    createChangeLanguageRequestFailureAction,
    createChangeLanguageRequestSuccessAction,
    createGetAppContextRequestAction,
    createGetAppContextRequestFailureAction,
    createGetAppContextRequestSuccessAction,
    createConsentCookieRequestAction,
    createConsentCookieSuccessAction,
    createConsentCookieFailureAction,
} from './actions';
import { createStartSignalRAction } from 'store/signalR/actions';

export const getAppContext = (): AppThunkAction<AppContextModel | null> => (dispatch, getState) => {
    const state = getState();

    if (state.appContext.isFetching || !state.appContext.didInvalidate) {
        return PromiseStore.get('getAppContext');
    }

    dispatch(createGetAppContextRequestAction());

    const fetchTask = AppContextApi
        .getAppContext()
        .then((data) => {
            const normalizedData = normalize(data, ApiSchema.AppContextModelSchema);
            dispatch(mergeEntities(normalizedData.entities));
            dispatch(createGetAppContextRequestSuccessAction(normalizedData.result));
            if (data.loggedUser) {
                dispatch(createStartSignalRAction());
            }
            return data;
        })
        .catch((error: Error) => {
            dispatch(createGetAppContextRequestFailureAction());
            Logger.logError(error);
            throw error;
        });

    SSRService.addTask(fetchTask, 'getAppContext');
    PromiseStore.set(fetchTask, 'getAppContext');

    return fetchTask;
};

export const answerCookieConsent = (isGranted: boolean): AppThunkAction => (dispatch, getState) => {
    const state = getState();

    if (state.appContext.isCookieConsentAnswering) {
        return PromiseStore.get('answerCookieConsent');
    }

    dispatch(createConsentCookieRequestAction());

    const fetchTask = (isGranted ? AppContextApi.grantCookieConsent() : AppContextApi.withdrawCookieConsent())
        .then(() => {
            dispatch(createConsentCookieSuccessAction());
        })
        .catch((error: Error) => {
            dispatch(createConsentCookieFailureAction());
            Logger.logError(error);
            throw error;
        });

    SSRService.addTask(fetchTask, 'answerCookieConsent');
    PromiseStore.set(fetchTask, 'answerCookieConsent');

    return fetchTask;
};

export const changeLanguage = (language: LanguageModel): AppThunkAction => (dispatch, getState) => {
    const state = getState();

    const appContext = Selectors.getAppContextModel(state.appContext.appContextId, state);
    if (!language.twoLetterISOLanguageName || state.appContext.isChangingLanguage || (appContext?.language === language)) {
        return PromiseStore.get('changeLanguage', language.twoLetterISOLanguageName || undefined);
    }

    dispatch(createChangeLanguageRequestAction());

    const fetchTask = AppContextApi
        .changeLanguage(language.twoLetterISOLanguageName)
        .then(() => {
            if (appContext) {
                appContext.language = language;
                const normalizedData = normalize(appContext, ApiSchema.AppContextModelSchema);
                dispatch(mergeEntities(normalizedData.entities));
            }
            dispatch(createChangeLanguageRequestSuccessAction());
        })
        .catch((error: Error) => {
            dispatch(createChangeLanguageRequestFailureAction());
            Logger.logError(error);
            throw error;
        });

    SSRService.addTask(fetchTask, 'changeLanguage');
    PromiseStore.set(fetchTask, 'changeLanguage', language.twoLetterISOLanguageName || undefined);

    return fetchTask;
};
