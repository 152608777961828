import { AppReducer } from 'store';
import { IRequest } from 'store/SharedModels';

const unloadedState = () => {
    return {
        informationDetailsRequests: {} as { readonly [eventId: number]: IRequest },
        informationUpdateRequests: {} as { readonly [eventId: string]: boolean },
        placeDeleteRequests: {} as { readonly [placeId: string]: boolean },
        placeUpdateRequests: {} as { readonly [placeId: string]: boolean },
    } as const;
};

export type InformationState = ReturnType<typeof unloadedState>;

export const InformationReducer: AppReducer<InformationState> = (state = unloadedState(), action) => {
    switch (action.type) {
        case '@INFORMATION/GET_INFORMATION_REQUEST':
            return {
                ...state,
                informationDetailsRequests: {
                    ...state.informationDetailsRequests,
                    [action.eventId]: {
                        ...state.informationDetailsRequests[action.eventId],
                        didInvalidate: state.informationDetailsRequests[action.eventId]?.didInvalidate || false,
                        isFetching: true,
                    },
                },
            };

        case '@INFORMATION/GET_INFORMATION_SUCCESS':
            return {
                ...state,
                informationDetailsRequests: {
                    ...state.informationDetailsRequests,
                    [action.eventId]: {
                        ...state.informationDetailsRequests[action.eventId],
                        isFetching: false,
                        didInvalidate: false,
                    },
                },
            };

        case '@INFORMATION/GET_INFORMATION_FAILURE':
            return {
                ...state,
                informationDetailsRequests: {
                    ...state.informationDetailsRequests,
                    [action.eventId]: {
                        ...state.informationDetailsRequests[action.eventId],
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        case '@INFORMATION/UPDATE_INFORMATION_REQUEST':
            return {
                ...state,
                informationUpdateRequests: {
                    ...state.informationUpdateRequests,
                    [action.eventId]: true,
                },
            };

        case '@INFORMATION/UPDATE_INFORMATION_SUCCESS':
            return {
                ...state,
                informationDetailsRequests: {
                    ...state.informationDetailsRequests,
                    [action.eventId]: {
                        ...state.informationDetailsRequests[action.eventId],
                        didInvalidate: true,
                    },
                },
                informationUpdateRequests: {
                    ...state.informationUpdateRequests,
                    [action.eventId]: false,
                },
            };

        case '@INFORMATION/UPDATE_INFORMATION_FAILURE':
            return {
                ...state,
                informationUpdateRequests: {
                    ...state.informationUpdateRequests,
                    [action.eventId]: false,
                },
            };

        case '@INFORMATION/DELETE_PLACE_REQUEST':
            return {
                ...state,
                placeDeleteRequests: {
                    ...state.placeDeleteRequests,
                    [action.placeId]: true,
                },
            };

        case '@INFORMATION/DELETE_PLACE_SUCCESS':
            return {
                ...state,
                informationDetailsRequests: {
                    ...state.informationDetailsRequests,
                    [action.eventId]: {
                        ...state.informationDetailsRequests[action.eventId],
                        didInvalidate: true,
                    },
                },
                placeDeleteRequests: {
                    ...state.placeDeleteRequests,
                    [action.placeId]: false,
                },
            };

        case '@INFORMATION/DELETE_PLACE_FAILURE':
            return {
                ...state,
                placeDeleteRequests: {
                    ...state.placeDeleteRequests,
                    [action.placeId]: false,
                },
            };

        case '@INFORMATION/UPDATE_PLACE_REQUEST':
            return {
                ...state,
                placeUpdateRequests: {
                    ...state.placeUpdateRequests,
                    [action.placeId]: true,
                },
            };

        case '@INFORMATION/UPDATE_PLACE_SUCCESS':
            return {
                ...state,
                informationDetailsRequests: {
                    ...state.informationDetailsRequests,
                    [action.eventId]: {
                        ...state.informationDetailsRequests[action.eventId],
                        didInvalidate: true,
                    },
                },
                placeUpdateRequests: {
                    ...state.placeUpdateRequests,
                    [action.placeId]: false,
                },
            };

        case '@INFORMATION/UPDATE_PLACE_FAILURE':
            return {
                ...state,
                placeUpdateRequests: {
                    ...state.placeUpdateRequests,
                    [action.placeId]: false,
                },
            };

        case '@SIGNALR/INFORMATION_UPDATED':
            return {
                ...state,
                informationDetailsRequests: {
                    ...state.informationDetailsRequests,
                    [action.payload.eventId]: {
                        ...state.informationDetailsRequests[action.payload.eventId],
                        didInvalidate: true,
                    },
                },
            };

        default:
            return state;
    }
};
