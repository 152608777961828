
import { AppReducer } from 'store';

export const DEFAULT_TAKE_SIZE = 10;

const unloadedState = () => {
    return {
        isFetching: false as boolean,
        didInvalidate: true as boolean,
        searchTerms: '' as string,
        skip: 0 as number,
        take: DEFAULT_TAKE_SIZE as number,
        totalResults: 0 as number,
        events: [] as number[],
    } as const;
};

export type EventsState = ReturnType<typeof unloadedState>;

export const EventsReducer: AppReducer<EventsState> = (state = unloadedState(), action) => {
    switch (action.type) {
        case '@EVENTS/SEARCH_EVENTS_REQUEST':
            return {
                ...state,
                isFetching: true,
            };

        case '@EVENTS/SEARCH_EVENTS_SUCCESS':
            return {
                ...state,
                isFetching: false,
                didInvalidate: false,
                totalResults: action.totalResults,
                events: action.events,
            };

        case '@EVENTS/SEARCH_EVENTS_FAILURE':
            return {
                ...state,
                isFetching: false,
                didInvalidate: true,
            };

        case '@EVENTS/SET_SEARCH_TERMS':
            return {
                ...state,
                searchTerms: action.searchTerms,
                skip: 0,
                didInvalidate: true,
            };

        case '@EVENTS/CHANGE_PAGE':
            return {
                ...state,
                skip: action.skip,
                take: action.take,
                didInvalidate: true,
            };

        case '@EVENT_DETAILS/DUPLICATE_EVENT_SUCCESS':
        case '@EVENT_DETAILS/DELETE_EVENT_SUCCESS':
        case '@EVENT_DETAILS/UPDATE_EVENT_SUCCESS':
        case '@EVENT_WIZARD/CREATE_EVENT_SUCCESS':
            return {
                ...state,
                didInvalidate: true,
            };

        default:
            return state;
    }
};
