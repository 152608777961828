import i18next from 'i18next';
import { normalize } from 'normalizr';
import { toastr } from 'react-redux-toastr';
import { GuestApi } from 'services/api';
import { Guest, PresenceType, Right } from 'services/api/ApiClient';
import { ApiSchema } from 'services/api/ApiSchema';
import { Http } from 'services/api/Http';
import { Logger } from 'services/Logger';
import { PromiseStore } from 'services/PromiseStore';
import { SSRService } from 'services/ServerSideRenderingService';
import { AppThunkAction } from 'store';
import { mergeEntities, removeEntities } from 'store/normalizr/actions';
import {
    createDeleteGuestRequestAction,
    createDeleteGuestRequestFailureAction,
    createDeleteGuestRequestSuccessAction,
    createGetGuestRequestAction,
    createGetGuestRequestFailureAction,
    createGetGuestRequestSuccessAction,
    createGetLightGuestsRequestAction,
    createGetLightGuestsRequestFailureAction,
    createGetLightGuestsRequestSuccessAction,
    createSearchGuestsRequestAction,
    createSearchGuestsRequestFailureAction,
    createSearchGuestsRequestSuccessAction,
    createSendInvitationsAction,
    createSendInvitationsFailureAction,
    createSendInvitationsSuccessAction,
    createSendInvitationToMyselfAction,
    createSendInvitationToMyselfFailureAction,
    createSendInvitationToMyselfSuccessAction,
    createSendRequestPlacementPreferencesAction,
    createSendRequestPlacementPreferencesFailureAction,
    createSendRequestPlacementPreferencesSuccessAction,
    createSendRequestPlacementPreferencesToMyselfAction,
    createSendRequestPlacementPreferencesToMyselfFailureAction,
    createSendRequestPlacementPreferencesToMyselfSuccessAction,
    createUpdateGuestRequestAction,
    createUpdateGuestRequestFailureAction,
    createUpdateGuestRequestSuccessAction,
    createGetLightGuestsForceEmptyAction,
    createAnswerInvitationRequestAction,
    createAnswerInvitationFailureAction,
    createAnswerInvitationSuccessAction,
} from './actions';
import { DEFAULT_SEARCH } from './reducer';
import { getRights, hasRight } from 'store/rights/thunk';
import { unseatGuest } from 'store/seatingPlans/thunk';

export const searchGuests = (eventId: number): AppThunkAction<boolean> => (dispatch, getState) => {
    const state = getState();

    Http.abortRequests(new RegExp(`/api/GuestApi/GetGuestList/${eventId}`));

    dispatch(createSearchGuestsRequestAction(eventId));

    const searchRequest = state.guests.search[eventId] || DEFAULT_SEARCH;

    const fetchTask = GuestApi
        .getGuestList(eventId, searchRequest.searchTerms, searchRequest.filterPresence, searchRequest.skip, searchRequest.take)
        .then((data) => {
            const normalizedData = normalize(data.guests, ApiSchema.GuestSchemaArray);
            dispatch(mergeEntities(normalizedData.entities));
            dispatch(createSearchGuestsRequestSuccessAction(eventId, data.totalItemCount, normalizedData.result));
            return !data.guests?.length
        })
        .catch((error: Error) => {
            dispatch(createSearchGuestsRequestFailureAction(eventId));
            Logger.logError(error);
            throw error;
        });

    SSRService.addTask(fetchTask, 'searchGuests');

    return fetchTask;
};

export const getGuest = (eventId: number, guestId: number): AppThunkAction => (dispatch, getState) => {
    const state = getState();

    const request = state.guests.guestDetailsRequests[guestId];
    if (request && (request.isFetching || !request.didInvalidate)) {
        return PromiseStore.get('getGuest', eventId, guestId);
    }

    dispatch(createGetGuestRequestAction(guestId));

    const fetchTask = GuestApi
        .getGuest(eventId, guestId)
        .then((data) => {
            const normalizedData = normalize(data, ApiSchema.GuestSchema);
            dispatch(mergeEntities(normalizedData.entities));
            dispatch(createGetGuestRequestSuccessAction(guestId));
        })
        .catch((error: Error) => {
            dispatch(createGetGuestRequestFailureAction(guestId));
            Logger.logError(error);
            throw error;
        });

    SSRService.addTask(fetchTask, 'getGuest');
    PromiseStore.set(fetchTask, 'getGuest', eventId, guestId);

    return fetchTask;
};

export const updateGuest = (eventId: number, guest: Guest, tempId?: string): AppThunkAction => (dispatch, getState) => {
    const state = getState();

    const guestId = guest.id || tempId || -1;
    const request = state.guests.guestUpdateRequests[guestId.toString()];
    if (request) {
        return PromiseStore.get('updateGuest', eventId, guestId);
    }

    dispatch(createUpdateGuestRequestAction(guestId));

    const fetchTask = GuestApi
        .update(eventId, guest)
        .then((data) => {
            if (data?.success) {
                guest.id = data.id;
                const normalizedData = normalize(guest, ApiSchema.GuestSchema);
                dispatch(mergeEntities(normalizedData.entities));
                dispatch(createUpdateGuestRequestSuccessAction(eventId, guestId));
            } else {
                dispatch(createUpdateGuestRequestFailureAction(guestId));
                toastr.error(i18next.t('Common:Error'), data?.message || i18next.t('Common:Failure'));
            }
        })
        .catch((error: Error) => {
            dispatch(createUpdateGuestRequestFailureAction(guestId));
            Logger.logError(error);
            throw error;
        });

    SSRService.addTask(fetchTask, 'updateGuest');
    PromiseStore.set(fetchTask, 'updateGuest', eventId, guestId);

    return fetchTask;
};

export const deleteGuest = (eventId: number, guestId: number): AppThunkAction => (dispatch, getState) => {
    const state = getState();

    const request = state.guests.guestDeleteRequests[guestId];
    if (request) {
        return PromiseStore.get('deleteGuest', eventId, guestId);
    }

    dispatch(createDeleteGuestRequestAction(guestId));

    const fetchTask = GuestApi
        .remove(eventId, guestId)
        .then((data) => {
            if (data?.success) {
                dispatch(createDeleteGuestRequestSuccessAction(eventId, guestId));
                dispatch(removeEntities(ApiSchema.GuestSchema, [guestId]));
                dispatch(unseatGuest(eventId, guestId));
            } else {
                dispatch(createDeleteGuestRequestFailureAction(guestId));
                toastr.error(i18next.t('Common:Error'), data?.message || i18next.t('Common:Failure'));
            }
        })
        .catch((error: Error) => {
            dispatch(createDeleteGuestRequestFailureAction(guestId));
            Logger.logError(error);
            throw error;
        });

    SSRService.addTask(fetchTask, 'deleteGuest');
    PromiseStore.set(fetchTask, 'deleteGuest', eventId, guestId);

    return fetchTask;
};

export const fetchLightGuests = (eventId: number): AppThunkAction => (dispatch, getState) => {
    return dispatch(getRights(eventId)).then(() => {
        const state = getState();
        if (!hasRight(state, eventId, Right.GuestsRead)) {
            if (hasRight(state, eventId, Right.SeatingPlanRead)) {
                const normalizedData = normalize([], ApiSchema.LightGuestSchemaArray);
                dispatch(mergeEntities(normalizedData.entities));
                dispatch(createGetLightGuestsForceEmptyAction(eventId));
                return Promise.resolve();
            }

            return Promise.reject('Unauthorized!');
        }
        const request = state.guests.lightGuests[eventId];
        if (request && (request.isFetching || !request.didInvalidate)) {
            return PromiseStore.get('fetchLightGuests', eventId);
        }

        dispatch(createGetLightGuestsRequestAction(eventId));

        const fetchTask = GuestApi
            .getLightGuestList(eventId)
            .then((data) => {
                const normalizedData = normalize(data, ApiSchema.LightGuestSchemaArray);
                dispatch(mergeEntities(normalizedData.entities));
                dispatch(createGetLightGuestsRequestSuccessAction(eventId, normalizedData.result));
            })
            .catch((error: Error) => {
                dispatch(createGetLightGuestsRequestFailureAction(eventId));
                Logger.logError(error);
                throw error;
            });

        SSRService.addTask(fetchTask, 'fetchLightGuests');
        PromiseStore.set(fetchTask, 'fetchLightGuests', eventId);

        return fetchTask;
    });
};

export const sendInvitationToMyself = (eventId: number): AppThunkAction => (dispatch, getState) => {
    const state = getState();

    const request = state.guests.sendInvitationToMyselfRequests[eventId];
    if (request) {
        return PromiseStore.get('sendInvitationToMyself', eventId);
    }

    dispatch(createSendInvitationToMyselfAction(eventId));

    const fetchTask = GuestApi
        .sendInvitationToMe(eventId)
        .then((data) => {
            if (data && data.success) {
                dispatch(createSendInvitationToMyselfSuccessAction(eventId));
                toastr.success(i18next.t('Common:Success'), data.message || i18next.t('Guest:Invitation sent!'));
            } else {
                dispatch(createSendInvitationToMyselfFailureAction(eventId));
                toastr.error(i18next.t('Common:Error'), data.message || i18next.t('Guest:Failed to send invitation!'));
            }
        })
        .catch((error: Error) => {
            dispatch(createSendInvitationToMyselfFailureAction(eventId));
            Logger.logError(error);
            throw error;
        });

    SSRService.addTask(fetchTask, 'sendInvitationToMyself');
    PromiseStore.set(fetchTask, 'sendInvitationToMyself', eventId);

    return fetchTask;
};

export const sendInvitations = (eventId: number): AppThunkAction => (dispatch, getState) => {
    const state = getState();

    const request = state.guests.sendInvitationsRequests[eventId];
    if (request) {
        return PromiseStore.get('sendInvitations', eventId);
    }

    dispatch(createSendInvitationsAction(eventId));

    const fetchTask = GuestApi
        .sendInvitations(eventId)
        .then((data) => {
            if (data && data.success) {
                dispatch(createSendInvitationsSuccessAction(eventId));
                toastr.success(i18next.t('Common:Success'), data.message || i18next.t('Guest:Invitations sent!'));
            } else {
                dispatch(createSendInvitationsFailureAction(eventId));
                toastr.error(i18next.t('Common:Error'), data.message || i18next.t('Guest:Failed to send invitations!'));
            }
        })
        .catch((error: Error) => {
            dispatch(createSendInvitationsFailureAction(eventId));
            Logger.logError(error);
            throw error;
        });

    SSRService.addTask(fetchTask, 'sendInvitations');
    PromiseStore.set(fetchTask, 'sendInvitations', eventId);

    return fetchTask;
};

export const sendRequestPlacementPreferencesToMyself = (eventId: number): AppThunkAction => (dispatch, getState) => {
    const state = getState();

    const request = state.guests.sendRequestPlacementPreferencesToMyselfRequests[eventId];
    if (request) {
        return PromiseStore.get('sendRequestPlacementPreferencesToMyself', eventId);
    }

    dispatch(createSendRequestPlacementPreferencesToMyselfAction(eventId));

    const fetchTask = GuestApi
        .sendEmailsForGuestsCustomizeAffinitiesToMe(eventId)
        .then((data) => {
            if (data && data.success) {
                dispatch(createSendRequestPlacementPreferencesToMyselfSuccessAction(eventId));
                toastr.success(i18next.t('Common:Success'), data.message || i18next.t('Guest:Email sent!'));
            } else {
                dispatch(createSendRequestPlacementPreferencesToMyselfFailureAction(eventId));
                toastr.error(i18next.t('Common:Error'), data.message || i18next.t('Guest:Failed to send emails!'));
            }
        })
        .catch((error: Error) => {
            dispatch(createSendRequestPlacementPreferencesToMyselfFailureAction(eventId));
            Logger.logError(error);
            throw error;
        });

    SSRService.addTask(fetchTask, 'sendRequestPlacementPreferencesToMyself');
    PromiseStore.set(fetchTask, 'sendRequestPlacementPreferencesToMyself', eventId);

    return fetchTask;
};

export const sendRequestPlacementPreferences = (eventId: number): AppThunkAction => (dispatch, getState) => {
    const state = getState();

    const request = state.guests.sendRequestPlacementPreferencesRequests[eventId];
    if (request) {
        return PromiseStore.get('sendRequestPlacementPreferences', eventId);
    }

    dispatch(createSendRequestPlacementPreferencesAction(eventId));

    const fetchTask = GuestApi
        .sendEmailsForGuestsCustomizeAffinities(eventId)
        .then((data) => {
            if (data && data.success) {
                dispatch(createSendRequestPlacementPreferencesSuccessAction(eventId));
                toastr.success(i18next.t('Common:Success'), data.message || i18next.t('Guest:Email sent!'));
            } else {
                dispatch(createSendRequestPlacementPreferencesFailureAction(eventId));
                toastr.error(i18next.t('Common:Error'), data.message || i18next.t('Guest:Failed to send emails!'));
            }
        })
        .catch((error: Error) => {
            dispatch(createSendRequestPlacementPreferencesFailureAction(eventId));
            Logger.logError(error);
            throw error;
        });

    SSRService.addTask(fetchTask, 'sendRequestPlacementPreferences');
    PromiseStore.set(fetchTask, 'sendRequestPlacementPreferences', eventId);

    return fetchTask;
};

export const answerInvitation = (eventId: number, answer: PresenceType): AppThunkAction => (dispatch, getState) => {
    const state = getState();

    const request = state.guests.answerInvitation[eventId];
    if (request) {
        return PromiseStore.get('answerInvitation', eventId);
    }

    dispatch(createAnswerInvitationRequestAction(eventId));

    const fetchTask = GuestApi
        .answerInvitation(eventId, answer)
        .then(() => {
            dispatch(createAnswerInvitationSuccessAction(eventId));
        })
        .catch((error: Error) => {
            dispatch(createAnswerInvitationFailureAction(eventId));
            Logger.logError(error);
            throw error;
        });

    SSRService.addTask(fetchTask, 'answerInvitation');
    PromiseStore.set(fetchTask, 'answerInvitation', eventId);

    return fetchTask;
};
