import { AppReducer } from 'store';
import { IRequest } from 'store/SharedModels';

export const DEFAULT_TEMPLATES_TAKE_SIZE = 5;

export interface ITemplatesRequest {
    readonly isFetching: boolean;
    readonly didInvalidate: boolean;
    readonly templates: number[];
}

export interface ITemplateFilter {
    readonly searchTerms: string;
    readonly skip: number;
    readonly take: number;
}

export interface IApplyTemplateRequest {
    readonly isFetching: boolean;
}

const unloadedState = () => {
    return {
        templateDetailsRequests: {} as { readonly [templateId: number]: IRequest },
        templates: {} as { readonly [requestId: string]: ITemplatesRequest },
        templatesFilters: {} as { readonly [requestId: string]: ITemplateFilter },
        applyTemplateRequests: {} as { readonly [eventId: number]: IApplyTemplateRequest },
    } as const;
};

export const DEFAULT_TEMPLATES_FILTER: ITemplateFilter = {
    searchTerms: '',
    skip: 0,
    take: DEFAULT_TEMPLATES_TAKE_SIZE,
};

export type TemplatesState = ReturnType<typeof unloadedState>;

export const TemplatesReducer: AppReducer<TemplatesState> = (state = unloadedState(), action) => {
    let requestId: string;
    switch (action.type) {
        case '@TEMPLATES/GET_TEMPLATE_REQUEST':
            return {
                ...state,
                templateDetailsRequests: {
                    ...state.templateDetailsRequests,
                    [action.templateId]: {
                        ...state.templateDetailsRequests[action.templateId],
                        didInvalidate: state.templateDetailsRequests[action.templateId]?.didInvalidate || false,
                        isFetching: true,
                    },
                },
            };

        case '@TEMPLATES/GET_TEMPLATE_SUCCESS':
            return {
                ...state,
                templateDetailsRequests: {
                    ...state.templateDetailsRequests,
                    [action.templateId]: {
                        ...state.templateDetailsRequests[action.templateId],
                        isFetching: false,
                        didInvalidate: false,
                    },
                },
            };

        case '@TEMPLATES/GET_TEMPLATE_FAILURE':
            return {
                ...state,
                templateDetailsRequests: {
                    ...state.templateDetailsRequests,
                    [action.templateId]: {
                        ...state.templateDetailsRequests[action.templateId],
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        case '@TEMPLATES/GET_TEMPLATES_REQUEST':
            requestId = `${action.proDomainId}-${action.proDomainRoomId}`;
            return {
                ...state,
                templates: {
                    ...state.templates,
                    [requestId]: {
                        ...state.templates[requestId],
                        templates: state.templates[requestId]?.templates || [],
                        didInvalidate: state.templates[requestId]?.didInvalidate === undefined || state.templates[requestId]?.didInvalidate,
                        isFetching: true,
                    },
                },
            };

        case '@TEMPLATES/GET_TEMPLATES_SUCCESS':
            requestId = `${action.proDomainId}-${action.proDomainRoomId}`;
            return {
                ...state,
                templates: {
                    ...state.templates,
                    [requestId]: {
                        ...state.templates[requestId],
                        isFetching: false,
                        didInvalidate: false,
                        templates: action.templates,
                    },
                },
            };

        case '@TEMPLATES/GET_TEMPLATES_FAILURE':
            requestId = `${action.proDomainId}-${action.proDomainRoomId}`;
            return {
                ...state,
                templates: {
                    ...state.templates,
                    [requestId]: {
                        ...state.templates[requestId],
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        case '@TEMPLATES/SET_SEARCH_TERMS':
            requestId = `${action.proDomainId}-${action.proDomainRoomId}`;
            return {
                ...state,
                templatesFilters: {
                    ...state.templatesFilters,
                    [requestId]: {
                        ...DEFAULT_TEMPLATES_FILTER,
                        ...state.templatesFilters[requestId],
                        searchTerms: action.searchTerms,
                        skip: 0,
                    },
                },
            };

        case '@TEMPLATES/CHANGE_PAGE':
            requestId = `${action.proDomainId}-${action.proDomainRoomId}`;
            return {
                ...state,
                templatesFilters: {
                    ...state.templatesFilters,
                    [requestId]: {
                        ...DEFAULT_TEMPLATES_FILTER,
                        ...state.templatesFilters[requestId],
                        skip: action.skip,
                        take: action.take,
                    },
                },
            };

        case '@TEMPLATES/APPLY_TEMPLATE_REQUEST':
            return {
                ...state,
                applyTemplateRequests: {
                    ...state.applyTemplateRequests,
                    [action.eventId]: {
                        isFetching: true,
                    },
                },
            };

        case '@TEMPLATES/APPLY_TEMPLATE_SUCCESS':
            return {
                ...state,
                applyTemplateRequests: {
                    ...state.applyTemplateRequests,
                    [action.eventId]: {
                        ...state.applyTemplateRequests[action.eventId],
                        isFetching: false,
                    },
                },
            };

        case '@TEMPLATES/APPLY_TEMPLATE_FAILURE':
            return {
                ...state,
                applyTemplateRequests: {
                    ...state.applyTemplateRequests,
                    [action.eventId]: {
                        ...state.applyTemplateRequests[action.eventId],
                        isFetching: false,
                    },
                },
            };

        default:
            return state;
    }
};
