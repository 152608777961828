export const createGetInformationAction = (eventId: number) => {
    return {
        type: '@INFORMATION/GET_INFORMATION_REQUEST',
        eventId,
    } as const;
};

export const createGetInformationSuccessAction = (eventId: number) => {
    return {
        type: '@INFORMATION/GET_INFORMATION_SUCCESS',
        eventId,
    } as const;
};

export const createGetInformationFailureAction = (eventId: number) => {
    return {
        type: '@INFORMATION/GET_INFORMATION_FAILURE',
        eventId,
    } as const;
};

export const createUpdateInformationAction = (eventId: number) => {
    return {
        type: '@INFORMATION/UPDATE_INFORMATION_REQUEST',
        eventId,
    } as const;
};

export const createUpdateInformationSuccessAction = (eventId: number) => {
    return {
        type: '@INFORMATION/UPDATE_INFORMATION_SUCCESS',
        eventId,
    } as const;
};

export const createUpdateInformationFailureAction = (eventId: number) => {
    return {
        type: '@INFORMATION/UPDATE_INFORMATION_FAILURE',
        eventId,
    } as const;
};

export const createDeletePlaceAction = (placeId: number) => {
    return {
        type: '@INFORMATION/DELETE_PLACE_REQUEST',
        placeId,
    } as const;
};

export const createDeletePlaceSuccessAction = (placeId: number, eventId: number) => {
    return {
        type: '@INFORMATION/DELETE_PLACE_SUCCESS',
        eventId,
        placeId,
    } as const;
};

export const createDeletePlaceFailureAction = (placeId: number) => {
    return {
        type: '@INFORMATION/DELETE_PLACE_FAILURE',
        placeId,
    } as const;
};

export const createUpdatePlaceAction = (placeId: number) => {
    return {
        type: '@INFORMATION/UPDATE_PLACE_REQUEST',
        placeId,
    } as const;
};

export const createUpdatePlaceSuccessAction = (placeId: number, eventId: number) => {
    return {
        type: '@INFORMATION/UPDATE_PLACE_SUCCESS',
        eventId,
        placeId,
    } as const;
};

export const createUpdatePlaceFailureAction = (placeId: number) => {
    return {
        type: '@INFORMATION/UPDATE_PLACE_FAILURE',
        placeId,
    } as const;
};

export type InformationAction =
    | ReturnType<typeof createGetInformationAction>
    | ReturnType<typeof createGetInformationSuccessAction>
    | ReturnType<typeof createGetInformationFailureAction>
    | ReturnType<typeof createUpdateInformationAction>
    | ReturnType<typeof createUpdateInformationSuccessAction>
    | ReturnType<typeof createUpdateInformationFailureAction>
    | ReturnType<typeof createDeletePlaceAction>
    | ReturnType<typeof createDeletePlaceSuccessAction>
    | ReturnType<typeof createDeletePlaceFailureAction>
    | ReturnType<typeof createUpdatePlaceAction>
    | ReturnType<typeof createUpdatePlaceSuccessAction>
    | ReturnType<typeof createUpdatePlaceFailureAction>;
