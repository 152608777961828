import classNames from 'classnames';
import * as React from 'react';

import './Spinner.scss';

interface ISpinnerProps {
    loading?: boolean;
    size?: 'big' | 'small';
    color?: 'default' | 'white';
    className?: string;
    style?: React.CSSProperties;
}

export class Spinner extends React.PureComponent<ISpinnerProps> {
    public render() {
        const { loading, color, size, className, style } = this.props;

        const classes = classNames(
            'Spinner',
            className,
        );

        const loaderClasses = classNames(
            'ajax-loader',
            color,
            size,
        );

        return loading ? (
            <div className={classes} style={style}>
                <div className={loaderClasses} />
            </div>
        ) : null;
    }
}
