import { AppReducer } from 'store';

const unloadedState = () => {
    return {
        enabled: false as boolean,
    } as const;
};

export type FullScreenState = ReturnType<typeof unloadedState>;

export const FullScreenReducer: AppReducer<FullScreenState> = (state = unloadedState(), action) => {
    switch (action.type) {
        case '@FULLSCREEN/CHANGE':
            return {
                ...state,
                enabled: action.enabled,
            };

        default:
            return state;
    }
};
