import { AppReducer } from 'store';
import { IRequest } from 'store/SharedModels';
import { HomeInsight } from 'services/api/ApiClient';

export interface IMenuRequest extends IRequest {
    readonly menu?: number;
}

const unloadedState = () => {
    return {
        isFetching: false as boolean,
        didInvalidate: true as boolean,
        insight: undefined as HomeInsight | undefined,
    } as const;
};

export type HomeState = ReturnType<typeof unloadedState>;

export const HomeReducer: AppReducer<HomeState> = (state = unloadedState(), action) => {
    switch (action.type) {
        case '@HOME/GET_INSIGHT_REQUEST':
            return {
                ...state,
                isFetching: true,
            };

        case '@HOME/GET_INSIGHT_SUCCESS':
            return {
                ...state,
                isFetching: false,
                didInvalidate: false,
                insight: action.insight,
            };

        case '@HOME/GET_INSIGHT_FAILURE':
            return {
                ...state,
                isFetching: false,
                didInvalidate: true,
            };

        default:
            return state;
    }
};
