import { AppReducer } from 'store';
import { IRequest } from 'store/SharedModels';

export interface IGetTablesRequest extends IRequest {
    readonly tables: number[];
}

export interface IGetObjectsRequest extends IRequest {
    readonly objects: number[];
}

export interface ISearch {
    readonly show: boolean;
    readonly terms: string;
}

const unloadedState = () => {
    return {
        fetchTablesRequests: {} as { readonly [eventId: number]: IGetTablesRequest },
        fetchObjectsRequests: {} as { readonly [eventId: number]: IGetObjectsRequest },
        configurationRequests: {} as { readonly [eventId: number]: IRequest },
        updateConfigurationRequests: {} as { readonly [eventId: number]: boolean },
        updateTableRequests: {} as { readonly [tableId: number]: boolean },
        updateObjectRequests: {} as { readonly [objectId: number]: boolean },
        searches: {} as { readonly [eventId: number]: ISearch },
        highlightedGuests: [] as number[],
    } as const;
};

export const DEFAULT_REQUEST: IRequest = {
    isFetching: false,
    didInvalidate: true,
};

export const DEFAULT_TABLES_REQUEST: IGetTablesRequest = {
    isFetching: false,
    didInvalidate: true,
    tables: [],
};

export const DEFAULT_OBJECTS_REQUEST: IGetObjectsRequest = {
    isFetching: false,
    didInvalidate: true,
    objects: [],
};

export type SeatingPlansState = ReturnType<typeof unloadedState>;

export const SeatingPlansReducer: AppReducer<SeatingPlansState> = (state = unloadedState(), action) => {
    switch (action.type) {
        case '@SEATING_PLAN/GET_TABLES_REQUEST':
            return {
                ...state,
                fetchTablesRequests: {
                    ...state.fetchTablesRequests,
                    [action.eventId]: {
                        ...DEFAULT_TABLES_REQUEST,
                        ...state.fetchTablesRequests[action.eventId],
                        isFetching: true,
                    },
                },
            };

        case '@SEATING_PLAN/GET_TABLES_SUCCESS':
            return {
                ...state,
                fetchTablesRequests: {
                    ...state.fetchTablesRequests,
                    [action.eventId]: {
                        ...DEFAULT_TABLES_REQUEST,
                        ...state.fetchTablesRequests[action.eventId],
                        isFetching: false,
                        didInvalidate: false,
                        tables: action.tables,
                    },
                },
            };

        case '@SEATING_PLAN/GET_TABLES_FAILURE':
            return {
                ...state,
                fetchTablesRequests: {
                    ...state.fetchTablesRequests,
                    [action.eventId]: {
                        ...DEFAULT_TABLES_REQUEST,
                        ...state.fetchTablesRequests[action.eventId],
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        case '@SEATING_PLAN/GET_OBJECTS_REQUEST':
            return {
                ...state,
                fetchObjectsRequests: {
                    ...state.fetchObjectsRequests,
                    [action.eventId]: {
                        ...DEFAULT_OBJECTS_REQUEST,
                        ...state.fetchObjectsRequests[action.eventId],
                        isFetching: true,
                    },
                },
            };

        case '@SEATING_PLAN/GET_OBJECTS_SUCCESS':
            return {
                ...state,
                fetchObjectsRequests: {
                    ...state.fetchObjectsRequests,
                    [action.eventId]: {
                        ...DEFAULT_OBJECTS_REQUEST,
                        ...state.fetchObjectsRequests[action.eventId],
                        isFetching: false,
                        didInvalidate: false,
                        objects: action.objects,
                    },
                },
            };

        case '@SEATING_PLAN/GET_OBJECTS_FAILURE':
            return {
                ...state,
                fetchObjectsRequests: {
                    ...state.fetchObjectsRequests,
                    [action.eventId]: {
                        ...DEFAULT_OBJECTS_REQUEST,
                        ...state.fetchObjectsRequests[action.eventId],
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        case '@SEATING_PLAN/GET_SEATING_PLAN_CONFIGURATION_REQUEST':
            return {
                ...state,
                configurationRequests: {
                    ...state.configurationRequests,
                    [action.eventId]: {
                        ...DEFAULT_REQUEST,
                        ...state.configurationRequests[action.eventId],
                        isFetching: true,
                    },
                },
            };

        case '@SEATING_PLAN/GET_SEATING_PLAN_CONFIGURATION_SUCCESS':
            return {
                ...state,
                configurationRequests: {
                    ...state.configurationRequests,
                    [action.eventId]: {
                        ...DEFAULT_REQUEST,
                        ...state.configurationRequests[action.eventId],
                        isFetching: false,
                        didInvalidate: false,
                    },
                },
            };

        case '@SEATING_PLAN/GET_SEATING_PLAN_CONFIGURATION_FAILURE':
            return {
                ...state,
                configurationRequests: {
                    ...state.configurationRequests,
                    [action.eventId]: {
                        ...DEFAULT_REQUEST,
                        ...state.configurationRequests[action.eventId],
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        case '@SEATING_PLAN/UPDATE_SEATING_PLAN_CONFIGURATION_REQUEST':
            return {
                ...state,
                updateConfigurationRequests: {
                    ...state.updateConfigurationRequests,
                    [action.eventId]: true,
                },
            };

        case '@SEATING_PLAN/UPDATE_SEATING_PLAN_CONFIGURATION_SUCCESS':
            return {
                ...state,
                updateConfigurationRequests: {
                    ...state.updateConfigurationRequests,
                    [action.eventId]: false,
                },
            };

        case '@SEATING_PLAN/UPDATE_SEATING_PLAN_CONFIGURATION_FAILURE':
            return {
                ...state,
                updateConfigurationRequests: {
                    ...state.updateConfigurationRequests,
                    [action.eventId]: false,
                },
            };

        case '@SEATING_PLAN/DISPLAY_SEARCH':
            return {
                ...state,
                searches: {
                    ...state.searches,
                    [action.eventId]: {
                        ...state.searches[action.eventId],
                        terms: state.searches[action.eventId]?.terms || '',
                        show: action.show,
                    },
                },
            };

        case '@SEATING_PLAN/SEARCH':
            return {
                ...state,
                searches: {
                    ...state.searches,
                    [action.eventId]: {
                        ...state.searches[action.eventId],
                        show: true,
                        terms: action.terms,
                    },
                },
            };

        case '@SEATING_PLAN/UPDATE_TABLE_REQUEST':
            return {
                ...state,
                updateTableRequests: {
                    ...state.updateTableRequests,
                    [action.tableId]: true,
                },
            };

        case '@SEATING_PLAN/UPDATE_TABLE_SUCCESS':
        case '@SEATING_PLAN/UPDATE_TABLE_MODIFY_SUCCESS':
            return {
                ...state,
                updateTableRequests: {
                    ...state.updateTableRequests,
                    [action.tableId]: false,
                },
            };

        case '@SEATING_PLAN/UPDATE_TABLE_FAILURE':
            return {
                ...state,
                updateTableRequests: {
                    ...state.updateTableRequests,
                    [action.tableId]: false,
                },
            };

        case '@SEATING_PLAN/CREATE_TABLE_SUCCESS':
        case '@SEATING_PLAN/CREATE_TABLE_MODIFY_SUCCESS':
            return {
                ...state,
                fetchTablesRequests: {
                    ...state.fetchTablesRequests,
                    [action.eventId]: {
                        ...DEFAULT_TABLES_REQUEST,
                        ...state.fetchTablesRequests[action.eventId],
                        tables: [...state.fetchTablesRequests[action.eventId].tables, action.tableId],
                    },
                },
            };

        case '@SEATING_PLAN/DELETE_TABLE_SUCCESS':
        case '@SEATING_PLAN/DELETE_TABLE_MODIFY_SUCCESS':
            return {
                ...state,
                fetchTablesRequests: {
                    ...state.fetchTablesRequests,
                    [action.eventId]: {
                        ...DEFAULT_TABLES_REQUEST,
                        ...state.fetchTablesRequests[action.eventId],
                        tables: state.fetchTablesRequests[action.eventId].tables.filter((tId) => tId !== action.tableId),
                    },
                },
            };

        case '@SEATING_PLAN/UPDATE_OBJECT_REQUEST':
            return {
                ...state,
                updateObjectRequests: {
                    ...state.updateObjectRequests,
                    [action.objectId]: true,
                },
            };

        case '@SEATING_PLAN/UPDATE_OBJECT_SUCCESS':
        case '@SEATING_PLAN/UPDATE_OBJECT_MODIFY_SUCCESS':
            return {
                ...state,
                updateObjectRequests: {
                    ...state.updateObjectRequests,
                    [action.objectId]: false,
                },
            };

        case '@SEATING_PLAN/UPDATE_OBJECT_FAILURE':
            return {
                ...state,
                updateObjectRequests: {
                    ...state.updateObjectRequests,
                    [action.objectId]: false,
                },
            };

        case '@SEATING_PLAN/CREATE_OBJECT_SUCCESS':
        case '@SEATING_PLAN/CREATE_OBJECT_MODIFY_SUCCESS':
            return {
                ...state,
                fetchObjectsRequests: {
                    ...state.fetchObjectsRequests,
                    [action.eventId]: {
                        ...DEFAULT_OBJECTS_REQUEST,
                        ...state.fetchObjectsRequests[action.eventId],
                        objects: [...state.fetchObjectsRequests[action.eventId].objects, action.objectId],
                    },
                },
            };

        case '@SEATING_PLAN/DELETE_OBJECT_SUCCESS':
        case '@SEATING_PLAN/DELETE_OBJECT_MODIFY_SUCCESS':
            return {
                ...state,
                fetchObjectsRequests: {
                    ...state.fetchObjectsRequests,
                    [action.eventId]: {
                        ...DEFAULT_OBJECTS_REQUEST,
                        ...state.fetchObjectsRequests[action.eventId],
                        objects: state.fetchObjectsRequests[action.eventId].objects.filter((tId) => tId !== action.objectId),
                    },
                },
            };

        case '@TEMPLATES/APPLY_TEMPLATE_SUCCESS':
            return {
                ...state,
                fetchTablesRequests: {
                    ...state.fetchTablesRequests,
                    [action.eventId]: {
                        ...DEFAULT_TABLES_REQUEST,
                        ...state.fetchTablesRequests[action.eventId],
                        didInvalidate: true,
                    },
                },
                fetchObjectsRequests: {
                    ...state.fetchObjectsRequests,
                    [action.eventId]: {
                        ...DEFAULT_OBJECTS_REQUEST,
                        ...state.fetchObjectsRequests[action.eventId],
                        didInvalidate: true,
                    },
                },
                configurationRequests: {
                    ...state.configurationRequests,
                    [action.eventId]: {
                        ...DEFAULT_REQUEST,
                        ...state.configurationRequests[action.eventId],
                        didInvalidate: true,
                    },
                },
            };

        case '@SEATING_PLAN/HIGHLIGHT_GUEST':
            return {
                ...state,
                highlightedGuests: action.highlighted ? [...state.highlightedGuests, action.guestId] : state.highlightedGuests.filter((g) => g !== action.guestId),
            };

        case '@EVENT_DETAILS/UPDATE_EVENT_SUCCESS':
            return {
                ...state,
                configurationRequests: {
                    ...state.configurationRequests,
                    [action.eventId]: {
                        ...DEFAULT_REQUEST,
                        ...state.configurationRequests[action.eventId],
                        didInvalidate: true,
                    },
                },
            };

        case '@SIGNALR/SEATING_PLAN_UPDATED':
        case '@SIGNALR/TABLE_UPDATED':
        case '@SIGNALR/TABLE_CREATED':
        case '@SIGNALR/TABLE_DELETED':
            return {
                ...state,
                fetchTablesRequests: {
                    ...state.fetchTablesRequests,
                    [action.payload.eventId]: {
                        ...DEFAULT_TABLES_REQUEST,
                        ...state.fetchTablesRequests[action.payload.eventId],
                        didInvalidate: true,
                    },
                },
            };

        case '@SIGNALR/SEATING_PLAN_CONFIGURATION_UPDATED':
            return {
                ...state,
                configurationRequests: {
                    ...state.configurationRequests,
                    [action.payload.eventId]: {
                        ...state.configurationRequests[action.payload.eventId],
                        didInvalidate: true,
                    },
                },
            };

        default:
            return state;
    }
};
