import { PresenceType } from 'services/api/ApiClient';
import { AppReducer } from 'store';
import { IRequest } from 'store/SharedModels';

export const DEFAULT_TAKE_SIZE = 30;
export const DEFAULT_LIGHT_GUESTS_TAKE_SIZE = 10;

export interface ISearchGuestsRequest extends IRequest {
    readonly searchTerms: string;
    readonly filterPresence?: PresenceType;
    readonly skip: number;
    readonly take: number;
    readonly totalResults: number;
    readonly guests: number[];
}

export interface ILightGuestRequest extends IRequest {
    readonly lightGuests: number[];
}

export interface ILightGuestFilter {
    readonly searchTerms: string;
    readonly presence: PresenceType[];
    readonly notSeatedOnly: boolean;
    readonly skip: number;
    readonly take: number;
}

const unloadedState = () => {
    return {
        search: {} as { readonly [eventId: number]: ISearchGuestsRequest },

        guestDetailsRequests: {} as { readonly [guestId: number]: IRequest },
        guestUpdateRequests: {} as { readonly [guestId: string]: boolean },
        guestDeleteRequests: {} as { readonly [guestId: number]: boolean },

        sendInvitationToMyselfRequests: {} as { readonly [eventId: number]: boolean },
        sendInvitationsRequests: {} as { readonly [eventId: number]: boolean },

        sendRequestPlacementPreferencesToMyselfRequests: {} as { readonly [eventId: number]: boolean },
        sendRequestPlacementPreferencesRequests: {} as { readonly [eventId: number]: boolean },

        lightGuests: {} as { readonly [eventId: number]: ILightGuestRequest },
        lightGuestsFilters: {} as { readonly [eventId: number]: ILightGuestFilter },

        answerInvitation: {} as { readonly [eventId: number]: boolean },
    } as const;
};

export const DEFAULT_SEARCH: ISearchGuestsRequest = {
    isFetching: false,
    didInvalidate: true,
    searchTerms: '',
    filterPresence: undefined,
    skip: 0,
    take: DEFAULT_TAKE_SIZE,
    guests: [],
    totalResults: 0,
};

export const DEFAULT_LIGHT_GUESTS_FILTER: ILightGuestFilter = {
    searchTerms: '',
    presence: [PresenceType.InvitationNotSent, PresenceType.InvitationSent, PresenceType.Present, PresenceType.PresentOnlyReception],
    notSeatedOnly: false,
    skip: 0,
    take: DEFAULT_LIGHT_GUESTS_TAKE_SIZE,
};

export type GuestsState = ReturnType<typeof unloadedState>;

export const GuestsReducer: AppReducer<GuestsState> = (state = unloadedState(), action) => {
    switch (action.type) {
        case '@GUESTS/SEARCH_GUESTS_REQUEST':
            return {
                ...state,
                search: {
                    ...state.search,
                    [action.eventId]: {
                        ...DEFAULT_SEARCH,
                        ...state.search[action.eventId],
                        isFetching: true,
                    },
                },
            };

        case '@GUESTS/SEARCH_GUESTS_SUCCESS':
            return {
                ...state,
                search: {
                    ...state.search,
                    [action.eventId]: {
                        ...DEFAULT_SEARCH,
                        ...state.search[action.eventId],
                        isFetching: false,
                        didInvalidate: false,
                        totalResults: action.totalResults,
                        guests: action.guests,
                    },
                },
                guestDetailsRequests: {
                    ...state.guestDetailsRequests,
                    ...action.guests.reduce<{ [id: number]: IRequest }>((map, guestId) => {
                        map[guestId] = {
                            isFetching: false,
                            didInvalidate: false,
                        };
                        return map;
                    }, {}),
                },
            };

        case '@GUESTS/SEARCH_GUESTS_FAILURE':
            return {
                ...state,
                search: {
                    ...state.search,
                    [action.eventId]: {
                        ...DEFAULT_SEARCH,
                        ...state.search[action.eventId],
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        case '@GUESTS/SET_SEARCH_TERMS':
            return {
                ...state,
                search: {
                    ...state.search,
                    [action.eventId]: {
                        ...DEFAULT_SEARCH,
                        ...state.search[action.eventId],
                        searchTerms: action.searchTerms,
                        skip: 0,
                        didInvalidate: true,
                    },
                },
            };

        case '@GUESTS/SET_FILTER_PRESENCE':
            return {
                ...state,
                search: {
                    ...state.search,
                    [action.eventId]: {
                        ...DEFAULT_SEARCH,
                        ...state.search[action.eventId],
                        filterPresence: action.filterPresence,
                        skip: 0,
                        didInvalidate: !state.search[action.eventId] || action.filterPresence !== state.search[action.eventId].filterPresence,
                    },
                },
            };

        case '@GUESTS/CHANGE_PAGE':
            return {
                ...state,
                search: {
                    ...state.search,
                    [action.eventId]: {
                        ...DEFAULT_SEARCH,
                        ...state.search[action.eventId],
                        skip: action.skip,
                        take: action.take,
                        didInvalidate: true,
                    },
                },
            };

        case '@GUESTS/GET_GUEST_REQUEST':
            return {
                ...state,
                guestDetailsRequests: {
                    ...state.guestDetailsRequests,
                    [action.guestId]: {
                        ...state.guestDetailsRequests[action.guestId],
                        didInvalidate: state.guestDetailsRequests[action.guestId]?.didInvalidate || false,
                        isFetching: true,
                    },
                },
            };

        case '@GUESTS/GET_GUEST_SUCCESS':
            return {
                ...state,
                guestDetailsRequests: {
                    ...state.guestDetailsRequests,
                    [action.guestId]: {
                        ...state.guestDetailsRequests[action.guestId],
                        isFetching: false,
                        didInvalidate: false,
                    },
                },
            };

        case '@GUESTS/GET_GUEST_FAILURE':
            return {
                ...state,
                guestDetailsRequests: {
                    ...state.guestDetailsRequests,
                    [action.guestId]: {
                        ...state.guestDetailsRequests[action.guestId],
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        case '@GUESTS/UPDATE_GUEST_REQUEST':
            return {
                ...state,
                guestUpdateRequests: {
                    ...state.guestUpdateRequests,
                    [action.guestId]: true,
                },
            };

        case '@GUESTS/UPDATE_GUEST_SUCCESS':
            {
                const guestDetailsRequestsUpdate = {
                    ...state.guestDetailsRequests,
                };
                const updatedGuestId = parseInt(action.guestId, 10);
                if (updatedGuestId) {
                    guestDetailsRequestsUpdate[updatedGuestId] = {
                        ...state.guestDetailsRequests[updatedGuestId],
                        didInvalidate: true,
                    };
                }
                return {
                    ...state,
                    search: {
                        ...state.search,
                        [action.eventId]: {
                            ...state.search[action.eventId],
                            didInvalidate: true,
                        },
                    },
                    guestDetailsRequests: guestDetailsRequestsUpdate,
                    guestUpdateRequests: {
                        ...state.guestUpdateRequests,
                        [action.guestId]: false,
                    },
                    lightGuests: {
                        ...state.lightGuests,
                        [action.eventId]: {
                            ...state.lightGuests[action.eventId],
                            didInvalidate: true,
                        },
                    },
                };
            }

        case '@GUESTS/UPDATE_GUEST_FAILURE':
            return {
                ...state,
                guestUpdateRequests: {
                    ...state.guestUpdateRequests,
                    [action.guestId]: false,
                },
            };

        case '@GUESTS/DELETE_GUEST_REQUEST':
            return {
                ...state,
                guestDeleteRequests: {
                    ...state.guestDeleteRequests,
                    [action.guestId]: true,
                },
            };

        case '@GUESTS/DELETE_GUEST_SUCCESS':
            {
                const guestDetailsRequests = {
                    ...state.guestDetailsRequests,
                };
                delete guestDetailsRequests[action.guestId];
                return {
                    ...state,
                    search: {
                        ...state.search,
                        [action.eventId]: {
                            ...state.search[action.eventId],
                            guests: state.search[action.eventId]?.guests?.filter((guestId) => guestId !== action.guestId) || [],
                        },
                    },
                    guestDetailsRequests,
                    guestDeleteRequests: {
                        ...state.guestDeleteRequests,
                        [action.guestId]: false,
                    },
                    lightGuests: {
                        ...state.lightGuests,
                        [action.eventId]: {
                            ...state.lightGuests[action.eventId],
                            lightGuests: state.lightGuests[action.eventId]?.lightGuests?.filter((guestId) => guestId !== action.guestId) || [],
                        },
                    },
                };
            }

        case '@GUESTS/DELETE_GUEST_FAILURE':
            return {
                ...state,
                guestDeleteRequests: {
                    ...state.guestDeleteRequests,
                    [action.guestId]: false,
                },
            };

        case '@LIGHT_GUESTS/GET_LIGHT_GUESTS_REQUEST':
            {
                const lightGuests = {
                    ...state.lightGuests,
                };
                if (lightGuests[action.eventId]) {
                    lightGuests[action.eventId] = {
                        ...lightGuests[action.eventId],
                        isFetching: true,
                    };
                } else {
                    lightGuests[action.eventId] = {
                        isFetching: true,
                        didInvalidate: false,
                        lightGuests: [],
                    };
                }
                return {
                    ...state,
                    lightGuests,
                };
            }

        case '@LIGHT_GUESTS/GET_LIGHT_GUESTS_SUCCESS':
        case '@LIGHT_GUESTS/GET_LIGHT_GUESTS_FORCE_EMPTY':
            return {
                ...state,
                lightGuests: {
                    ...state.lightGuests,
                    [action.eventId]: {
                        ...state.lightGuests[action.eventId],
                        isFetching: false,
                        didInvalidate: false,
                        lightGuests: action.lightGuests,
                    },
                },
            };

        case '@LIGHT_GUESTS/GET_LIGHT_GUESTS_FAILURE':
            return {
                ...state,
                lightGuests: {
                    ...state.lightGuests,
                    [action.eventId]: {
                        ...state.lightGuests[action.eventId],
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        case '@LIGHT_GUESTS/SET_FILTER_NOT_SEATED_ONLY':
            return {
                ...state,
                lightGuestsFilters: {
                    ...state.lightGuestsFilters,
                    [action.eventId]: {
                        ...DEFAULT_LIGHT_GUESTS_FILTER,
                        ...state.lightGuestsFilters[action.eventId],
                        notSeatedOnly: action.notSeatedOnly,
                        skip: 0,
                    },
                },
            };

        case '@LIGHT_GUESTS/SET_SEARCH_TERMS':
            return {
                ...state,
                lightGuestsFilters: {
                    ...state.lightGuestsFilters,
                    [action.eventId]: {
                        ...DEFAULT_LIGHT_GUESTS_FILTER,
                        ...state.lightGuestsFilters[action.eventId],
                        searchTerms: action.searchTerms,
                        skip: 0,
                    },
                },
            };

        case '@LIGHT_GUESTS/SET_FILTER_PRESENCE':
            return {
                ...state,
                lightGuestsFilters: {
                    ...state.lightGuestsFilters,
                    [action.eventId]: {
                        ...DEFAULT_LIGHT_GUESTS_FILTER,
                        ...state.lightGuestsFilters[action.eventId],
                        presence: action.presence,
                        skip: 0,
                    },
                },
            };

        case '@LIGHT_GUESTS/CHANGE_PAGE':
            return {
                ...state,
                lightGuestsFilters: {
                    ...state.lightGuestsFilters,
                    [action.eventId]: {
                        ...DEFAULT_LIGHT_GUESTS_FILTER,
                        ...state.lightGuestsFilters[action.eventId],
                        skip: action.skip,
                        take: action.take,
                    },
                },
            };

        case '@GUESTS_CSV_IMPORT/CREATE_GUEST_SUCCESS':
            {
                const invalidatedLightGuests = {
                    ...state.lightGuests,
                };
                if (invalidatedLightGuests[action.eventId]) {
                    invalidatedLightGuests[action.eventId] = {
                        ...invalidatedLightGuests[action.eventId],
                        didInvalidate: true,
                    };
                }
                return {
                    ...state,
                    search: {
                        ...state.search,
                        [action.eventId]: {
                            ...state.search[action.eventId],
                            didInvalidate: true,
                        },
                    },
                    lightGuests: invalidatedLightGuests,
                };
            }

        case '@GUESTS/SEND_INVITATION_TO_MYSELF':
            return {
                ...state,
                sendInvitationToMyselfRequests: {
                    ...state.sendInvitationToMyselfRequests,
                    [action.eventId]: true,
                },
            };

        case '@GUESTS/SEND_INVITATION_TO_MYSELF_FAILURE':
        case '@GUESTS/SEND_INVITATION_TO_MYSELF_SUCCESS':
            return {
                ...state,
                sendInvitationToMyselfRequests: {
                    ...state.sendInvitationToMyselfRequests,
                    [action.eventId]: false,
                },
            };

        case '@GUESTS/SEND_INVITATIONS':
            return {
                ...state,
                sendInvitationsRequests: {
                    ...state.sendInvitationsRequests,
                    [action.eventId]: true,
                },
            };

        case '@GUESTS/SEND_INVITATIONS_FAILURE':
        case '@GUESTS/SEND_INVITATIONS_SUCCESS':
            return {
                ...state,
                sendInvitationsRequests: {
                    ...state.sendInvitationsRequests,
                    [action.eventId]: false,
                },
            };

        case '@GUESTS/SEND_REQUEST_PLACEMENT_PREFERENCES_TO_MYSELF':
            return {
                ...state,
                sendRequestPlacementPreferencesToMyselfRequests: {
                    ...state.sendRequestPlacementPreferencesToMyselfRequests,
                    [action.eventId]: true,
                },
            };

        case '@GUESTS/SEND_REQUEST_PLACEMENT_PREFERENCES_TO_MYSELF_FAILURE':
        case '@GUESTS/SEND_REQUEST_PLACEMENT_PREFERENCES_TO_MYSELF_SUCCESS':
            return {
                ...state,
                sendRequestPlacementPreferencesToMyselfRequests: {
                    ...state.sendRequestPlacementPreferencesToMyselfRequests,
                    [action.eventId]: false,
                },
            };

        case '@GUESTS/SEND_REQUEST_PLACEMENT_PREFERENCES':
            return {
                ...state,
                sendRequestPlacementPreferencesRequests: {
                    ...state.sendRequestPlacementPreferencesRequests,
                    [action.eventId]: true,
                },
            };

        case '@GUESTS/SEND_REQUEST_PLACEMENT_PREFERENCES_FAILURE':
        case '@GUESTS/SEND_REQUEST_PLACEMENT_PREFERENCES_SUCCESS':
            return {
                ...state,
                sendRequestPlacementPreferencesRequests: {
                    ...state.sendRequestPlacementPreferencesRequests,
                    [action.eventId]: false,
                },
            };

        case '@GUESTS/ANSWER_INVITATION_REQUEST':
            return {
                ...state,
                answerInvitation: {
                    ...state.answerInvitation,
                    [action.eventId]: true,
                },
            };

        case '@GUESTS/ANSWER_INVITATION_SUCCESS':
        case '@GUESTS/ANSWER_INVITATION_FAILURE':
            return {
                ...state,
                answerInvitation: {
                    ...state.answerInvitation,
                    [action.eventId]: false,
                },
            };

        case '@SIGNALR/GUEST_UPDATED':
        case '@SIGNALR/GUEST_CREATED':
        case '@SIGNALR/GUEST_DELETED':
            return {
                ...state,
                search: {
                    ...state.search,
                    [action.payload.eventId]: {
                        ...state.search[action.payload.eventId],
                        didInvalidate: true,
                    },
                },
                guestDetailsRequests: {
                    ...state.guestDetailsRequests,
                    [action.payload.eventId]: {
                        ...state.guestDetailsRequests[action.payload.eventId],
                        didInvalidate: true,
                    },
                },
                lightGuests: {
                    ...state.lightGuests,
                    [action.payload.eventId]: {
                        ...state.lightGuests[action.payload.eventId],
                        didInvalidate: true,
                    },
                },
            };

        default:
            return state;
    }
};
