import i18next from 'i18next';
import { Middleware } from 'redux';
import { toastr } from 'react-redux-toastr';
import { SignalRService } from 'services/SignalRService';
import { SignalRAction } from 'store/signalR/actions';
import { getTables, getObjects, getSeatingPlanConfiguration } from 'store/seatingPlans/thunk';
import { AppThunkDispatch, ApplicationState } from 'store';
import { fetchLightGuests, searchGuests } from 'store/guests/thunk';
import { getChecklist } from 'store/checklists/thunk';
import { getMenu } from 'store/menus/thunk';
import { getInformation } from 'store/information/thunk';
import { getConfiguration, getGroups } from 'store/constraintReports/thunk';
import { createInvalidateConstraintReportsRequestAction } from 'store/constraintReports/actions';

export const signalRMiddleware: Middleware<unknown, ApplicationState, AppThunkDispatch> = (api) => {
    const signalR = SignalRService.Instance;
    signalR.connection.onreconnected(() => {
        const state = api.getState();
        if (state.signalR.currentEventId) {
            signalR.invoke('AddToEventGroup', state.signalR.currentEventId);
        } else if (state.signalR.currentCustomUrl) {
            signalR.invoke('AddToEventGroupByCustomUrl', state.signalR.currentCustomUrl);
        }
    });

    signalR.connection.on('ReceiveMessage', (message: string) => {
        toastr.success(
            i18next.t('Common:Success'),
            i18next.t(message),
        );
    });

    signalR.connection.on('ReceiveAction', (action: SignalRAction) => {
        api.dispatch(action);

        if (action.type === '@SIGNALR/START') {
            return;
        }

        const state = api.getState();
        if (state.signalR.currentEventId === action.payload.eventId
            || state.signalR.currentCustomUrl && state.eventDetails.customUrls[state.signalR.currentCustomUrl]?.eventId == action.payload.eventId) {
            switch (action.type) {
                case '@SIGNALR/SEATING_PLAN_UPDATED':
                    api.dispatch(getTables(action.payload.eventId)).catch(void 0);
                    api.dispatch(getObjects(action.payload.eventId)).catch(void 0);
                    break;

                case '@SIGNALR/TABLE_UPDATED':
                case '@SIGNALR/TABLE_CREATED':
                case '@SIGNALR/TABLE_DELETED':
                    api.dispatch(getTables(action.payload.eventId)).catch(void 0);
                    break;

                case '@SIGNALR/OBJECT_UPDATED':
                case '@SIGNALR/OBJECT_CREATED':
                case '@SIGNALR/OBJECT_DELETED':
                    api.dispatch(getObjects(action.payload.eventId)).catch(void 0);
                    break;

                case '@SIGNALR/GUEST_UPDATED':
                case '@SIGNALR/GUEST_CREATED':
                case '@SIGNALR/GUEST_DELETED':
                    api.dispatch(fetchLightGuests(action.payload.eventId)).catch(void 0);
                    api.dispatch(searchGuests(action.payload.eventId)).catch(void 0);
                    break;

                case '@SIGNALR/CHECKLIST_ITEM_UPDATED':
                case '@SIGNALR/CHECKLIST_ITEM_CREATED':
                case '@SIGNALR/CHECKLIST_ITEM_DELETED':
                    api.dispatch(getChecklist(action.payload.eventId)).catch(void 0);
                    break;

                case '@SIGNALR/SEATING_PLAN_CONFIGURATION_UPDATED':
                    api.dispatch(getSeatingPlanConfiguration(action.payload.eventId)).catch(void 0);
                    break;

                case '@SIGNALR/MENU_UPDATED':
                    api.dispatch(getMenu(action.payload.eventId)).catch(void 0);
                    break;

                case '@SIGNALR/INFORMATION_UPDATED':
                    api.dispatch(getInformation(action.payload.eventId)).catch(void 0);
                    break;

                case '@SIGNALR/CONSTRAINT_REPORT_CONFIGURATION_UPDATED':
                    api.dispatch(getConfiguration(action.payload.eventId)).catch(void 0);
                    api.dispatch(createInvalidateConstraintReportsRequestAction(action.payload.eventId));
                    break;

                case '@SIGNALR/CONSTRAINT_REPORT_GROUP_UPDATED':
                case '@SIGNALR/CONSTRAINT_REPORT_GROUP_CREATED':
                case '@SIGNALR/CONSTRAINT_REPORT_GROUP_DELETED':
                    api.dispatch(getGroups(action.payload.eventId)).catch(void 0);
                    api.dispatch(createInvalidateConstraintReportsRequestAction(action.payload.eventId));
                    break;
            }
        }
    });

    return (next) => (action) => {
        if (action.type === '@SIGNALR/START') {
            SignalRService.Instance.start();
            return;
        }
        return next(action);
    };
};
