import { Action, Middleware } from 'redux';
import { ReduxState } from 'services/api/ApiClient';
import { ApplicationState } from 'store';

export const actionsRecorderMiddleware: Middleware = (api) => {
    return (next) => (action) => {
        const state = api.getState();
        middlewareData.addAction(state, action);

        return next(action);
    };
};

const MAX_NUMBER_OF_ACTIONS_TO_RECORD = 20;

class MiddlewareData {
    private static MiddlewareDataInstance: MiddlewareData;
    private actions: Action[] = [];
    private initialState: ApplicationState[] = [];

    public addAction(state: ApplicationState, action: Action) {
        this.initialState.push(state);
        this.actions.push(action);

        if (this.initialState.length > MAX_NUMBER_OF_ACTIONS_TO_RECORD) {
            this.initialState.shift();
            this.actions.shift();
        }
    }

    public clear() {
        this.initialState = [];
        this.actions = [];
    }

    public getReduxRecord(): ReduxState {
        return {
            preloadedState: JSON.stringify(this.initialState[0]),
            payload: JSON.stringify(this.actions),
        };
    }

    public static get Instance() {
        // Do you need arguments? Make it a regular static method instead.
        return this.MiddlewareDataInstance || (this.MiddlewareDataInstance = new this());
    }
}

export const middlewareData = MiddlewareData.Instance;
