import { AppReducer } from 'store';
import { IRequest } from 'store/SharedModels';

export interface ISearchProDomainRequest extends IRequest {
    readonly boundingBox: number[];
    readonly searchTerms: string;
    readonly proDomains: number[];
}

const unloadedState = () => {
    return {
        search: {
            isFetching: false,
            didInvalidate: true,
            boundingBox: [],
            searchTerms: '',
            proDomains: [],
        } as ISearchProDomainRequest,
        proDomainRequests: {} as { readonly [proDomainId: number]: IRequest },
    } as const;
};

export type ProDomainsState = ReturnType<typeof unloadedState>;

export const ProDomainsReducer: AppReducer<ProDomainsState> = (state = unloadedState(), action) => {
    switch (action.type) {
        case '@PRO_DOMAINS/SEARCH_PRO_DOMAINS_REQUEST':
            return {
                ...state,
                search: {
                    ...state.search,
                    didInvalidate: true,
                    isFetching: true,
                    boundingBox: action.boundingBox,
                    searchTerms: action.searchTerms || '',
                },
            };

        case '@PRO_DOMAINS/SEARCH_PRO_DOMAINS_SUCCESS':
            return {
                ...state,
                search: {
                    ...state.search,
                    didInvalidate: false,
                    isFetching: false,
                    proDomains: action.proDomains,
                },
            };

        case '@PRO_DOMAINS/SEARCH_PRO_DOMAINS_FAILURE':
            return {
                ...state,
                search: {
                    ...state.search,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        case '@PRO_DOMAINS/GET_PRO_DOMAIN_REQUEST':
            return {
                ...state,
                proDomainRequests: {
                    ...state.proDomainRequests,
                    [action.proDomainId]: {
                        ...state.proDomainRequests[action.proDomainId],
                        didInvalidate: state.proDomainRequests[action.proDomainId]?.didInvalidate || false,
                        isFetching: true,
                    },
                },
            };

        case '@PRO_DOMAINS/GET_PRO_DOMAIN_SUCCESS':
            return {
                ...state,
                proDomainRequests: {
                    ...state.proDomainRequests,
                    [action.proDomainId]: {
                        ...state.proDomainRequests[action.proDomainId],
                        isFetching: false,
                        didInvalidate: false,
                    },
                },
            };

        case '@PRO_DOMAINS/GET_PRO_DOMAIN_FAILURE':
            return {
                ...state,
                proDomainRequests: {
                    ...state.proDomainRequests,
                    [action.proDomainId]: {
                        ...state.proDomainRequests[action.proDomainId],
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        default:
            return state;
    }
};
