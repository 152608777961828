
import { AppReducer } from 'store';
import { IRequest } from 'store/SharedModels';

export interface IRightsRequest extends IRequest {
    readonly rightsModelId?: string;
}

const unloadedState = () => {
    return {
        eventsRights: {} as { readonly [eventId: number]: IRightsRequest },
    } as const;
};

export type RightsState = ReturnType<typeof unloadedState>;

export const RightsReducer: AppReducer<RightsState> = (state = unloadedState(), action) => {
    switch (action.type) {
        case '@RIGHTS/GET_RIGHTS_REQUEST':
            return {
                ...state,
                eventsRights: {
                    ...state.eventsRights,
                    [action.eventId || 0]: {
                        ...state.eventsRights[action.eventId || 0],
                        didInvalidate: true,
                        isFetching: true,
                    },
                },
            };

        case '@RIGHTS/GET_RIGHTS_SUCCESS':
            return {
                ...state,
                eventsRights: {
                    ...state.eventsRights,
                    [action.eventId || 0]: {
                        ...state.eventsRights[action.eventId || 0],
                        didInvalidate: false,
                        isFetching: false,
                        rightsModelId: action.rightsModelId,
                    },
                },
            };

        case '@RIGHTS/GET_RIGHTS_FAILURE':
            return {
                ...state,
                eventsRights: {
                    ...state.eventsRights,
                    [action.eventId || 0]: {
                        ...state.eventsRights[action.eventId || 0],
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        default:
            return state;
    }
};
