export const createSetSearchTermsAction = (eventId: number, searchTerms: string) => {
    return {
        type: '@CHECKLIST/SET_SEARCH_TERMS',
        eventId,
        searchTerms,
    } as const;
};

export const createGetChecklistAction = (eventId: number) => {
    return {
        type: '@CHECKLIST/GET_CHECKLIST_REQUEST',
        eventId,
    } as const;
};

export const createGetChecklistSuccessAction = (eventId: number) => {
    return {
        type: '@CHECKLIST/GET_CHECKLIST_SUCCESS',
        eventId,
    } as const;
};

export const createGetChecklistFailureAction = (eventId: number) => {
    return {
        type: '@CHECKLIST/GET_CHECKLIST_FAILURE',
        eventId,
    } as const;
};

export const createUpdateItemRequestAction = (itemId: number) => {
    return {
        type: '@CHECKLIST/UPDATE_ITEM_REQUEST',
        itemId,
    } as const;
};

export const createUpdateItemRequestSuccessAction = (eventId: number, itemId: number) => {
    return {
        type: '@CHECKLIST/UPDATE_ITEM_SUCCESS',
        eventId,
        itemId,
    } as const;
};

export const createUpdateItemRequestFailureAction = (itemId: number) => {
    return {
        type: '@CHECKLIST/UPDATE_ITEM_FAILURE',
        itemId,
    } as const;
};

export const createDeleteItemRequestAction = (itemId: number) => {
    return {
        type: '@CHECKLIST/DELETE_ITEM_REQUEST',
        itemId,
    } as const;
};

export const createDeleteItemRequestSuccessAction = (eventId: number, itemId: number) => {
    return {
        type: '@CHECKLIST/DELETE_ITEM_SUCCESS',
        eventId,
        itemId,
    } as const;
};

export const createDeleteItemRequestFailureAction = (itemId: number) => {
    return {
        type: '@CHECKLIST/DELETE_ITEM_FAILURE',
        itemId,
    } as const;
};

export const createGetChecklistTemplatesAction = () => {
    return {
        type: '@CHECKLIST/GET_CHECKLIST_TEMPLATES_REQUEST',
    } as const;
};

export const createGetChecklistTemplatesSuccessAction = (templates: number[]) => {
    return {
        type: '@CHECKLIST/GET_CHECKLIST_TEMPLATES_SUCCESS',
        templates,
    } as const;
};

export const createGetChecklistTemplatesFailureAction = () => {
    return {
        type: '@CHECKLIST/GET_CHECKLIST_TEMPLATES_FAILURE',
    } as const;
};

export const createGetChecklistTemplateItemsAction = (templateId: number) => {
    return {
        type: '@CHECKLIST/GET_CHECKLIST_TEMPLATE_ITEMS_REQUEST',
        templateId,
    } as const;
};

export const createGetChecklistTemplateItemsSuccessAction = (templateId: number, items: number[]) => {
    return {
        type: '@CHECKLIST/GET_CHECKLIST_TEMPLATE_ITEMS_SUCCESS',
        templateId,
        items,
    } as const;
};

export const createGetChecklistTemplateItemsFailureAction = (templateId: number) => {
    return {
        type: '@CHECKLIST/GET_CHECKLIST_TEMPLATE_ITEMS_FAILURE',
        templateId,
    } as const;
};

export const createSelectTemplateAction = (templateId: number) => {
    return {
        type: '@CHECKLIST/SELECT_TEMPLATE',
        templateId,
    } as const;
};

export const createAddTemplateItemAction = (templateId: number, itemId: number) => {
    return {
        type: '@CHECKLIST/ADD_TEMPLATE_ITEM',
        templateId,
        itemId,
    } as const;
};

export const createRemoveTemplateItemAction = (templateId: number, itemId: number) => {
    return {
        type: '@CHECKLIST/REMOVE_TEMPLATE_ITEM',
        templateId,
        itemId,
    } as const;
};

export const createSetTemplateItemsSearchTermsAction = (searchTerms: string) => {
    return {
        type: '@CHECKLIST/SET_TEMPLATE_ITEMS_SEARCH_TERMS',
        searchTerms,
    } as const;
};

export const createAddItemsFromTemplateAction = () => {
    return {
        type: '@CHECKLIST/ADD_ITEMS_FROM_TEMPLATE_REQUEST',
    } as const;
};

export const createAddItemsFromTemplateSuccessAction = (eventId: number) => {
    return {
        type: '@CHECKLIST/ADD_ITEMS_FROM_TEMPLATE_SUCCESS',
        eventId,
    } as const;
};

export const createAddItemsFromTemplateFailureAction = () => {
    return {
        type: '@CHECKLIST/ADD_ITEMS_FROM_TEMPLATE_FAILURE',
    } as const;
};

export type ChecklistsAction =
    | ReturnType<typeof createSetSearchTermsAction>
    | ReturnType<typeof createGetChecklistAction>
    | ReturnType<typeof createGetChecklistSuccessAction>
    | ReturnType<typeof createGetChecklistFailureAction>
    | ReturnType<typeof createUpdateItemRequestAction>
    | ReturnType<typeof createUpdateItemRequestSuccessAction>
    | ReturnType<typeof createUpdateItemRequestFailureAction>
    | ReturnType<typeof createDeleteItemRequestAction>
    | ReturnType<typeof createDeleteItemRequestSuccessAction>
    | ReturnType<typeof createDeleteItemRequestFailureAction>
    | ReturnType<typeof createGetChecklistTemplatesAction>
    | ReturnType<typeof createGetChecklistTemplatesSuccessAction>
    | ReturnType<typeof createGetChecklistTemplatesFailureAction>
    | ReturnType<typeof createGetChecklistTemplateItemsAction>
    | ReturnType<typeof createGetChecklistTemplateItemsSuccessAction>
    | ReturnType<typeof createGetChecklistTemplateItemsFailureAction>
    | ReturnType<typeof createSelectTemplateAction>
    | ReturnType<typeof createAddTemplateItemAction>
    | ReturnType<typeof createRemoveTemplateItemAction>
    | ReturnType<typeof createSetTemplateItemsSearchTermsAction>
    | ReturnType<typeof createAddItemsFromTemplateAction>
    | ReturnType<typeof createAddItemsFromTemplateSuccessAction>
    | ReturnType<typeof createAddItemsFromTemplateFailureAction>;
