export const createGetRightsRequestAction = (eventId?: number) => {
    return {
        type: '@RIGHTS/GET_RIGHTS_REQUEST',
        eventId,
    } as const;
};

export const createGetRightsRequestSuccessAction = (eventId?: number, rightsModelId?: string) => {
    return {
        type: '@RIGHTS/GET_RIGHTS_SUCCESS',
        eventId,
        rightsModelId,
    } as const;
};

export const createGetRightsRequestFailureAction = (eventId?: number) => {
    return {
        type: '@RIGHTS/GET_RIGHTS_FAILURE',
        eventId,
    } as const;
};

export type RightsAction =
    | ReturnType<typeof createGetRightsRequestAction>
    | ReturnType<typeof createGetRightsRequestSuccessAction>
    | ReturnType<typeof createGetRightsRequestFailureAction>;
