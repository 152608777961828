import * as React from 'react';
import { FacebookProvider, Like, LikeLayout, LikeAction } from 'react-facebook';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ILikeButtonProps } from './LikeButtonModels';

class LikeButtonComponent extends React.PureComponent<ILikeButtonProps & WithTranslation> {
    public render() {
        const { appId, pageUrl, i18n } = this.props;
        return appId && pageUrl ? (
            <div style={{ width: '145px', height: '25px' }}>
                <FacebookProvider appId={appId} language={i18n.language}>
                    <Like
                        href={pageUrl}
                        showFaces={false}
                        share={true}
                        layout={LikeLayout.BUTTON_COUNT}
                        width={145}
                        height={25}
                        action={LikeAction.LIKE}
                    />
                </FacebookProvider>
            </div>
        ) : null;
    }
}

export const LikeButton = withTranslation()(LikeButtonComponent);
