import {
    JsonHubProtocol,
    HubConnectionBuilder,
    HubConnection,
    HubConnectionState,
    HttpTransportType,
} from '@microsoft/signalr';
import { getAuthInfo } from 'services/AuthService';
import { getBaseApiUrl } from 'config/constants';

export class SignalRService {
    private static SignalRServiceInstance: SignalRService;
    public readonly connection: HubConnection;

    constructor() {
        this.connection = new HubConnectionBuilder()
            .withUrl(`${getBaseApiUrl()}/hubs/seating-plan-events`, {
                logMessageContent: true,
                accessTokenFactory: async () => {
                    const authInfo = await getAuthInfo();
                    return authInfo.accessToken || '';
                },
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets
            })
            .withAutomaticReconnect()
            .withHubProtocol(new JsonHubProtocol())
            .build();

        this.connection.onclose((error) => {
            if (error) {
                this.start();
            }
        });
    }

    public async start() {
        if (this.connection.state === HubConnectionState.Disconnected) {
            try {
                await this.connection.start();
                console.log("[SignalR] Connected");
            } catch (err) {
                setTimeout(() => this.start(), 5000);
            }
        }
    }

    public invoke(methodName: string, ...args: unknown[]) {
        if (this.connection.state === HubConnectionState.Connected) {
            this.connection.invoke(methodName, ...args);
        } else {
            setTimeout(() => this.invoke(methodName, ...args), 500);
        }
    }

    public static get Instance() {
        return this.SignalRServiceInstance || (this.SignalRServiceInstance = new this());
    }
}
