import loadable from '@loadable/component';
import i18n from 'i18next';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Route, Switch, RouteComponentProps, matchPath, withRouter } from 'react-router-dom';
import { AuthorizedRoute } from './AuthorizedRoute';
import { RoutePaths } from './RoutePaths';
import { Spinner } from 'components/common/loaders';

const Callback = loadable(() => import('components/Pages/Callback'), {
    resolveComponent: components => components.Callback,
    fallback: <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center" />,
});
const ChecklistLayout = loadable(() => import('components/Pages/Checklist'), {
    resolveComponent: components => components.ChecklistLayout,
    fallback: <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center" />,
});
const CreateEventLayout = loadable(() => import('components/Pages/CreateEvent'), {
    resolveComponent: components => components.CreateEventLayout,
    fallback: <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center" />,
});
const NotFound = loadable(() => import('components/Pages/Errors'), {
    resolveComponent: components => components.NotFound,
    fallback: <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center" />,
});
const EventsLayout = loadable(() => import('components/Pages/Events'), {
    resolveComponent: components => components.EventsLayout,
    fallback: <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center" />,
});
const GuestAffinitiesLayout = loadable(() => import('components/Pages/GuestAffinities'), {
    resolveComponent: components => components.GuestAffinitiesLayout,
    fallback: <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center" />,
});
const GuestsLayout = loadable(() => import('components/Pages/Guests'), {
    resolveComponent: components => components.GuestsLayout,
    fallback: <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center" />,
});
const GuestCsvImport = loadable(() => import('components/Pages/Guests/GuestCsvImport'), {
    resolveComponent: components => components.GuestCsvImport,
    fallback: <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center" />,
});
const InformationLayout = loadable(() => import('components/Pages/Information'), {
    resolveComponent: components => components.InformationLayout,
    fallback: <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center" />,
});
const InvitationLayout = loadable(() => import('components/Pages/Invitation'), {
    resolveComponent: components => components.InvitationLayout,
    fallback: <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center" />,
});
const MenuLayout = loadable(() => import('components/Pages/Menu'), {
    resolveComponent: components => components.MenuLayout,
    fallback: <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center" />,
});
const PurchaseLayout = loadable(() => import('components/Pages/Purchase'), {
    resolveComponent: components => components.PurchaseLayout,
    fallback: <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center" />,
});
const AadRedirect = loadable(() => import('components/Pages/Redirect/AadRedirect'), {
    resolveComponent: components => components.AadRedirect,
    fallback: <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center" />,
});
const SeatingPlanLayout = loadable(() => import('components/Pages/SeatingPlan'), {
    resolveComponent: components => components.SeatingPlanLayout,
    fallback: <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center" />,
});
const Home = loadable(() => import('components/Pages/Home'), {
    resolveComponent: components => components.Home,
    fallback: <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center" />,
});
const Blog = loadable(() => import('components/Pages/Blog'), {
    resolveComponent: components => components.Blog,
    fallback: <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center" />,
});
const BlogArticle = loadable(() => import('components/Pages/Blog'), {
    resolveComponent: components => components.BlogArticle,
    fallback: <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center" />,
});

class LocalizedRoutesComponent extends React.PureComponent<WithTranslation & RouteComponentProps> {
    public render() {
        const { i18n, location } = this.props;
        const match = matchPath<{ lang: string }>(location.pathname, {
            path: '/:lang/*',
        });
        const localesLoading = match && match.params.lang.toLowerCase() !== i18n.language.toLowerCase();
        return localesLoading ? (
            <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center position-relative" style={{ top: 'calc(50vh - 128px)' }} />
        ) :
            (
                <FragmentSupportingSwitch>
                    <Route path="/callback" component={Callback} />
                    <Route path={RoutePaths.Home.route()} component={Home} exact />
                    {this.createLocalizedRoutes(i18n.language)}
                    <Route path={RoutePaths.RedirectUris.AzureActiveDirectory.route('en')} component={AadRedirect} />
                    <Route path={RoutePaths.Errors.NotFound.route()} component={NotFound} />
                </FragmentSupportingSwitch>
            );
    }

    private createLocalizedRoutes(language: string) {
        const demoUrlRoute = RoutePaths.Events.route(language)
            .replace(`/${i18n.t('Routing:Events', language)}/:eventId(\\d+)`, `/demo/:demoId`);
        return (
            <React.Fragment>
                <Route path={RoutePaths.Blog.route(language)} component={Blog} exact />
                <Route path={RoutePaths.Blog.Article.route(language)} component={BlogArticle} exact />
                {this.createRoutes(language, RoutePaths.Events.List.route, EventsLayout, false)}
                {this.createRoutes(language, RoutePaths.Events.Delete.route, EventsLayout, false)}
                {this.createRoutes(language, RoutePaths.Events.Edit.route, EventsLayout, false)}
                <AuthorizedRoute exact={true} path={RoutePaths.Events.Create.route(language)} component={CreateEventLayout} />
                {this.createRoutes(language, RoutePaths.Events.GuestAffinities.route, GuestAffinitiesLayout, false, false)}
                {this.createRoutes(language, RoutePaths.Events.Guests.route, GuestsLayout)}
                {this.createRoutes(language, RoutePaths.Events.Guests.Create.route, GuestsLayout)}
                {this.createRoutes(language, RoutePaths.Events.Guests.Config.route, GuestsLayout)}
                {this.createRoutes(language, RoutePaths.Events.Guests.Import.Google.route, GuestsLayout)}
                {this.createRoutes(language, RoutePaths.Events.Guests.Import.Microsoft.route, GuestsLayout)}
                {this.createRoutes(language, RoutePaths.Events.Guests.Import.Csv.route, GuestCsvImport)}
                {this.createRoutes(language, RoutePaths.Events.Guests.RequestPlacementPreferences.route, GuestsLayout)}
                {this.createRoutes(language, RoutePaths.Events.Guests.SendInvitations.route, GuestsLayout)}
                {this.createRoutes(language, RoutePaths.Events.Menu.route, MenuLayout)}
                {this.createRoutes(language, RoutePaths.Events.Menu.Edit.route, MenuLayout)}
                {this.createRoutes(language, RoutePaths.Events.Menu.Config.route, MenuLayout)}
                {this.createRoutes(language, RoutePaths.Events.Information.route, InformationLayout)}
                {this.createRoutes(language, RoutePaths.Events.Information.AddPlace.route, InformationLayout)}
                {this.createRoutes(language, RoutePaths.Events.Information.Config.route, InformationLayout)}
                {this.createRoutes(language, RoutePaths.Events.Information.Edit.route, InformationLayout)}
                {this.createRoutes(language, RoutePaths.Events.Information.EditPlace.route, InformationLayout)}
                {this.createRoutes(language, RoutePaths.Events.Invitation.Answer.route, InvitationLayout)}
                {this.createRoutes(language, RoutePaths.Events.Checklist.route, ChecklistLayout)}
                {this.createRoutes(language, RoutePaths.Events.Checklist.Add.route, ChecklistLayout)}
                {this.createRoutes(language, RoutePaths.Events.Checklist.AddPredefinedTasks.route, ChecklistLayout)}
                {this.createRoutes(language, RoutePaths.Events.Checklist.Config.route, ChecklistLayout)}
                {this.createRoutes(language, RoutePaths.Events.SeatingPlan.route, SeatingPlanLayout)}
                <Route exact={true} path={demoUrlRoute} component={SeatingPlanLayout} />
                {this.createRoutes(language, RoutePaths.Events.SeatingPlan.Config.route, SeatingPlanLayout)}
                {this.createRoutes(language, RoutePaths.Events.SeatingPlan.Print.route, SeatingPlanLayout)}
                {this.createRoutes(language, RoutePaths.Events.SeatingPlan.Object.route, SeatingPlanLayout)}
                {this.createRoutes(language, RoutePaths.Events.SeatingPlan.Object.Add.route, SeatingPlanLayout)}
                {this.createRoutes(language, RoutePaths.Events.SeatingPlan.Table.route, SeatingPlanLayout)}
                {this.createRoutes(language, RoutePaths.Events.SeatingPlan.Table.Add.route, SeatingPlanLayout)}
                {this.createRoutes(language, RoutePaths.Events.SeatingPlan.Room.Configuration.route, SeatingPlanLayout)}
                {this.createRoutes(language, RoutePaths.Events.SeatingPlan.RoomPlan.Search.route, SeatingPlanLayout)}
                {this.createRoutes(language, RoutePaths.Events.SeatingPlan.Seating.Automatic.route, SeatingPlanLayout)}
                {this.createRoutes(language, RoutePaths.Events.SeatingPlan.Seating.Automatic.Configuration.route, SeatingPlanLayout)}
                {this.createRoutes(language, RoutePaths.Events.SeatingPlan.Seating.Automatic.AffinityConfiguration.route, SeatingPlanLayout)}
                {this.createRoutes(language, RoutePaths.Events.SeatingPlan.Seating.Automatic.PlanPrices.route, SeatingPlanLayout)}
                {this.createRoutes(language, RoutePaths.Events.SeatingPlan.Seating.Manual.route, SeatingPlanLayout)}
                {this.createRoutes(language, RoutePaths.Events.SeatingPlan.Templates.route, SeatingPlanLayout)}
                <AuthorizedRoute exact={true} path={RoutePaths.Purchase.route(language)} component={PurchaseLayout} />
                <AuthorizedRoute exact={true} path={RoutePaths.Purchase.TransferFund.route(language)} component={PurchaseLayout} />
            </React.Fragment>
        );
    }

    private createRoutes(language: string, route: (language: string) => string, component: React.ComponentType<RouteComponentProps> | React.ComponentType, addDemoRoute = true, authorized = true) {
        const customUrlRoute = route(language)
            .replace(`/${i18n.t('Routing:Events', language)}/:eventId(\\d+)`, `/public/:customUrl`);
        const demoUrlRoute = route(language)
            .replace(`/${i18n.t('Routing:Events', language)}/:eventId(\\d+)`, `/demo/:demoId`);
        const RouteComponent = authorized ? AuthorizedRoute : Route;
        return (
            <React.Fragment>
                <RouteComponent exact={true} path={route(language)} component={component} />
                <RouteComponent exact={true} path={customUrlRoute} component={component} />
                {addDemoRoute ? <Route exact={true} path={demoUrlRoute} component={component} /> : null}
            </React.Fragment>
        );
    }
}

function FragmentSupportingSwitch({ children }: { children: React.ReactNode }) {
    const flattenedChildren: React.ReactNode[] = [];
    flatten(flattenedChildren, children);
    return (
        <Switch>
            {flattenedChildren}
        </Switch>
    );
}

function flatten(target: React.ReactNode[], children: React.ReactNode) {
    React.Children.forEach(children, (child) => {
        if (React.isValidElement(child)) {
            if (child.type === React.Fragment) {
                flatten(target, (child as { props: { children?: React.ReactNode } }).props.children);
            } else {
                target.push(child);
            }
        }
    });
}

export const LocalizedRoutes = withRouter(withTranslation()(LocalizedRoutesComponent));