import { AppReducer } from 'store';
import { IRequest } from 'store/SharedModels';

export interface IMenuRequest extends IRequest {
    readonly menu?: number;
}

const unloadedState = () => {
    return {
        menuDetailsRequests: {} as { readonly [eventId: number]: IMenuRequest },
        menuUpdateRequests: {} as { readonly [eventId: string]: boolean },
    } as const;
};

export type MenusState = ReturnType<typeof unloadedState>;

export const MenusReducer: AppReducer<MenusState> = (state = unloadedState(), action) => {
    switch (action.type) {
        case '@MENUS/GET_MENU_REQUEST':
            return {
                ...state,
                menuDetailsRequests: {
                    ...state.menuDetailsRequests,
                    [action.eventId]: {
                        ...state.menuDetailsRequests[action.eventId],
                        didInvalidate: state.menuDetailsRequests[action.eventId]?.didInvalidate || false,
                        isFetching: true,
                    },
                },
            };

        case '@MENUS/GET_MENU_SUCCESS':
            return {
                ...state,
                menuDetailsRequests: {
                    ...state.menuDetailsRequests,
                    [action.eventId]: {
                        ...state.menuDetailsRequests[action.eventId],
                        isFetching: false,
                        didInvalidate: false,
                        menu: action.menuId,
                    },
                },
            };

        case '@MENUS/GET_MENU_FAILURE':
            return {
                ...state,
                menuDetailsRequests: {
                    ...state.menuDetailsRequests,
                    [action.eventId]: {
                        ...state.menuDetailsRequests[action.eventId],
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        case '@MENUS/UPDATE_MENU_REQUEST':
            return {
                ...state,
                menuUpdateRequests: {
                    ...state.menuUpdateRequests,
                    [action.eventId]: true,
                },
            };

        case '@MENUS/UPDATE_MENU_SUCCESS':
            return {
                ...state,
                menuDetailsRequests: {
                    ...state.menuDetailsRequests,
                    [action.eventId]: {
                        ...state.menuDetailsRequests[action.eventId],
                        didInvalidate: true,
                        menu: action.menuId,
                    },
                },
                menuUpdateRequests: {
                    ...state.menuUpdateRequests,
                    [action.eventId]: false,
                },
            };

        case '@MENUS/UPDATE_MENU_FAILURE':
            return {
                ...state,
                menuUpdateRequests: {
                    ...state.menuUpdateRequests,
                    [action.eventId]: false,
                },
            };

        case '@SIGNALR/MENU_UPDATED':
            return {
                ...state,
                menuDetailsRequests: {
                    ...state.menuDetailsRequests,
                    [action.payload.eventId]: {
                        ...state.menuDetailsRequests[action.payload.eventId],
                        didInvalidate: true,
                    },
                },
            };

        default:
            return state;
    }
};
