import { connect } from 'react-redux';
import { ApplicationState, AppThunkDispatch } from 'store';
import { AuthorizedRoute } from './AuthorizedRoute';
import { IDispatchProps, IProps } from './AuthorizedRouteModels';
import { signIn } from 'services/AuthService';
import { getAccessKey } from 'config/constants';
import { Selectors } from 'store/normalizr/selectors';
import { getAppContext } from 'store/appContext/thunk';

const mapStateToProps = (state: ApplicationState): IProps => {
    const appContext = Selectors.getAppContextModel(state.appContext.appContextId, state);
    const accessKey = getAccessKey();
    return {
        isAuthenticated: !!state.oidc.user || !!accessKey || !!appContext?.loggedUser,
    };
};

const mapDispatchToProps = (dispatch: AppThunkDispatch): IDispatchProps => {
    return {
        onInit: (isAuthenticated) => {
            dispatch(getAppContext()).catch(void 0);
            if (!isAuthenticated) {
                signIn();
            }
        },
    };
};

export const AuthorizedRouteContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(AuthorizedRoute);
