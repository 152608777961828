export const createGetMenuRequestAction = (eventId: number) => {
    return {
        type: '@MENUS/GET_MENU_REQUEST',
        eventId,
    } as const;
};

export const createGetMenuRequestSuccessAction = (eventId: number, menuId: number) => {
    return {
        type: '@MENUS/GET_MENU_SUCCESS',
        eventId,
        menuId,
    } as const;
};

export const createGetMenuRequestFailureAction = (eventId: number) => {
    return {
        type: '@MENUS/GET_MENU_FAILURE',
        eventId,
    } as const;
};

export const createUpdateMenuRequestAction = (eventId: number) => {
    return {
        type: '@MENUS/UPDATE_MENU_REQUEST',
        eventId,
    } as const;
};

export const createUpdateMenuRequestSuccessAction = (eventId: number, menuId: number) => {
    return {
        type: '@MENUS/UPDATE_MENU_SUCCESS',
        eventId,
        menuId,
    } as const;
};

export const createUpdateMenuRequestFailureAction = (eventId: number) => {
    return {
        type: '@MENUS/UPDATE_MENU_FAILURE',
        eventId,
    } as const;
};

export type MenusAction =
    | ReturnType<typeof createGetMenuRequestAction>
    | ReturnType<typeof createGetMenuRequestSuccessAction>
    | ReturnType<typeof createGetMenuRequestFailureAction>
    | ReturnType<typeof createUpdateMenuRequestAction>
    | ReturnType<typeof createUpdateMenuRequestSuccessAction>
    | ReturnType<typeof createUpdateMenuRequestFailureAction>;
