export const createGetSeatingPlanConfigurationAction = (eventId: number) => {
    return {
        type: '@SEATING_PLAN/GET_SEATING_PLAN_CONFIGURATION_REQUEST',
        eventId,
    } as const;
};

export const createGetSeatingPlanConfigurationSuccessAction = (eventId: number) => {
    return {
        type: '@SEATING_PLAN/GET_SEATING_PLAN_CONFIGURATION_SUCCESS',
        eventId,
    } as const;
};

export const createGetSeatingPlanConfigurationFailureAction = (eventId: number) => {
    return {
        type: '@SEATING_PLAN/GET_SEATING_PLAN_CONFIGURATION_FAILURE',
        eventId,
    } as const;
};

export const createGetTablesAction = (eventId: number) => {
    return {
        type: '@SEATING_PLAN/GET_TABLES_REQUEST',
        eventId,
    } as const;
};

export const createGetTablesSuccessAction = (eventId: number, tables: number[]) => {
    return {
        type: '@SEATING_PLAN/GET_TABLES_SUCCESS',
        eventId,
        tables,
    } as const;
};

export const createGetTablesFailureAction = (eventId: number) => {
    return {
        type: '@SEATING_PLAN/GET_TABLES_FAILURE',
        eventId,
    } as const;
};

export const createGetObjectsAction = (eventId: number) => {
    return {
        type: '@SEATING_PLAN/GET_OBJECTS_REQUEST',
        eventId,
    } as const;
};

export const createGetObjectsSuccessAction = (eventId: number, objects: number[]) => {
    return {
        type: '@SEATING_PLAN/GET_OBJECTS_SUCCESS',
        eventId,
        objects,
    } as const;
};

export const createGetObjectsFailureAction = (eventId: number) => {
    return {
        type: '@SEATING_PLAN/GET_OBJECTS_FAILURE',
        eventId,
    } as const;
};

export const createUpdateSeatingPlanConfigurationAction = (eventId: number) => {
    return {
        type: '@SEATING_PLAN/UPDATE_SEATING_PLAN_CONFIGURATION_REQUEST',
        eventId,
    } as const;
};

export const createUpdateSeatingPlanConfigurationSuccessAction = (eventId: number) => {
    return {
        type: '@SEATING_PLAN/UPDATE_SEATING_PLAN_CONFIGURATION_SUCCESS',
        eventId,
    } as const;
};

export const createUpdateSeatingPlanConfigurationFailureAction = (eventId: number) => {
    return {
        type: '@SEATING_PLAN/UPDATE_SEATING_PLAN_CONFIGURATION_FAILURE',
        eventId,
    } as const;
};

export const createDisplaySearchAction = (eventId: number, show: boolean) => {
    return {
        type: '@SEATING_PLAN/DISPLAY_SEARCH',
        eventId,
        show,
    } as const;
};

export const createSearchAction = (eventId: number, terms: string) => {
    return {
        type: '@SEATING_PLAN/SEARCH',
        eventId,
        terms,
    } as const;
};

export const createUpdateTableAction = (eventId: number, tableId: number) => {
    return {
        type: '@SEATING_PLAN/UPDATE_TABLE_REQUEST',
        eventId,
        tableId,
    } as const;
};

export const createUpdateTableSuccessAction = (eventId: number, tableId: number) => {
    return {
        type: '@SEATING_PLAN/UPDATE_TABLE_SUCCESS',
        eventId,
        tableId,
    } as const;
};

export const createUpdateTableModifySuccessAction = (eventId: number, tableId: number) => {
    return {
        type: '@SEATING_PLAN/UPDATE_TABLE_MODIFY_SUCCESS',
        eventId,
        tableId,
    } as const;
};

export const createUpdateTableFailureAction = (eventId: number, tableId: number) => {
    return {
        type: '@SEATING_PLAN/UPDATE_TABLE_FAILURE',
        eventId,
        tableId,
    } as const;
};

export const createCreateTableAction = (eventId: number) => {
    return {
        type: '@SEATING_PLAN/CREATE_TABLE_REQUEST',
        eventId,
    } as const;
};

export const createCreateTableSuccessAction = (eventId: number, tableId: number) => {
    return {
        type: '@SEATING_PLAN/CREATE_TABLE_SUCCESS',
        eventId,
        tableId,
    } as const;
};

export const createCreateTableModifySuccessAction = (eventId: number, tableId: number) => {
    return {
        type: '@SEATING_PLAN/CREATE_TABLE_MODIFY_SUCCESS',
        eventId,
        tableId,
    } as const;
};

export const createCreateTableFailureAction = (eventId: number) => {
    return {
        type: '@SEATING_PLAN/CREATE_TABLE_FAILURE',
        eventId,
    } as const;
};

export const createDeleteTableAction = (eventId: number, tableId: number) => {
    return {
        type: '@SEATING_PLAN/DELETE_TABLE_REQUEST',
        eventId,
        tableId,
    } as const;
};

export const createDeleteTableSuccessAction = (eventId: number, tableId: number) => {
    return {
        type: '@SEATING_PLAN/DELETE_TABLE_SUCCESS',
        eventId,
        tableId,
    } as const;
};

export const createDeleteTableModifySuccessAction = (eventId: number, tableId: number) => {
    return {
        type: '@SEATING_PLAN/DELETE_TABLE_MODIFY_SUCCESS',
        eventId,
        tableId,
    } as const;
};

export const createDeleteTableFailureAction = (eventId: number, tableId: number) => {
    return {
        type: '@SEATING_PLAN/DELETE_TABLE_FAILURE',
        eventId,
        tableId,
    } as const;
};

export const createUpdateObjectAction = (objectId: number) => {
    return {
        type: '@SEATING_PLAN/UPDATE_OBJECT_REQUEST',
        objectId,
    } as const;
};

export const createUpdateObjectSuccessAction = (objectId: number) => {
    return {
        type: '@SEATING_PLAN/UPDATE_OBJECT_SUCCESS',
        objectId,
    } as const;
};

export const createUpdateObjectModifySuccessAction = (objectId: number) => {
    return {
        type: '@SEATING_PLAN/UPDATE_OBJECT_MODIFY_SUCCESS',
        objectId,
    } as const;
};

export const createUpdateObjectFailureAction = (objectId: number) => {
    return {
        type: '@SEATING_PLAN/UPDATE_OBJECT_FAILURE',
        objectId,
    } as const;
};

export const createCreateObjectAction = (eventId: number) => {
    return {
        type: '@SEATING_PLAN/CREATE_OBJECT_REQUEST',
        eventId,
    } as const;
};

export const createCreateObjectSuccessAction = (eventId: number, objectId: number) => {
    return {
        type: '@SEATING_PLAN/CREATE_OBJECT_SUCCESS',
        eventId,
        objectId,
    } as const;
};

export const createCreateObjectModifySuccessAction = (eventId: number, objectId: number) => {
    return {
        type: '@SEATING_PLAN/CREATE_OBJECT_MODIFY_SUCCESS',
        eventId,
        objectId,
    } as const;
};

export const createCreateObjectFailureAction = (eventId: number) => {
    return {
        type: '@SEATING_PLAN/CREATE_OBJECT_FAILURE',
        eventId,
    } as const;
};

export const createDeleteObjectAction = (eventId: number, objectId: number) => {
    return {
        type: '@SEATING_PLAN/DELETE_OBJECT_REQUEST',
        eventId,
        objectId,
    } as const;
};

export const createDeleteObjectSuccessAction = (eventId: number, objectId: number) => {
    return {
        type: '@SEATING_PLAN/DELETE_OBJECT_SUCCESS',
        eventId,
        objectId,
    } as const;
};

export const createDeleteObjectModifySuccessAction = (eventId: number, objectId: number) => {
    return {
        type: '@SEATING_PLAN/DELETE_OBJECT_MODIFY_SUCCESS',
        eventId,
        objectId,
    } as const;
};

export const createDeleteObjectFailureAction = (eventId: number, objectId: number) => {
    return {
        type: '@SEATING_PLAN/DELETE_OBJECT_FAILURE',
        eventId,
        objectId,
    } as const;
};

export const createHighlightGuestAction = (guestId: number, highlighted: boolean) => {
    return {
        type: '@SEATING_PLAN/HIGHLIGHT_GUEST',
        guestId,
        highlighted,
    } as const;
};

export type SeatingPlansAction =
    | ReturnType<typeof createGetTablesAction>
    | ReturnType<typeof createGetTablesSuccessAction>
    | ReturnType<typeof createGetTablesFailureAction>
    | ReturnType<typeof createGetObjectsAction>
    | ReturnType<typeof createGetObjectsSuccessAction>
    | ReturnType<typeof createGetObjectsFailureAction>
    | ReturnType<typeof createGetSeatingPlanConfigurationAction>
    | ReturnType<typeof createGetSeatingPlanConfigurationSuccessAction>
    | ReturnType<typeof createGetSeatingPlanConfigurationFailureAction>
    | ReturnType<typeof createUpdateSeatingPlanConfigurationAction>
    | ReturnType<typeof createUpdateSeatingPlanConfigurationSuccessAction>
    | ReturnType<typeof createUpdateSeatingPlanConfigurationFailureAction>
    | ReturnType<typeof createDisplaySearchAction>
    | ReturnType<typeof createSearchAction>
    | ReturnType<typeof createUpdateTableAction>
    | ReturnType<typeof createUpdateTableSuccessAction>
    | ReturnType<typeof createUpdateTableModifySuccessAction>
    | ReturnType<typeof createUpdateTableFailureAction>
    | ReturnType<typeof createCreateTableAction>
    | ReturnType<typeof createCreateTableSuccessAction>
    | ReturnType<typeof createCreateTableModifySuccessAction>
    | ReturnType<typeof createCreateTableFailureAction>
    | ReturnType<typeof createDeleteTableAction>
    | ReturnType<typeof createDeleteTableSuccessAction>
    | ReturnType<typeof createDeleteTableModifySuccessAction>
    | ReturnType<typeof createDeleteTableFailureAction>
    | ReturnType<typeof createUpdateObjectAction>
    | ReturnType<typeof createUpdateObjectSuccessAction>
    | ReturnType<typeof createUpdateObjectModifySuccessAction>
    | ReturnType<typeof createUpdateObjectFailureAction>
    | ReturnType<typeof createCreateObjectAction>
    | ReturnType<typeof createCreateObjectSuccessAction>
    | ReturnType<typeof createCreateObjectModifySuccessAction>
    | ReturnType<typeof createCreateObjectFailureAction>
    | ReturnType<typeof createDeleteObjectAction>
    | ReturnType<typeof createDeleteObjectSuccessAction>
    | ReturnType<typeof createDeleteObjectModifySuccessAction>
    | ReturnType<typeof createDeleteObjectFailureAction>
    | ReturnType<typeof createHighlightGuestAction>;
