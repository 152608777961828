
import { AppReducer } from 'store';
import { IRequest } from 'store/SharedModels';

export interface IPaymentLineConfigurationRequest extends IRequest {
    readonly configurationId?: string;
}

export interface IConfirmPaymentRequest {
    readonly isTokenizing: boolean;
    readonly isPaying: boolean;
    readonly isSaving: boolean;
}

const unloadedState = () => {
    return {
        paymentLineConfiguration: {
            isFetching: false,
            didInvalidate: true,
            configurationId: '',
        } as IPaymentLineConfigurationRequest,
        itemRequests: {} as { readonly [itemType: string]: { [itemId: number]: IRequest } },
        initPaymentRequest: {
            isFetching: false as boolean,
            paymentId: undefined as string | undefined,
        },
        confirmPaymentRequests: {} as { readonly [paymentId: string]: IConfirmPaymentRequest },
    } as const;
};

export type PurchasesState = ReturnType<typeof unloadedState>;

export const PurchasesReducer: AppReducer<PurchasesState> = (state = unloadedState(), action) => {
    switch (action.type) {
        case '@PURCHASES/GET_PAYMENT_LINE_CONFIGURATION_REQUEST':
            return {
                ...state,
                paymentLineConfiguration: {
                    ...state.paymentLineConfiguration,
                    isFetching: true,
                },
            };

        case '@PURCHASES/GET_PAYMENT_LINE_CONFIGURATION_SUCCESS':
            return {
                ...state,
                paymentLineConfiguration: {
                    ...state.paymentLineConfiguration,
                    isFetching: false,
                    didInvalidate: false,
                    configurationId: action.configurationId,
                },
            };

        case '@PURCHASES/GET_PAYMENT_LINE_CONFIGURATION_FAILURE':
            return {
                ...state,
                paymentLineConfiguration: {
                    ...state.paymentLineConfiguration,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        case '@PURCHASES/GET_ITEM_REQUEST':
            return {
                ...state,
                itemRequests: {
                    ...state.itemRequests,
                    [action.itemType]: {
                        ...state.itemRequests[action.itemType],
                        [action.itemId]: {
                            isFetching: true,
                            didInvalidate: true,
                        },
                    },
                },
            };

        case '@PURCHASES/GET_ITEM_SUCCESS':
            return {
                ...state,
                itemRequests: {
                    ...state.itemRequests,
                    [action.itemType]: {
                        ...state.itemRequests[action.itemType],
                        [action.itemId]: {
                            isFetching: false,
                            didInvalidate: false,
                        },
                    },
                },
            };

        case '@PURCHASES/GET_ITEM_FAILURE':
            return {
                ...state,
                itemRequests: {
                    ...state.itemRequests,
                    [action.itemType]: {
                        ...state.itemRequests[action.itemType],
                        [action.itemId]: {
                            isFetching: false,
                            didInvalidate: true,
                        },
                    },
                },
            };

        case '@PURCHASES/INIT_PAYMENT_REQUEST':
            return {
                ...state,
                initPaymentRequest: {
                    ...state.initPaymentRequest,
                    isFetching: true
                },
            };

        case '@PURCHASES/INIT_PAYMENT_SUCCESS':
            return {
                ...state,
                initPaymentRequest: {
                    isFetching: false,
                    paymentId: action.paymentId,
                },
            };
        case '@PURCHASES/INIT_PAYMENT_FAILURE':
            return {
                ...state,
                initPaymentRequest: {
                    ...state.initPaymentRequest,
                    isFetching: false,
                },
            };

        case '@PURCHASES/CONFIRM_PAYMENT_INIT':
            return {
                ...state,
                confirmPaymentRequests: {
                    ...state.confirmPaymentRequests,
                    [action.paymentId]: {
                        isTokenizing: false,
                        isPaying: false,
                        isSaving: false,
                    },
                },
            };

        case '@PURCHASES/CONFIRM_PAYMENT_TOKEN_REQUEST':
            return {
                ...state,
                confirmPaymentRequests: {
                    ...state.confirmPaymentRequests,
                    [action.paymentId]: {
                        isTokenizing: true,
                        isPaying: false,
                        isSaving: false,
                    },
                },
            };

        case '@PURCHASES/CONFIRM_PAYMENT_TOKEN_SUCCESS':
        case '@PURCHASES/CONFIRM_PAYMENT_TOKEN_FAILURE':
            return {
                ...state,
                confirmPaymentRequests: {
                    ...state.confirmPaymentRequests,
                    [action.paymentId]: {
                        isTokenizing: false,
                        isPaying: false,
                        isSaving: false,
                    },
                },
            };

        case '@PURCHASES/CONFIRM_PAYMENT_PAYING_REQUEST':
            return {
                ...state,
                confirmPaymentRequests: {
                    ...state.confirmPaymentRequests,
                    [action.paymentId]: {
                        isTokenizing: false,
                        isPaying: true,
                        isSaving: false,
                    },
                },
            };

        case '@PURCHASES/CONFIRM_PAYMENT_PAYING_SUCCESS':
        case '@PURCHASES/CONFIRM_PAYMENT_PAYING_FAILURE':
            return {
                ...state,
                confirmPaymentRequests: {
                    ...state.confirmPaymentRequests,
                    [action.paymentId]: {
                        isTokenizing: false,
                        isPaying: false,
                        isSaving: false,
                    },
                },
            };

        case '@PURCHASES/CONFIRM_PAYMENT_SAVING_REQUEST':
            return {
                ...state,
                confirmPaymentRequests: {
                    ...state.confirmPaymentRequests,
                    [action.paymentId]: {
                        isTokenizing: false,
                        isPaying: false,
                        isSaving: true,
                    },
                },
            };

        case '@PURCHASES/CONFIRM_PAYMENT_SAVING_SUCCESS':
        case '@PURCHASES/CONFIRM_PAYMENT_SAVING_FAILURE':
            return {
                ...state,
                confirmPaymentRequests: {
                    ...state.confirmPaymentRequests,
                    [action.paymentId]: {
                        isTokenizing: false,
                        isPaying: false,
                        isSaving: false,
                    },
                },
            };

        default:
            return state;
    }
};
