export const createShowRobotAction = (robotId: string) => {
    return {
        type: '@ROBOTS/SHOW',
        robotId,
    } as const;
};

export const createHideRobotAction = (robotId: string) => {
    return {
        type: '@ROBOTS/HIDE',
        robotId,
    } as const;
};

export const createStartRobotAction = (robotId: string) => {
    return {
        type: '@ROBOTS/START',
        robotId,
    } as const;
};

export const createStopRobotAction = (robotId: string) => {
    return {
        type: '@ROBOTS/STOP',
        robotId,
    } as const;
};

export type RobotsAction =
    | ReturnType<typeof createShowRobotAction>
    | ReturnType<typeof createHideRobotAction>
    | ReturnType<typeof createStartRobotAction>
    | ReturnType<typeof createStopRobotAction>;
