import fetch from 'isomorphic-fetch';

export interface ITaskInfo {
    task: Promise<unknown>;
    name?: string;
    callStack?: string;
    status: TaskStatus;
    reason?: string;
}

export class SSRService {
    public static logTasks = false;
    public static renderingCurrentStep = '';
    public static readonly tasksInfo: ITaskInfo[] = [];
    public static readonly errors: Error[] = [];

    public static fetch(requestInfo: RequestInfo, init?: RequestInit | undefined) {
        return fetch(requestInfo, init);
    }
    
    public static addTask(task: Promise<unknown>, name: string) {
        if (SSRService.logTasks) {
            const taskInfo: ITaskInfo = {
                task,
                name,
                callStack: new Error().stack,
                status: TaskStatus.Pending,
            };

            SSRService.tasksInfo.push(taskInfo);

            task.then(() => {
                taskInfo.status = TaskStatus.Resolved;
            }).catch((reason) => {
                taskInfo.status = TaskStatus.Rejected;
                taskInfo.reason = reason;
            });
        }
    }

    public static throwError(error: Error){
        SSRService.errors.push(error);
    }
}

export enum TaskStatus {
    Pending = 'Pending',
    Resolved = 'Resolved',
    Rejected = 'Rejected',
}
