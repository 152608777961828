import { connect } from 'react-redux';
import { AppThunkDispatch, ApplicationState } from 'store';
import { getAppContext, answerCookieConsent } from 'store/appContext/thunk';
import { Selectors } from 'store/normalizr/selectors';
import { CookieConsent } from './CookieConsent';
import { IDispatchProps, IProps } from './CookieConsentModels';

const mapStateToProps = (state: ApplicationState): IProps => {
    const appContext = Selectors.getAppContextModel(state.appContext.appContextId, state);
    return {
        showCookieConsent: !state.appContext.didInvalidate && (appContext?.showCookieConsent || false),
    };
};

const mapDispatchToProps = (dispatch: AppThunkDispatch): IDispatchProps => {
    return {
        onInit: () => {
            dispatch(getAppContext()).catch(void 0);
        },
        onGrantConsent: () => {
            dispatch(answerCookieConsent(true))
                .then(() => {
                    dispatch(getAppContext()).catch(void 0);
                });
        },
        onWithdrawConsent: () => {
            dispatch(answerCookieConsent(false))
                .then(() => {
                    dispatch(getAppContext()).catch(void 0);
                });
        },
    };
};

export const CookieConsentContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(CookieConsent);
