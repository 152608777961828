import { windowIfDefined } from 'services/WindowService';
import { Configuration as ConfigurationLocal } from './constants.local';
import { Configuration as ConfigurationPreProd } from './constants.pre-prod';
import { Configuration } from './constants.prod';

export const apiVersion = '2.2.0.10';
export const publicPath = '/';

// Base API url for web app
let baseApiUrl = '';
export const setBaseApiUrl = (url: string) => {
    baseApiUrl = url;
    oauthMobileAppConfig.issuer = url;
};
export const getBaseApiUrl = () => baseApiUrl;

// Base API url for mobile app
// export const baseApiUrl = 'https://www.meshotes.com';

const location = windowIfDefined && windowIfDefined.location;
const protocol = (location && location.protocol) || 'https:';
const hostname = (location && location.hostname) || 'localhost';
const port = location && location.port;
let baseUrl = `${protocol}//${hostname}${port ? `:${port}` : ''}`;
export const setBaseUrl = (url: string) => {
    baseUrl = url;
};
export const getBaseUrl = () => baseUrl;

let accessKey: string | undefined = undefined;
export const setAccessKey = (value: string) => {
    accessKey = value;
};
export const getAccessKey = () => {
    if (windowIfDefined) {
        const accessKeyQueryParam = new URLSearchParams(windowIfDefined.location.search).get('accessKey');
        return accessKey || accessKeyQueryParam;
    }
    return accessKey;
};

export const oauthMobileAppConfig = {
    issuer: baseApiUrl,
    clientId: 'meshotes.mobile',
    redirectUrl: 'com.meshotes.www:/oauthredirect',
    scopes: ['openid', 'profile', 'offline_access', 'api.meshotes.com'],
    dangerouslyAllowInsecureHttpRequests: __DEV__,
};

export const signalRConfig = {
    BASE_URL: `${baseApiUrl}/hubs/users`,
    CONNECTION_DELAY: 0,
    HUB_MESSAGE_DELAY: 3000,
    HUB_MESSAGE_TITLE: 'Hub Message',
    LOGIN_USER_EVENT: 'UserLogin',
    LOGOUT_USER_EVENT: 'UserLogout',
    CLOSE_EVENT: 'CloseAllConnections',
    TOASTIFY_ICON: 'info',
};

export const DraggableItemTypes = {
    Guest: 'guest',
};

export const RobotList = {
    SPNono: 'SPNono',
};

export const getConfiguration = () => {
    const env = getEnvironment(baseUrl);

    switch (env) {
        case 'local':
            return ConfigurationLocal;

        case 'pre-prod':
            return ConfigurationPreProd;

        default:
            return Configuration;
    }
};

const getEnvironment = (url: string) => {
    const domain = getDomain(url, true);

    if (domain.startsWith('localhost')) {
        return 'local';
    }

    if (domain.startsWith('test')) {
        return 'pre-prod';
    }

    return 'prod';
};

const getDomain = (url: string, subdomain: boolean) => {
    subdomain = subdomain || false;

    url = url.replace(/(https?:\/\/)?(www.)?/i, '');

    if (!subdomain) {
        url = url.split('.')
            .slice(url.length - 2)
            .join('.');
    }

    if (url.indexOf('/') !== -1) {
        return url.split('/')[0];
    }

    return url;
};
