import { PresenceType } from 'services/api/ApiClient';

export const createSetSearchTermsAction = (eventId: number, searchTerms: string) => {
    return {
        type: '@GUESTS/SET_SEARCH_TERMS',
        eventId,
        searchTerms,
    } as const;
};

export const createSetFilterPresenceAction = (eventId: number, filterPresence?: PresenceType) => {
    return {
        type: '@GUESTS/SET_FILTER_PRESENCE',
        eventId,
        filterPresence,
    } as const;
};

export const createChangePageAction = (eventId: number, skip: number, take: number) => {
    return {
        type: '@GUESTS/CHANGE_PAGE',
        eventId,
        skip,
        take,
    } as const;
};

export const createSearchGuestsRequestAction = (eventId: number) => {
    return {
        type: '@GUESTS/SEARCH_GUESTS_REQUEST',
        eventId,
    } as const;
};

export const createSearchGuestsRequestSuccessAction = (eventId: number, totalResults: number, guests: number[]) => {
    return {
        type: '@GUESTS/SEARCH_GUESTS_SUCCESS',
        eventId,
        totalResults,
        guests,
    } as const;
};

export const createSearchGuestsRequestFailureAction = (eventId: number) => {
    return {
        type: '@GUESTS/SEARCH_GUESTS_FAILURE',
        eventId,
    } as const;
};

export const createGetGuestRequestAction = (guestId: number) => {
    return {
        type: '@GUESTS/GET_GUEST_REQUEST',
        guestId,
    } as const;
};

export const createGetGuestRequestSuccessAction = (guestId: number) => {
    return {
        type: '@GUESTS/GET_GUEST_SUCCESS',
        guestId,
    } as const;
};

export const createGetGuestRequestFailureAction = (guestId: number) => {
    return {
        type: '@GUESTS/GET_GUEST_FAILURE',
        guestId,
    } as const;
};

export const createUpdateGuestRequestAction = (guestId: string | number) => {
    return {
        type: '@GUESTS/UPDATE_GUEST_REQUEST',
        guestId: guestId.toString(),
    } as const;
};

export const createUpdateGuestRequestSuccessAction = (eventId: number, guestId: string | number) => {
    return {
        type: '@GUESTS/UPDATE_GUEST_SUCCESS',
        eventId,
        guestId: guestId.toString(),
    } as const;
};

export const createUpdateGuestRequestFailureAction = (guestId: string | number) => {
    return {
        type: '@GUESTS/UPDATE_GUEST_FAILURE',
        guestId: guestId.toString(),
    } as const;
};

export const createDeleteGuestRequestAction = (guestId: number) => {
    return {
        type: '@GUESTS/DELETE_GUEST_REQUEST',
        guestId,
    } as const;
};

export const createDeleteGuestRequestSuccessAction = (eventId: number, guestId: number) => {
    return {
        type: '@GUESTS/DELETE_GUEST_SUCCESS',
        eventId,
        guestId,
    } as const;
};

export const createDeleteGuestRequestFailureAction = (guestId: number) => {
    return {
        type: '@GUESTS/DELETE_GUEST_FAILURE',
        guestId,
    } as const;
};

export const createGetLightGuestsRequestAction = (eventId: number) => {
    return {
        type: '@LIGHT_GUESTS/GET_LIGHT_GUESTS_REQUEST',
        eventId,
    } as const;
};

export const createGetLightGuestsRequestSuccessAction = (eventId: number, lightGuests: number[]) => {
    return {
        type: '@LIGHT_GUESTS/GET_LIGHT_GUESTS_SUCCESS',
        eventId,
        lightGuests,
    } as const;
};

export const createGetLightGuestsForceEmptyAction = (eventId: number) => {
    return {
        type: '@LIGHT_GUESTS/GET_LIGHT_GUESTS_FORCE_EMPTY',
        eventId,
        lightGuests: [] as number[],
    } as const;
};

export const createGetLightGuestsRequestFailureAction = (eventId: number) => {
    return {
        type: '@LIGHT_GUESTS/GET_LIGHT_GUESTS_FAILURE',
        eventId,
    } as const;
};

export const createLightGuestsSetSearchTermsAction = (eventId: number, searchTerms: string) => {
    return {
        type: '@LIGHT_GUESTS/SET_SEARCH_TERMS',
        eventId,
        searchTerms,
    } as const;
};

export const createLightGuestsSetFilterPresenceAction = (eventId: number, presence: PresenceType[]) => {
    return {
        type: '@LIGHT_GUESTS/SET_FILTER_PRESENCE',
        eventId,
        presence,
    } as const;
};

export const createLightGuestsSetFilterNotSeatedOnlyAction = (eventId: number, notSeatedOnly: boolean) => {
    return {
        type: '@LIGHT_GUESTS/SET_FILTER_NOT_SEATED_ONLY',
        eventId,
        notSeatedOnly,
    } as const;
};

export const createLightGuestsChangePageAction = (eventId: number, skip: number, take: number) => {
    return {
        type: '@LIGHT_GUESTS/CHANGE_PAGE',
        eventId,
        skip,
        take,
    } as const;
};

export const createSendInvitationToMyselfAction = (eventId: number) => {
    return {
        type: '@GUESTS/SEND_INVITATION_TO_MYSELF',
        eventId,
    } as const;
};

export const createSendInvitationToMyselfSuccessAction = (eventId: number) => {
    return {
        type: '@GUESTS/SEND_INVITATION_TO_MYSELF_SUCCESS',
        eventId,
    } as const;
};

export const createSendInvitationToMyselfFailureAction = (eventId: number) => {
    return {
        type: '@GUESTS/SEND_INVITATION_TO_MYSELF_FAILURE',
        eventId,
    } as const;
};

export const createSendInvitationsAction = (eventId: number) => {
    return {
        type: '@GUESTS/SEND_INVITATIONS',
        eventId,
    } as const;
};

export const createSendInvitationsSuccessAction = (eventId: number) => {
    return {
        type: '@GUESTS/SEND_INVITATIONS_SUCCESS',
        eventId,
    } as const;
};

export const createSendInvitationsFailureAction = (eventId: number) => {
    return {
        type: '@GUESTS/SEND_INVITATIONS_FAILURE',
        eventId,
    } as const;
};

export const createSendRequestPlacementPreferencesToMyselfAction = (eventId: number) => {
    return {
        type: '@GUESTS/SEND_REQUEST_PLACEMENT_PREFERENCES_TO_MYSELF',
        eventId,
    } as const;
};

export const createSendRequestPlacementPreferencesToMyselfSuccessAction = (eventId: number) => {
    return {
        type: '@GUESTS/SEND_REQUEST_PLACEMENT_PREFERENCES_TO_MYSELF_SUCCESS',
        eventId,
    } as const;
};

export const createSendRequestPlacementPreferencesToMyselfFailureAction = (eventId: number) => {
    return {
        type: '@GUESTS/SEND_REQUEST_PLACEMENT_PREFERENCES_TO_MYSELF_FAILURE',
        eventId,
    } as const;
};

export const createSendRequestPlacementPreferencesAction = (eventId: number) => {
    return {
        type: '@GUESTS/SEND_REQUEST_PLACEMENT_PREFERENCES',
        eventId,
    } as const;
};

export const createSendRequestPlacementPreferencesSuccessAction = (eventId: number) => {
    return {
        type: '@GUESTS/SEND_REQUEST_PLACEMENT_PREFERENCES_SUCCESS',
        eventId,
    } as const;
};

export const createSendRequestPlacementPreferencesFailureAction = (eventId: number) => {
    return {
        type: '@GUESTS/SEND_REQUEST_PLACEMENT_PREFERENCES_FAILURE',
        eventId,
    } as const;
};

export const createAnswerInvitationRequestAction = (eventId: number) => {
    return {
        type: '@GUESTS/ANSWER_INVITATION_REQUEST',
        eventId,
    } as const;
};

export const createAnswerInvitationSuccessAction = (eventId: number) => {
    return {
        type: '@GUESTS/ANSWER_INVITATION_SUCCESS',
        eventId,
    } as const;
};

export const createAnswerInvitationFailureAction = (eventId: number) => {
    return {
        type: '@GUESTS/ANSWER_INVITATION_FAILURE',
        eventId,
    } as const;
};

export type GuestsAction =
    | ReturnType<typeof createSetSearchTermsAction>
    | ReturnType<typeof createSetFilterPresenceAction>
    | ReturnType<typeof createChangePageAction>
    | ReturnType<typeof createSearchGuestsRequestAction>
    | ReturnType<typeof createSearchGuestsRequestSuccessAction>
    | ReturnType<typeof createSearchGuestsRequestFailureAction>
    | ReturnType<typeof createGetGuestRequestAction>
    | ReturnType<typeof createGetGuestRequestSuccessAction>
    | ReturnType<typeof createGetGuestRequestFailureAction>
    | ReturnType<typeof createUpdateGuestRequestAction>
    | ReturnType<typeof createUpdateGuestRequestSuccessAction>
    | ReturnType<typeof createUpdateGuestRequestFailureAction>
    | ReturnType<typeof createDeleteGuestRequestAction>
    | ReturnType<typeof createDeleteGuestRequestSuccessAction>
    | ReturnType<typeof createDeleteGuestRequestFailureAction>
    | ReturnType<typeof createGetLightGuestsRequestAction>
    | ReturnType<typeof createGetLightGuestsRequestSuccessAction>
    | ReturnType<typeof createGetLightGuestsRequestFailureAction>
    | ReturnType<typeof createGetLightGuestsForceEmptyAction>
    | ReturnType<typeof createLightGuestsSetSearchTermsAction>
    | ReturnType<typeof createLightGuestsSetFilterPresenceAction>
    | ReturnType<typeof createLightGuestsSetFilterNotSeatedOnlyAction>
    | ReturnType<typeof createLightGuestsChangePageAction>
    | ReturnType<typeof createSendInvitationToMyselfAction>
    | ReturnType<typeof createSendInvitationToMyselfSuccessAction>
    | ReturnType<typeof createSendInvitationToMyselfFailureAction>
    | ReturnType<typeof createSendInvitationsAction>
    | ReturnType<typeof createSendInvitationsSuccessAction>
    | ReturnType<typeof createSendInvitationsFailureAction>
    | ReturnType<typeof createSendRequestPlacementPreferencesToMyselfAction>
    | ReturnType<typeof createSendRequestPlacementPreferencesToMyselfSuccessAction>
    | ReturnType<typeof createSendRequestPlacementPreferencesToMyselfFailureAction>
    | ReturnType<typeof createSendRequestPlacementPreferencesAction>
    | ReturnType<typeof createSendRequestPlacementPreferencesSuccessAction>
    | ReturnType<typeof createSendRequestPlacementPreferencesFailureAction>
    | ReturnType<typeof createAnswerInvitationRequestAction>
    | ReturnType<typeof createAnswerInvitationSuccessAction>
    | ReturnType<typeof createAnswerInvitationFailureAction>;
