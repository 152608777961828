import { faGavel } from '@fortawesome/free-solid-svg-icons/faGavel';
import { faQuestion } from '@fortawesome/free-solid-svg-icons/faQuestion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Language } from 'components/common/language';
import { RoutePaths } from 'components/Routes/RoutePaths';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { ChatButton } from './ChatButton/ChatButton';

import './Footer.scss';

class FooterComponent extends React.PureComponent<WithTranslation> {
    public render() {
        const { t } = this.props;
        const currentYear = new Date().getFullYear();
        return (
            <footer>
                <ul className="d-flex flex-wrap justify-content-center align-items-center">
                    <li className="has-dropup">
                        <Language />
                    </li>

                    <li className="has-dropup">
                        <UncontrolledDropdown direction="up">
                            <DropdownToggle caret={true} color="">
                                <FontAwesomeIcon icon={faGavel} />
                                {t('Common:Legal')}
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem href={RoutePaths.Legal.Privacy.url()}>
                                    {t('Sitemap:Privacy')}
                                </DropdownItem>
                                <DropdownItem href={RoutePaths.Legal.TermsOfUse.url()}>
                                    {t('Sitemap:TermsOfUse')}
                                </DropdownItem>
                                <DropdownItem href={RoutePaths.Legal.TermsOfSales.url()}>
                                    {t('Sitemap:TermsOfSales')}
                                </DropdownItem>
                                <DropdownItem href={RoutePaths.Legal.LegalNotice.url()}>
                                    {t('Sitemap:LegalNotices')}
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </li>

                    <li className="has-dropup">
                        <UncontrolledDropdown direction="up">
                            <DropdownToggle caret={true} color="">
                                <FontAwesomeIcon icon={faQuestion} />
                                {t('Sitemap:Help')}
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem href={RoutePaths.Help.url()}>
                                    {t('Sitemap:FAQ')}
                                </DropdownItem>
                                <DropdownItem href={RoutePaths.Forum.url()}>
                                    {t('Sitemap:Forum')}
                                </DropdownItem>
                                <DropdownItem href={RoutePaths.Contact.url()}>
                                    {t('Sitemap:Contact')}
                                </DropdownItem>
                                <DropdownItem href={RoutePaths.ServicesPricing.url()} role="menuitem" className="dropdown-item">
                                    {t('Sitemap:ServicesPricing')}
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </li>

                    <li>
                        <a href={RoutePaths.About.url()}>
                            {t('Sitemap:About')}
                        </a>
                    </li>
                    <li>
                        <a href={RoutePaths.Press.url()}>
                            {t('Sitemap:Press')}
                        </a>
                    </li>

                    <li className="text-muted">
                        <span>&copy; 2013 - {currentYear}</span>
                        <a target="_blank" rel="noopener noreferrer" href="http://www.wadane.com/" title="Wadane">Wadane</a>
                        <span className="d-none d-sm-block">
                            {t('Common:Copyright')}
                        </span>
                    </li>

                    <li className="filler d-none d-lg-block" />
                    <li><ChatButton /></li>
                </ul>
            </footer>
        );
    }
}

export const Footer = withTranslation()(FooterComponent);
