import { connectRouter, routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { actionsRecorderMiddleware, errorLoggingMiddleware, signalRMiddleware } from 'middlewares';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import reduxThunk from 'redux-thunk';
import { windowIfDefined } from 'services/WindowService';
import { ApplicationState, reducers } from './store';

declare global {
    // tslint:disable-next-line: interface-name
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: <R>(a: R) => R;
    }
}

const createRootReducer = (history: History) => combineReducers({
    router: connectRouter(history),
    toastr: toastrReducer,
    ...reducers,
});

export let store: undefined | ReturnType<typeof configureStore> = undefined;

export function configureStore(history: History, initialState?: ApplicationState) {
    // If devTools is installed, connect to it
    const composeEnhancers = windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const newStore = createStore(
        createRootReducer(history),
        initialState,
        composeEnhancers(
            applyMiddleware(
                errorLoggingMiddleware,
                reduxThunk.withExtraArgument({}),
                signalRMiddleware,
                routerMiddleware(history),
                actionsRecorderMiddleware,
            ),
        ),
    );

    store = newStore;

    return newStore;
}
