
import { AppReducer } from 'store';

const unloadedState = () => {
    return {
        isSigningIn: false as boolean,
        isSigningOut: false as boolean,
    } as const;
};

export type AuthenticationState = ReturnType<typeof unloadedState>;

export const AuthenticationReducer: AppReducer<AuthenticationState> = (state = unloadedState(), action) => {
    switch (action.type) {
        case '@AUTHENTICATION/INIT_SIGN_IN':
            return {
                ...state,
                isSigningIn: true,
            };

        case '@AUTHENTICATION/INIT_SIGN_IN_COMPLETE':
            return {
                ...state,
                isSigningIn: false,
            };

        case '@AUTHENTICATION/INIT_SIGN_OUT':
            return {
                ...state,
                isSigningOut: true,
            };

        default:
            return state;
    }
};
