import { ErrorBoundary } from 'components/common/ErrorBoundary';
import { MetaData } from 'components/common/MetaData';
import * as React from 'react';
import ReduxToastr from 'react-redux-toastr';
import { Footer } from './footer/Footer';
import { NavBar } from './nav';
import { CookieConsent } from './CookieConsent';

export const AppLayout: React.FC = (props) => (
    <React.Fragment>
        <ErrorBoundary>
            <MetaData />
        </ErrorBoundary>
        <NavBar />
        <div id="body" className="body-content">
            <ErrorBoundary>
                {props.children}
            </ErrorBoundary>
        </div>
        <Footer />
        <CookieConsent />
        <ReduxToastr
            timeOut={5000}
            newestOnTop={true}
            preventDuplicates={true}
            position="top-right"
            transitionIn="bounceIn"
            transitionOut="bounceOut"
            progressBar={true}
            closeOnToastrClick={true}
        />
    </React.Fragment>
);
