import { faComment } from '@fortawesome/free-solid-svg-icons/faComment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { documentIfDefined } from 'services/WindowService';
import classNames from 'classnames';
import { boundMethod } from 'autobind-decorator';
import { IState } from './ChatButtonModels';

import './ChatButton.scss';


class ChatButtonComponent extends React.PureComponent<WithTranslation, IState> {
    constructor(props: WithTranslation) {
        super(props);

        this.state = {
            isChatHidden: false
        };
    }

    public render() {
        const { t } = this.props;
        const chatElement = this.getChatElement();
        const chatElementHidden = this.state.isChatHidden;
        const label = chatElementHidden ? t("Common:DisplayChat") : t("Common:HideChat");
        const className = classNames(
            "btn",
            {
                "btn-chat-primary": chatElementHidden,
                "btn-chat-secondary": !chatElementHidden
            }
        );

        return (
            chatElement ?
                <button onClick={this.toggleChatOnClick}
                    className={className}
                    title={label}
                    aria-label={label}
                >
                    <FontAwesomeIcon icon={faComment} flip="horizontal" />
                </button>
            : null
        );
    }

    @boundMethod
    private toggleChatOnClick() {
        const chatElt = this.getChatElement();
        if (chatElt) {
            this.setState({ isChatHidden: !this.state.isChatHidden });
            chatElt.style.display = this.state.isChatHidden ? "block" : "none";
        }
    }

    private getChatElement() {
        return documentIfDefined?.getElementById('chat-application');
    }
}

export const ChatButton = withTranslation()(ChatButtonComponent);
