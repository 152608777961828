import { Chair, Table, TableFitType } from './api/ApiClient';

export const reorderChairs = (table: Table): Table => {
    if (!table || !table.chairs) {
        return table;
    }

    const orderedChairs = table.chairs
        .sort((chairA, chairB) => {
            return (chairA.number < chairB.number) ? -1 : (chairA.number > chairB.number) ? 1 : 0;
        });

    let chairs;
    const tableCenterPoint = { x: table.left, y: table.top };
    switch (table.fit) {
        case TableFitType.Round:
            chairs = reorderChairsOnCircle(orderedChairs, table.width * 100 / 2, tableCenterPoint);
            break;

        case TableFitType.Rectangle:
        case TableFitType.Square:
        case TableFitType.Oval:
        default:
            chairs = reorderChairsOnRectangle(orderedChairs, table.width * 100, table.height * 100, tableCenterPoint);
            chairs = rotateChairs(chairs, table.angle, tableCenterPoint);
            break;
    }

    return {
        ...table,
        chairs,
    };
};

export const addChair = (table: Table, newChair: Chair): Table => {
    if (!table) {
        return table;
    }

    if (!table.chairs) {
        table.chairs = [];
    }

    let chairs: Chair[];
    const tableCenterPoint = { x: table.left, y: table.top };
    switch (table.fit) {
        case TableFitType.Round:
            chairs = addChairOnCircle(table.chairs, newChair, table.width * 100 / 2, tableCenterPoint);
            break;

        case TableFitType.Rectangle:
        case TableFitType.Square:
        case TableFitType.Oval:
        default:
            {
                const unrotatedChairs = rotateChairs(table.chairs, -table.angle, tableCenterPoint) || [];
                chairs = addChairOnRectangle(unrotatedChairs, newChair, table.width * 100, table.height * 100, tableCenterPoint);
                chairs = rotateChairs(chairs, table.angle, tableCenterPoint) || [];
                break;
            }
    }

    return {
        ...table,
        chairs,
    };
};

export const rotateChairs = (chairs: Chair[] | null, angle: number, center: IPoint) => {
    if (!angle || !chairs) {
        return chairs;
    }

    return chairs.map((chair) => {
        const chairPositionWithRotation = rotatePoint(angle, center, { x: chair.left, y: chair.top });
        return {
            ...chair,
            left: getFixedValue(chairPositionWithRotation.x),
            top: getFixedValue(chairPositionWithRotation.y)
        };
    });
};

export const findAvailableSpace = (areaWidth: number, areaHeight: number, objects: IObject[], newObject: IObject): IPoint => {
    const objectsAsPolygons = objects.map(toPolygon);
    const testTable = { ...newObject };

    for (let y = testTable.height * 100 / 2 + newObject.margin; y < areaHeight - testTable.height * 100 + newObject.margin; y += 10) {
        for (let x = testTable.width * 100 / 2 + newObject.margin; x < areaWidth - testTable.width * 100 + newObject.margin; x += 10) {
            testTable.left = x;
            testTable.top = y;
            const newTableAsPolygon = toPolygon(testTable);
            const intersect = objectsAsPolygons.some((polygon) => doPolygonsIntersect(polygon, newTableAsPolygon));

            if (!intersect) {
                return {
                    x: testTable.left,
                    y: testTable.top,
                };
            }
        }
    }

    return {
        x: testTable.width * 100 / 2 + newObject.margin,
        y: testTable.height * 100 / 2 + newObject.margin,
    };
};

export const getAverageChairDiameter = (chairs?: Chair[] | null) => {
    const average = chairs && chairs.length && chairs.reduce<number>((map, chair) => {
        return map + chair.width;
    }, 0) / chairs.length || .5;
    return getFixedValue(average);
};

export const getMaxRecommandedChairs = (tableFit: TableFitType, width: number, height: number, averageChairDiameter: number) => {
    const tablePerimeter = getTablePerimeter(tableFit, width, height);
    const ensureValidDiameter = averageChairDiameter || .5;
    const maxNeededPlace = ensureValidDiameter + 0.1 * 2.0;
    const minNeededPlace = ensureValidDiameter;
    return {
        min: Math.floor(tablePerimeter / maxNeededPlace),
        max: Math.ceil(tablePerimeter / minNeededPlace),
    };
};

const getTablePerimeter = (tableFit: TableFitType, width: number, height: number) => {
    switch (tableFit) {
        case TableFitType.Round:
            return width * Math.PI;

        case TableFitType.Oval:
            {
                const radiusA = width / 2;
                const radiusB = height / 2;
                return Math.PI * (3 * (radiusA + radiusB) - Math.sqrt((3 * radiusA + radiusB) * (radiusA + 3 * radiusB)));
            }

        default:
            return width * 2.0 + height * 2.0;
    }
};

const reorderChairsOnCircle = (chairs: Chair[], radius: number, center: IPoint): Chair[] => {
    const seatDiameter = getAverageChairDiameter(chairs) * 100;
    const pathRay = radius + seatDiameter / 2;
    const deltaAngle = 2.0 * Math.PI / chairs.length;

    return chairs.map((chair, index) => {
        const angle = deltaAngle * index;
        const left = getFixedValue(pathRay * Math.cos(angle) + center.x);
        const top = getFixedValue(pathRay * Math.sin(angle) + center.y);
        return {
            ...chair,
            left,
            top
        };
    });
};

const reorderChairsOnRectangle = (chairs: Chair[], width: number, height: number, center: IPoint): Chair[] => {
    const seatDiameter = getAverageChairDiameter(chairs) * 100;
    let topChairNumbers = 0;
    let rightChairNumbers = 0;
    let bottomChairNumbers = 0;
    let leftChairNumbers = 0;

    chairs.forEach(() => {
        const topSpaceIndicator = width - topChairNumbers * seatDiameter;
        const rightSpaceIndicator = height - rightChairNumbers * seatDiameter;
        const bottomSpaceIndicator = width - bottomChairNumbers * seatDiameter;
        const leftSpaceIndicator = height - leftChairNumbers * seatDiameter;
        const maxSpaceIndicator = Math.max(topSpaceIndicator, rightSpaceIndicator, bottomSpaceIndicator, leftSpaceIndicator);

        switch (maxSpaceIndicator) {
            case topSpaceIndicator:
                topChairNumbers++;
                break;

            case bottomSpaceIndicator:
                bottomChairNumbers++;
                break;

            case rightSpaceIndicator:
                rightChairNumbers++;
                break;

            default:
                leftChairNumbers++;
                break;
        }
    });

    let topRemainingPlaces = topChairNumbers;
    let rightRemainingPlaces = rightChairNumbers;
    let bottomRemainingPlaces = bottomChairNumbers;
    let leftRemainingPlaces = leftChairNumbers;
    const tableLeft = center.x - width / 2;
    const tableTop = center.y - height / 2;

    return chairs.map((chair) => {
        let spaceWidth;
        let left = tableLeft - 2 * seatDiameter;
        let top = tableTop - 2 * seatDiameter;
        if (topRemainingPlaces > 0) {
            spaceWidth = width / topChairNumbers;
            left = tableLeft + (topChairNumbers - topRemainingPlaces) * spaceWidth + spaceWidth / 2;
            top = tableTop - seatDiameter / 2;
            topRemainingPlaces--;
        } else if (rightRemainingPlaces > 0) {
            spaceWidth = height / rightChairNumbers;
            left = tableLeft + width + seatDiameter / 2;
            top = tableTop + (rightChairNumbers - rightRemainingPlaces) * spaceWidth + spaceWidth / 2;
            rightRemainingPlaces--;
        } else if (bottomRemainingPlaces > 0) {
            spaceWidth = width / bottomChairNumbers;
            left = tableLeft + width - (bottomChairNumbers - bottomRemainingPlaces) * spaceWidth - spaceWidth / 2;
            top = tableTop + height + seatDiameter / 2;
            bottomRemainingPlaces--;
        } else if (leftRemainingPlaces > 0) {
            spaceWidth = height / leftChairNumbers;
            left = tableLeft - seatDiameter / 2;
            top = tableTop + height - (leftChairNumbers - leftRemainingPlaces) * spaceWidth - spaceWidth / 2;
            leftRemainingPlaces--;
        }
        return {
            ...chair,
            left,
            top,
        };
    });
};

/**
 * Ensure that we get 100 instead of 100.00000000000001 or 99.99999999999999
 */
const getFixedValue = (value: number) => {
    // 10 decimals just in case we need that much precision.
    return +value.toFixed(10);
}

const rotatePoint = (angle: number, center: IPoint, point: IPoint): IPoint => {
    if (!angle) {
        return {
            x: point.x,
            y: point.y,
        };
    }

    const angleInRadians = angle * (Math.PI / 180);
    const cosTheta = Math.cos(angleInRadians);
    const sinTheta = Math.sin(angleInRadians);
    return {
        x: cosTheta * (point.x - center.x) - sinTheta * (point.y - center.y) + center.x,
        y: sinTheta * (point.x - center.x) + cosTheta * (point.y - center.y) + center.y,
    };
};

const addChairOnCircle = (chairs: Chair[], newChair: Chair, radius: number, center: IPoint): Chair[] => {
    const result = [...chairs, newChair];
    const seatDiameter = getAverageChairDiameter(result) * 100;

    const temp = [...chairs];
    const angles: number[] = [];
    while (temp.length > 0) {
        const tempChair = temp.shift();
        if (tempChair) {
            angles.push(...temp.map((c) => Math.abs(calcAngle(
                { x: tempChair.left, y: tempChair.top },
                { x: c.left, y: c.top },
                center,
            ))));
        }
    }
    const deltaAngle = Math.min(...angles.filter((a) => a > Math.PI / 180));

    const chairsAsPolygons = chairs.map((c) => toPolygon({ ...c, margin: 0, angle: 0 }));
    let pathRay = radius + seatDiameter / 2;
    let angle = 0;
    while (pathRay < radius + seatDiameter * 2) {
        newChair.left = pathRay * Math.cos(angle) + center.x;
        newChair.top = pathRay * Math.sin(angle) + center.y;

        const newChairAsPolygon = toPolygon({ ...newChair, margin: 0, angle: 0 });
        const intersect = chairsAsPolygons.some((polygon) => doPolygonsIntersect(polygon, newChairAsPolygon));

        if (!intersect) {
            break;
        }

        angle += deltaAngle;

        if (angle > 2 * Math.PI) {
            angle = 0;
            pathRay += seatDiameter;
        }

        if (pathRay > radius + seatDiameter * 2) {
            newChair.left = center.x;
            newChair.top = center.y;
        }
    }

    return result;
};

const calcAngle = (pointA: IPoint, pointB: IPoint, center: IPoint) => {
    const dx0 = pointA.x - center.x;
    const dy0 = pointA.y - center.y;
    const dx1 = pointB.x - center.x;
    const dy1 = pointA.y - center.y;
    return Math.atan2(dx0 * dy1 - dx1 * dy0, dx0 * dx1 + dy0 * dy1);
};

const addChairOnRectangle = (chairs: Chair[], newChair: Chair, width: number, height: number, center: IPoint): Chair[] => {
    const result = [...chairs, newChair];
    const seatDiameter = getAverageChairDiameter(result) * 100;

    const temp = [...chairs];
    const spaces: number[] = [];
    while (temp.length > 0) {
        const tempChair = temp.shift();
        if (tempChair) {
            spaces.push(...temp.map((c) => Math.min(Math.abs(tempChair.left - c.left), Math.abs(tempChair.top - c.top))));
        }
    }
    const deltaSpace = Math.min(10, ...spaces.filter((a) => a > 1));

    const chairsAsPolygons = chairs.map((c) => toPolygon({ ...c, margin: 0, angle: 0 }));

    const tableLeft = center.x - width / 2;
    const tableTop = center.y - height / 2;

    let spaceWidth = 0;
    let spaceHeight = 0;
    let margin = 0;
    while (margin < seatDiameter * 2) {
        if (spaceWidth < width + margin * 2) {
            newChair.left = tableLeft + spaceWidth - seatDiameter / 2 - margin;
            newChair.top = tableTop - seatDiameter / 2 - margin;

            const newTopChairAsPolygon = toPolygon({ ...newChair, margin: 0, angle: 0 });
            if (!chairsAsPolygons.some((polygon) => doPolygonsIntersect(polygon, newTopChairAsPolygon))) {
                break;
            }

            newChair.top = tableTop + height + seatDiameter / 2 + margin;

            const newBottomChairAsPolygon = toPolygon({ ...newChair, margin: 0, angle: 0 });
            if (!chairsAsPolygons.some((polygon) => doPolygonsIntersect(polygon, newBottomChairAsPolygon))) {
                break;
            }
        }
        if (spaceHeight < height + margin * 2) {
            newChair.left = tableLeft - seatDiameter / 2 - margin;
            newChair.top = tableTop + spaceHeight - seatDiameter / 2 - margin;

            const newLeftChairAsPolygon = toPolygon({ ...newChair, margin: 0, angle: 0 });
            if (!chairsAsPolygons.some((polygon) => doPolygonsIntersect(polygon, newLeftChairAsPolygon))) {
                break;
            }

            newChair.left = tableLeft + width + seatDiameter / 2 + margin;

            const newRightChairAsPolygon = toPolygon({ ...newChair, margin: 0, angle: 0 });
            if (!chairsAsPolygons.some((polygon) => doPolygonsIntersect(polygon, newRightChairAsPolygon))) {
                break;
            }
        }

        spaceWidth += deltaSpace;
        spaceHeight += deltaSpace;

        if (spaceWidth > width + margin * 2 && spaceHeight > height + margin * 2) {
            spaceWidth = 0;
            spaceHeight = 0;
            margin += seatDiameter;
        }

        if (margin > seatDiameter * 2) {
            newChair.left = center.x;
            newChair.top = center.y;
        }
    }

    return result;
};

interface IObject {
    top: number;
    left: number;
    width: number;
    height: number;
    angle: number;
    margin: number;
}

interface IPoint {
    x: number;
    y: number;
}

type IPolygon = IPoint[];

const toPolygon = (object: IObject): IPolygon => {
    /*
     * ptl    ----------------   ptr
     *        |              |
     *        |              |
     *        |              |
     *        |              |
     * pbl    ----------------   pbr */
    const tableCenterPoint = { x: object.left, y: object.top };
    const ptl = rotatePoint(object.angle, tableCenterPoint, {
        x: object.left - object.width * 100 / 2 - object.margin,
        y: object.top - object.height * 100 / 2 - object.margin,
    });
    const ptr = rotatePoint(object.angle, tableCenterPoint, {
        x: object.left + object.width * 100 / 2 + object.margin,
        y: object.top - object.height * 100 / 2 - object.margin,
    });
    const pbr = rotatePoint(object.angle, tableCenterPoint, {
        x: object.left + object.width * 100 / 2 + object.margin,
        y: object.top + object.height * 100 / 2 + object.margin,
    });
    const pbl = rotatePoint(object.angle, tableCenterPoint, {
        x: object.left - object.width * 100 / 2 - object.margin,
        y: object.top + object.height * 100 / 2 + object.margin,
    });
    return [ptl, ptr, pbr, pbl];
};

/**
 * Helper function to determine whether there is an intersection between the two polygons described
 * by the lists of vertices. Uses the Separating Axis Theorem
 *
 * @param polygonA an array of connected points [{x:, y:}, {x:, y:},...] that form a closed polygon
 * @param polygonB an array of connected points [{x:, y:}, {x:, y:},...] that form a closed polygon
 * @return true if there is any intersection between the 2 polygons, false otherwise
 */
const doPolygonsIntersect = (polygonA: IPolygon, polygonB: IPolygon) => {
    const polygons = [polygonA, polygonB];
    let minA: number | undefined;
    let maxA: number | undefined;
    let projected: number;
    let minB: number | undefined;
    let maxB: number | undefined;

    return polygons.some((polygon) => {
        // for each polygon, look at each edge of the polygon, and determine if it separates
        // the two shapes
        for (let i1 = 0; i1 < polygon.length; i1++) {
            // grab 2 vertices to create an edge
            const i2 = (i1 + 1) % polygon.length;
            const p1 = polygon[i1];
            const p2 = polygon[i2];

            // find the line perpendicular to this edge
            const normal = {
                x: p2.y - p1.y,
                y: p1.x - p2.x,
            };

            minA = maxA = undefined;
            // for each vertex in the first shape, project it onto the line perpendicular to the edge
            // and keep track of the min and max of these values
            polygonA.forEach((point) => {
                projected = normal.x * point.x + normal.y * point.y;
                if (minA === undefined || projected < minA) {
                    minA = projected;
                }
                if (maxA === undefined || projected > maxA) {
                    maxA = projected;
                }
            });

            // for each vertex in the second shape, project it onto the line perpendicular to the edge
            // and keep track of the min and max of these values
            minB = maxB = undefined;
            polygonB.forEach((point) => {
                projected = normal.x * point.x + normal.y * point.y;
                if (minB === undefined || projected < minB) {
                    minB = projected;
                }
                if (maxB === undefined || projected > maxB) {
                    maxB = projected;
                }
            });

            // if there is no overlap between the projects, the edge we are looking at separates the two
            // polygons, and we know there is no overlap
            if (maxA === undefined || minB === undefined || maxB === undefined || minA === undefined || maxA < minB || maxB < minA) {
                return false;
            }
        }
        return true;
    });
};
