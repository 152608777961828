import { AppReducer } from 'store';
import { IRequest } from 'store/SharedModels';

export interface ICustomUrlAvailability extends IRequest {
    readonly isAvailable?: boolean;
}

const unloadedState = () => {
    return {
        customUrlsAvailabilities: {} as { readonly [customUrl: string]: ICustomUrlAvailability },
    } as const;
};

export type ValidationState = ReturnType<typeof unloadedState>;

export const ValidationReducer: AppReducer<ValidationState> = (state = unloadedState(), action) => {
    switch (action.type) {
        case '@VALIDATION/IS_CUSTOM_URL_AVAILABLE_REQUEST':
            return {
                ...state,
                customUrlsAvailabilities: {
                    ...state.customUrlsAvailabilities,
                    [action.customUrl]: {
                        ...state.customUrlsAvailabilities[action.customUrl],
                        didInvalidate: true,
                        isFetching: true,
                    },
                },
            };

        case '@VALIDATION/IS_CUSTOM_URL_AVAILABLE_SUCCESS':
            return {
                ...state,
                customUrlsAvailabilities: {
                    ...state.customUrlsAvailabilities,
                    [action.customUrl]: {
                        ...state.customUrlsAvailabilities[action.customUrl],
                        didInvalidate: false,
                        isFetching: false,
                        isAvailable: action.isAvailable,
                    },
                },
            };

        case '@VALIDATION/IS_CUSTOM_URL_AVAILABLE_FAILURE':
            return {
                ...state,
                customUrlsAvailabilities: {
                    ...state.customUrlsAvailabilities,
                    [action.customUrl]: {
                        ...state.customUrlsAvailabilities[action.customUrl],
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        default:
            return state;
    }
};
