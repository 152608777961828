import { intersection } from 'lodash-es';
import { normalize } from 'normalizr';
import { RightApi } from 'services/api';
import { Right, RightsModel } from 'services/api/ApiClient';
import { ApiSchema } from 'services/api/ApiSchema';
import { Logger } from 'services/Logger';
import { PromiseStore } from 'services/PromiseStore';
import { SSRService } from 'services/ServerSideRenderingService';
import { AppThunkAction, ApplicationState } from 'store';
import { mergeEntities } from 'store/normalizr/actions';
import { Selectors } from 'store/normalizr/selectors';
import {
    createGetRightsRequestAction,
    createGetRightsRequestFailureAction,
    createGetRightsRequestSuccessAction,
} from './actions';

export const getRights = (eventId?: number | null): AppThunkAction<RightsModel | null> => (dispatch, getState) => {
    const state = getState();

    const request = state.rights.eventsRights[eventId || 0];
    if (request && (request.isFetching || !request.didInvalidate)) {
        return PromiseStore.get('getRights');
    }

    dispatch(createGetRightsRequestAction(eventId || 0));

    const fetchTask = RightApi
        .getRights(eventId || undefined)
        .then((data) => {
            const normalizedData = normalize(data, ApiSchema.RightsModelSchema);
            dispatch(mergeEntities(normalizedData.entities));
            dispatch(createGetRightsRequestSuccessAction(eventId || 0, normalizedData.result));
            return data;
        })
        .catch((error: Error) => {
            dispatch(createGetRightsRequestFailureAction(eventId || 0));
            Logger.logError(error);
            throw error;
        });

    SSRService.addTask(fetchTask, 'getRights');
    PromiseStore.set(fetchTask, 'getRights');

    return fetchTask;
};

export const hasRight = (state: ApplicationState, eventId: number | null, right: Right) => {
    const rightsModelId = state.rights.eventsRights[eventId || 0]?.rightsModelId;
    const rights = rightsModelId && Selectors.getRightsModel(rightsModelId, state)?.rights || [];
    return rights.includes(right);
};

export const hasAnyRights = (state: ApplicationState, eventId: number | null, rights: Right[]) => {
    const rightsModelId = state.rights.eventsRights[eventId || 0]?.rightsModelId;
    const eventRights = rightsModelId && Selectors.getRightsModel(rightsModelId, state)?.rights || [];
    return intersection(eventRights, rights).length > 0;
}