import { RouterState, RouterAction } from 'connected-react-router';
import { Action as ReduxAction, Reducer } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import {
    reducer as OidcReducer,
    USER_EXPIRED,
    REDIRECT_SUCCESS,
    USER_LOADED,
    SILENT_RENEW_ERROR,
    SESSION_TERMINATED,
    USER_EXPIRING,
    USER_FOUND,
    LOADING_USER,
    LOAD_USER_ERROR,
    USER_SIGNED_OUT,
} from 'redux-oidc';

import { AppContextAction } from './appContext/actions';
import { AppContextReducer } from './appContext/reducer';
import { AuthenticationAction } from './authentication/actions';
import { AuthenticationReducer } from './authentication/reducer';
import { BlogAction } from './blog/actions';
import { BlogReducer } from './blog/reducer';
import { ChecklistsAction } from './checklists/actions';
import { ChecklistsReducer } from './checklists/reducer';
import { ConstraintReportsAction } from './constraintReports/actions';
import { ConstraintReportsReducer } from './constraintReports/reducer';
import { EventDetailsAction } from './eventDetails/actions';
import { EventDetailsReducer } from './eventDetails/reducer';
import { EventsAction } from './events/actions';
import { EventsReducer } from './events/reducer';
import { EventWizardAction } from './eventWizard/actions';
import { EventWizardReducer } from './eventWizard/reducer';
import { FullScreenAction } from './fullScreen/actions';
import { FullScreenReducer } from './fullScreen/reducer';
import { GeolocationAction } from './geolocation/actions';
import { GeolocationReducer } from './geolocation/reducer';
import { GuestsAction } from './guests/actions';
import { GuestsReducer } from './guests/reducer';
import { GuestsCsvImportAction } from './guestsCsvImport/actions';
import { GuestsCsvImportReducer } from './guestsCsvImport/reducer';
import { GuestsGoogleImportAction } from './guestsGoogleImport/actions';
import { GuestsGoogleImportReducer } from './guestsGoogleImport/reducer';
import { GuestsMicrosoftImportAction } from './guestsMicrosoftImport/actions';
import { GuestsMicrosoftImportReducer } from './guestsMicrosoftImport/reducer';
import { HomeAction } from './home/actions';
import { HomeReducer } from './home/reducer';
import { InformationAction } from './information/actions';
import { InformationReducer } from './information/reducer';
import { MenusAction } from './menus/actions';
import { MenusReducer } from './menus/reducer';
import { ModalsAction } from './modals/actions';
import { ModalsReducer } from './modals/reducer';
import { NormalizrAction } from './normalizr/actions';
import { NormalizrReducer } from './normalizr/reducer';
import { PrintsAction } from './prints/actions';
import { PrintsReducer } from './prints/reducer';
import { ProDomainsAction } from './proDomains/actions';
import { ProDomainsReducer } from './proDomains/reducer';
import { PurchasesAction } from './purchases/actions';
import { PurchasesReducer } from './purchases/reducer';
import { RightsAction } from './rights/actions';
import { RightsReducer } from './rights/reducer';
import { RobotsAction } from './robots/actions';
import { RobotsReducer } from './robots/reducer';
import { RoomPlansAction } from './roomPlans/actions';
import { RoomPlansReducer } from './roomPlans/reducer';
import { SeatingPlansAction } from './seatingPlans/actions';
import { SeatingPlansReducer } from './seatingPlans/reducer';
import { SignalRAction } from './signalR/actions';
import { SignalRReducer } from './signalR/reducer';
import { TemplatesAction } from './templates/actions';
import { TemplatesReducer } from './templates/reducer';
import { ThemesAction } from './themes/actions';
import { ThemesReducer } from './themes/reducer';
import { ValidationAction } from './validation/actions';
import { ValidationReducer } from './validation/reducer';

export const reducers = {
    appContext: AppContextReducer,
    authentication: AuthenticationReducer,
    blog: BlogReducer,
    checklists: ChecklistsReducer,
    constraintReports: ConstraintReportsReducer,
    entities: NormalizrReducer,
    events: EventsReducer,
    eventDetails: EventDetailsReducer,
    eventWizard: EventWizardReducer,
    fullScreen: FullScreenReducer,
    geolocation: GeolocationReducer,
    guests: GuestsReducer,
    guestsCsvImport: GuestsCsvImportReducer,
    guestsGoogleImport: GuestsGoogleImportReducer,
    guestsMicrosoftImport: GuestsMicrosoftImportReducer,
    home: HomeReducer,
    information: InformationReducer,
    menus: MenusReducer,
    modals: ModalsReducer,
    oidc: OidcReducer,
    prints: PrintsReducer,
    proDomains: ProDomainsReducer,
    purchases: PurchasesReducer,
    rights: RightsReducer,
    robots: RobotsReducer,
    roomPlans: RoomPlansReducer,
    seatingPlans: SeatingPlansReducer,
    signalR: SignalRReducer,
    templates: TemplatesReducer,
    themes: ThemesReducer,
    validation: ValidationReducer,
};

type ReducersMapObject = typeof reducers;
type StateKeys = keyof ReducersMapObject;
export type ApplicationState = { readonly [key in StateKeys]: ReturnType<ReducersMapObject[key]> } & { router: RouterState };

const reduxOidcActionTypes = {
    'redux-oidc/REDIRECT_SUCCESS': REDIRECT_SUCCESS,
    'redux-oidc/USER_LOADED': USER_LOADED,
    'redux-oidc/SILENT_RENEW_ERROR': SILENT_RENEW_ERROR,
    'redux-oidc/SESSION_TERMINATED': SESSION_TERMINATED,
    'redux-oidc/USER_EXPIRING': USER_EXPIRING,
    'redux-oidc/USER_EXPIRED': USER_EXPIRED,
    'redux-oidc/USER_FOUND': USER_FOUND,
    'redux-oidc/LOADING_USER': LOADING_USER,
    'redux-oidc/LOAD_USER_ERROR': LOAD_USER_ERROR,
    'redux-oidc/USER_SIGNED_OUT': USER_SIGNED_OUT,
};
type ReduxOidcAction = { type: keyof typeof reduxOidcActionTypes };

export type Action =
    | AppContextAction
    | AuthenticationAction
    | BlogAction
    | ChecklistsAction
    | ConstraintReportsAction
    | EventsAction
    | EventDetailsAction
    | EventWizardAction
    | FullScreenAction
    | GeolocationAction
    | GuestsAction
    | GuestsCsvImportAction
    | GuestsGoogleImportAction
    | GuestsMicrosoftImportAction
    | HomeAction
    | InformationAction
    | MenusAction
    | ModalsAction
    | NormalizrAction
    | PrintsAction
    | ProDomainsAction
    | PurchasesAction
    | ReduxOidcAction
    | RightsAction
    | RobotsAction
    | RouterAction
    | RoomPlansAction
    | SeatingPlansAction
    | SignalRAction
    | TemplatesAction
    | ThemesAction
    | ValidationAction;

export type AppReducer<S> = Reducer<S, Action>;
export type AppThunkAction<R = void> = ThunkAction<Promise<R>, ApplicationState, unknown, Action | ReduxAction>;
export type AppThunkDispatch = ThunkDispatch<ApplicationState, unknown, Action | ReduxAction>;
