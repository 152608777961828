
import { AppReducer } from 'store';

export interface IGeolocationState {
    readonly [searchId: string]: ISearchAddress;
}

export interface ISearchAddress {
    readonly isSearching: boolean;
    readonly searchAddress: string;
    readonly suggestions: IAddressSuggestion[];
    readonly isGeocoding: boolean;
    readonly address?: IAddress;
}

export interface IAddressSuggestion {
    readonly description: string;
    readonly placeId: string;
}

export interface IPartialCoordinates {
    readonly latitude?: number | null;
    readonly longitude?: number | null;
}

export interface IAddress {
    readonly coords?: IPartialCoordinates;
    readonly address?: {
        readonly city?: string | null;
        readonly country?: string | null;
        readonly countryCode?: string | null;
        readonly county?: string | null;
        readonly postalCode?: string | null;
        readonly premises?: string | null;
        readonly region?: string | null;
        readonly street?: string | null;
        readonly streetNumber?: string | null;
    };
    readonly formattedAddress?: string | null;
    readonly googleMapUrl?: string | null;
    readonly googleMapImageUrl?: string | null;
}

const unloadedState = () => {
    return {} as IGeolocationState;
};

export const DEFAULT_SEARCH: ISearchAddress = {
    isSearching: false,
    searchAddress: '',
    suggestions: [],
    isGeocoding: false,
};

export type GeolocationState = ReturnType<typeof unloadedState>;

export const GeolocationReducer: AppReducer<GeolocationState> = (state = unloadedState(), action) => {
    switch (action.type) {
        case '@GEO_LOCATION/SEARCH_ADDRESS':
            return {
                ...state,
                [action.searchId]: {
                    ...DEFAULT_SEARCH,
                    ...state[action.searchId],
                    isSearching: true,
                    searchAddress: action.searchAddress,
                },
            };

        case '@GEO_LOCATION/SEARCH_ADDRESS_SUCCESS':
        case '@GEO_LOCATION/SEARCH_ADDRESS_SUCCESS_EMPTY':
            return {
                ...state,
                [action.searchId]: {
                    ...DEFAULT_SEARCH,
                    ...state[action.searchId],
                    isSearching: false,
                    suggestions: action.suggestions,
                },
            };

        case '@GEO_LOCATION/SEARCH_ADDRESS_FAILURE':
            return {
                ...state,
                [action.searchId]: {
                    ...DEFAULT_SEARCH,
                    ...state[action.searchId],
                    isSearching: false,
                },
            };

        case '@GEO_LOCATION/GEOCODE_ADDRESS':
            return {
                ...state,
                [action.searchId]: {
                    ...DEFAULT_SEARCH,
                    ...state[action.searchId],
                    isGeocoding: true,
                },
            };

        case '@GEO_LOCATION/GEOCODE_ADDRESS_SUCCESS':
        case '@GEO_LOCATION/GEOCODE_ADDRESS_SUCCESS_EMPTY':
            return {
                ...state,
                [action.searchId]: {
                    ...DEFAULT_SEARCH,
                    ...state[action.searchId],
                    isGeocoding: false,
                    address: action.address,
                },
            };

        case '@GEO_LOCATION/GEOCODE_ADDRESS_FAILURE':
            return {
                ...state,
                [action.searchId]: {
                    ...DEFAULT_SEARCH,
                    ...state[action.searchId],
                    isGeocoding: false,
                    address: undefined,
                },
            };

        default:
            return state;
    }
};
