
import { AppReducer } from 'store';
import { IImportedContact } from 'store/SharedModels';

const unloadedState = () => {
    return {
        isImporting: false as boolean,
        searchTerms: '' as string,
        contacts: [] as IImportedContact[],
    } as const;
};

export type GuestsMicrosoftImportState = ReturnType<typeof unloadedState>;

export const GuestsMicrosoftImportReducer: AppReducer<GuestsMicrosoftImportState> = (state = unloadedState(), action) => {
    switch (action.type) {
        case '@GUESTS_MICROSOFT_IMPORT/IMPORT_CONTACTS':
            return {
                ...state,
                isImporting: true,
            };

        case '@GUESTS_MICROSOFT_IMPORT/IMPORT_CONTACTS_SUCCESS':
            return {
                ...state,
                isImporting: false,
                contacts: action.contacts,
            };

        case '@GUESTS_MICROSOFT_IMPORT/IMPORT_CONTACTS_FAILURE':
            return {
                ...state,
                isImporting: false,
            };

        case '@GUESTS_MICROSOFT_IMPORT/UPDATE_CONTACT_PHOTO':
            return {
                ...state,
                contacts: state.contacts.map((c) => {
                    if (c.externalId === action.contactId) {
                        return {
                            ...c,
                            photo: action.photoUrl,
                        };
                    }
                    return c;
                }),
            };

        case '@GUESTS_MICROSOFT_IMPORT/SET_SEARCH_TERMS':
            return {
                ...state,
                searchTerms: action.terms || '',
            };

        default:
            return state;
    }
};
