import { AppReducer } from 'store';
import { IRequest } from 'store/SharedModels';

export interface IIndicatorRequest { readonly [indicatorType: string]: IRequest }

export interface ICustomUrlRequest extends IRequest { readonly eventId: number }

export interface ICustomUrlByIdRequest extends IRequest { readonly customUrl: string }

const unloadedState = () => {
    return {
        eventDetailsRequests: {} as { readonly [eventId: number]: IRequest },
        indicatorRequests: {} as { readonly [eventId: number]: IIndicatorRequest },
        updateEventRequests: {} as { readonly [eventId: number]: boolean },
        deleteEventRequests: {} as { readonly [eventId: number]: boolean },
        duplicateEventRequests: {} as { readonly [eventId: number]: boolean },
        validationRequests: {} as { readonly [eventId: number]: boolean },
        customUrls: {} as { readonly [customUrl: string]: ICustomUrlRequest },
        eventIds: {} as { readonly [eventId: number]: ICustomUrlByIdRequest },
    } as const;
};

export type EventDetailsState = ReturnType<typeof unloadedState>;

export const EventDetailsReducer: AppReducer<EventDetailsState> = (state = unloadedState(), action) => {
    switch (action.type) {
        case '@EVENT_DETAILS/GET_EVENT_DETAILS_REQUEST':
            return {
                ...state,
                eventDetailsRequests: {
                    ...state.eventDetailsRequests,
                    [action.eventId]: {
                        ...state.eventDetailsRequests[action.eventId],
                        didInvalidate: state.eventDetailsRequests[action.eventId]?.didInvalidate || false,
                        isFetching: true,
                    },
                },
            };

        case '@EVENT_DETAILS/GET_EVENT_DETAILS_SUCCESS':
            return {
                ...state,
                eventDetailsRequests: {
                    ...state.eventDetailsRequests,
                    [action.eventId]: {
                        ...state.eventDetailsRequests[action.eventId],
                        isFetching: false,
                        didInvalidate: false,
                    },
                },
            };

        case '@EVENT_DETAILS/GET_EVENT_DETAILS_FAILURE':
            return {
                ...state,
                eventDetailsRequests: {
                    ...state.eventDetailsRequests,
                    [action.eventId]: {
                        ...state.eventDetailsRequests[action.eventId],
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        case '@EVENT_DETAILS/GET_EVENT_INDICATOR_REQUEST':
            return {
                ...state,
                indicatorRequests: {
                    ...state.indicatorRequests,
                    [action.eventId]: {
                        ...state.indicatorRequests[action.eventId],
                        [action.indicatorType]: {
                            ...(state.indicatorRequests[action.eventId] && state.indicatorRequests[action.eventId][action.indicatorType]),
                            didInvalidate: (state.indicatorRequests[action.eventId] && state.indicatorRequests[action.eventId][action.indicatorType])?.didInvalidate || false,
                            isFetching: true,
                        },
                    },
                },
            };

        case '@EVENT_DETAILS/GET_EVENT_INDICATOR_SUCCESS':
            return {
                ...state,
                indicatorRequests: {
                    ...state.indicatorRequests,
                    [action.eventId]: {
                        ...state.indicatorRequests[action.eventId],
                        [action.indicatorType]: {
                            isFetching: false,
                            didInvalidate: false,
                        },
                    },
                },
            };

        case '@EVENT_DETAILS/GET_EVENT_INDICATOR_FAILURE':
            return {
                ...state,
                indicatorRequests: {
                    ...state.indicatorRequests,
                    [action.eventId]: {
                        ...state.indicatorRequests[action.eventId],
                        [action.indicatorType]: {
                            isFetching: false,
                            didInvalidate: true,
                        },
                    },
                },
            };

        case '@EVENT_DETAILS/UPDATE_EVENT_REQUEST':
            return {
                ...state,
                updateEventRequests: {
                    ...state.updateEventRequests,
                    [action.eventId]: true,
                },
            };

        case '@EVENT_DETAILS/UPDATE_EVENT_SUCCESS':
            return {
                ...state,
                updateEventRequests: {
                    ...state.updateEventRequests,
                    [action.eventId]: false,
                },
                eventDetailsRequests: {
                    ...state.eventDetailsRequests,
                    [action.eventId]: {
                        ...state.eventDetailsRequests[action.eventId],
                        didInvalidate: true,
                    },
                },
            };

        case '@EVENT_DETAILS/UPDATE_EVENT_FAILURE':
            return {
                ...state,
                updateEventRequests: {
                    ...state.updateEventRequests,
                    [action.eventId]: false,
                },
            };

        case '@EVENT_DETAILS/DELETE_EVENT_REQUEST':
            return {
                ...state,
                deleteEventRequests: {
                    ...state.deleteEventRequests,
                    [action.eventId]: true,
                },
            };

        case '@EVENT_DETAILS/DELETE_EVENT_SUCCESS':
            return {
                ...state,
                deleteEventRequests: {
                    ...state.deleteEventRequests,
                    [action.eventId]: false,
                },
                eventDetailsRequests: {
                    ...state.eventDetailsRequests,
                    [action.eventId]: {
                        ...state.eventDetailsRequests[action.eventId],
                        didInvalidate: true,
                    },
                },
            };

        case '@EVENT_DETAILS/DELETE_EVENT_FAILURE':
            return {
                ...state,
                deleteEventRequests: {
                    ...state.deleteEventRequests,
                    [action.eventId]: false,
                },
            };

        case '@EVENT_DETAILS/DUPLICATE_EVENT_REQUEST':
            return {
                ...state,
                duplicateEventRequests: {
                    ...state.duplicateEventRequests,
                    [action.eventId]: true,
                },
            };

        case '@EVENT_DETAILS/DUPLICATE_EVENT_SUCCESS':
        case '@EVENT_DETAILS/DUPLICATE_EVENT_FAILURE':
            return {
                ...state,
                duplicateEventRequests: {
                    ...state.duplicateEventRequests,
                    [action.eventId]: false,
                },
            };

        case '@EVENT_DETAILS/CUSTOM_URL_INFO_REQUEST':
            return {
                ...state,
                customUrls: {
                    ...state.customUrls,
                    [action.customUrl]: {
                        ...state.customUrls[action.customUrl],
                        didInvalidate: state.customUrls[action.customUrl]?.didInvalidate || false,
                        isFetching: true,
                    },
                },
            };

        case '@EVENT_DETAILS/CUSTOM_URL_INFO_BY_EVENT_ID_REQUEST':
            return {
                ...state,
                eventIds: {
                    ...state.eventIds,
                    [action.eventId]: {
                        ...state.eventIds[action.eventId],
                        didInvalidate: state.eventIds[action.eventId]?.didInvalidate || false,
                        isFetching: true,
                    },
                },
            };

        case '@EVENT_DETAILS/CUSTOM_URL_INFO_SUCCESS':
            return {
                ...state,
                customUrls: {
                    ...state.customUrls,
                    [action.customUrl]: {
                        ...state.customUrls[action.customUrl],
                        isFetching: false,
                        didInvalidate: false,
                        eventId: action.eventId,
                    },
                },
                eventIds: {
                    ...state.eventIds,
                    [action.eventId]: {
                        ...state.eventIds[action.eventId],
                        isFetching: false,
                        didInvalidate: false,
                        customUrl: action.customUrl,
                    },
                },
            };

        case '@EVENT_DETAILS/CUSTOM_URL_INFO_FAILURE':
            return {
                ...state,
                customUrls: {
                    ...state.customUrls,
                    [action.customUrl]: {
                        ...state.customUrls[action.customUrl],
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        case '@EVENT_DETAILS/CUSTOM_URL_INFO_BY_EVENT_ID_FAILURE':
            return {
                ...state,
                eventIds: {
                    ...state.eventIds,
                    [action.eventId]: {
                        ...state.eventIds[action.eventId],
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        case '@EVENT_DETAILS/VALIDATE_OFFICIAL_SEATING_PLAN_REQUEST':
            return {
                ...state,
                validationRequests: {
                    ...state.validationRequests,
                    [action.eventId]: true,
                },
            };

        case '@EVENT_DETAILS/VALIDATE_OFFICIAL_SEATING_PLAN_SUCCESS':
        case '@EVENT_DETAILS/VALIDATE_OFFICIAL_SEATING_PLAN_FAILURE':
            return {
                ...state,
                validationRequests: {
                    ...state.validationRequests,
                    [action.eventId]: false,
                },
            };

        default:
            return state;
    }
};
