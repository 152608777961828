import { AppReducer } from 'store';
import { IRequest } from 'store/SharedModels';

export interface IGetChecklistRequest extends IRequest {
    readonly searchTerms: string;
}

export interface ITemplatesState extends IRequest {
    readonly templates: number[];
    readonly selectedTemplateId?: number;
    readonly templateItemsRequests: { readonly [templateId: number]: ITemplateItemsState };
    readonly searchTerms: string;
    readonly isAddingTemplatedItemsRequest: boolean;
}

export interface ITemplateItemsState extends IRequest {
    readonly items: number[];
    readonly selected: number[];
}

const unloadedState = () => {
    return {
        checklistRequests: {} as { readonly [eventId: number]: IGetChecklistRequest },

        itemUpdateRequests: {} as { readonly [itemId: string]: boolean },
        itemDeleteRequests: {} as { readonly [itemId: number]: boolean },

        templates: {
            didInvalidate: true,
            isFetching: false,
            templates: [],
            templateItemsRequests: {},
            searchTerms: '',
            isAddingTemplatedItemsRequest: false,
        } as ITemplatesState,
    } as const;
};

export const DEFAULT_SEARCH: IGetChecklistRequest = {
    isFetching: false,
    didInvalidate: true,
    searchTerms: '',
};

export type ChecklistsState = ReturnType<typeof unloadedState>;

export const ChecklistsReducer: AppReducer<ChecklistsState> = (state = unloadedState(), action) => {
    switch (action.type) {
        case '@CHECKLIST/GET_CHECKLIST_REQUEST':
            return {
                ...state,
                checklistRequests: {
                    ...state.checklistRequests,
                    [action.eventId]: {
                        ...DEFAULT_SEARCH,
                        ...state.checklistRequests[action.eventId],
                        isFetching: true,
                    },
                },
            };

        case '@CHECKLIST/GET_CHECKLIST_SUCCESS':
            return {
                ...state,
                checklistRequests: {
                    ...state.checklistRequests,
                    [action.eventId]: {
                        ...DEFAULT_SEARCH,
                        ...state.checklistRequests[action.eventId],
                        isFetching: false,
                        didInvalidate: false,
                    },
                },
            };

        case '@CHECKLIST/GET_CHECKLIST_FAILURE':
            return {
                ...state,
                checklistRequests: {
                    ...state.checklistRequests,
                    [action.eventId]: {
                        ...DEFAULT_SEARCH,
                        ...state.checklistRequests[action.eventId],
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        case '@CHECKLIST/SET_SEARCH_TERMS':
            return {
                ...state,
                checklistRequests: {
                    ...state.checklistRequests,
                    [action.eventId]: {
                        ...state.checklistRequests[action.eventId],
                        searchTerms: action.searchTerms,
                    },
                },
            };

        case '@CHECKLIST/UPDATE_ITEM_REQUEST':
            return {
                ...state,
                itemUpdateRequests: {
                    ...state.itemUpdateRequests,
                    [action.itemId]: true,
                },
            };

        case '@CHECKLIST/UPDATE_ITEM_SUCCESS':
            return {
                ...state,
                checklistRequests: {
                    ...state.checklistRequests,
                    [action.eventId]: {
                        ...state.checklistRequests[action.eventId],
                        didInvalidate: true,
                    },
                },
                itemUpdateRequests: {
                    ...state.itemUpdateRequests,
                    [action.itemId]: false,
                },
            };

        case '@CHECKLIST/UPDATE_ITEM_FAILURE':
            return {
                ...state,
                itemUpdateRequests: {
                    ...state.itemUpdateRequests,
                    [action.itemId]: false,
                },
            };

        case '@CHECKLIST/DELETE_ITEM_REQUEST':
            return {
                ...state,
                itemDeleteRequests: {
                    ...state.itemDeleteRequests,
                    [action.itemId]: true,
                },
            };

        case '@CHECKLIST/DELETE_ITEM_SUCCESS':
            return {
                ...state,
                checklistRequests: {
                    ...state.checklistRequests,
                    [action.eventId]: {
                        ...state.checklistRequests[action.eventId],
                        didInvalidate: true,
                    },
                },
                itemDeleteRequests: {
                    ...state.itemDeleteRequests,
                    [action.itemId]: false,
                },
            };

        case '@CHECKLIST/DELETE_ITEM_FAILURE':
            return {
                ...state,
                itemDeleteRequests: {
                    ...state.itemDeleteRequests,
                    [action.itemId]: false,
                },
            };

        case '@CHECKLIST/GET_CHECKLIST_TEMPLATES_REQUEST':
            return {
                ...state,
                templates: {
                    ...state.templates,
                    isFetching: true,
                },
            };

        case '@CHECKLIST/GET_CHECKLIST_TEMPLATES_SUCCESS':
            return {
                ...state,
                templates: {
                    ...state.templates,
                    isFetching: false,
                    didInvalidate: false,
                    templates: action.templates,
                },
            };

        case '@CHECKLIST/GET_CHECKLIST_TEMPLATES_FAILURE':
            return {
                ...state,
                templates: {
                    ...state.templates,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        case '@CHECKLIST/GET_CHECKLIST_TEMPLATE_ITEMS_REQUEST':
            return {
                ...state,
                templates: {
                    ...state.templates,
                    templateItemsRequests: {
                        ...state.templates.templateItemsRequests,
                        [action.templateId]: {
                            ...state.templates.templateItemsRequests[action.templateId],
                            items: state.templates.templateItemsRequests[action.templateId]?.items || [],
                            selected: state.templates.templateItemsRequests[action.templateId]?.selected || [],
                            didInvalidate: true,
                            isFetching: true,
                        },
                    },
                },
            };

        case '@CHECKLIST/GET_CHECKLIST_TEMPLATE_ITEMS_SUCCESS':
            return {
                ...state,
                templates: {
                    ...state.templates,
                    templateItemsRequests: {
                        ...state.templates.templateItemsRequests,
                        [action.templateId]: {
                            ...state.templates.templateItemsRequests[action.templateId],
                            isFetching: false,
                            didInvalidate: false,
                            selected: action.items,
                            items: action.items,
                        },
                    },
                },
            };

        case '@CHECKLIST/GET_CHECKLIST_TEMPLATE_ITEMS_FAILURE':
            return {
                ...state,
                templates: {
                    ...state.templates,
                    templateItemsRequests: {
                        ...state.templates.templateItemsRequests,
                        [action.templateId]: {
                            ...state.templates.templateItemsRequests[action.templateId],
                            items: state.templates.templateItemsRequests[action.templateId]?.items || [],
                            selected: state.templates.templateItemsRequests[action.templateId]?.selected || [],
                            isFetching: false,
                            didInvalidate: true,
                        },
                    },
                },
            };

        case '@CHECKLIST/SELECT_TEMPLATE':
            return {
                ...state,
                templates: {
                    ...state.templates,
                    selectedTemplateId: action.templateId,
                },
            };

        case '@CHECKLIST/SET_TEMPLATE_ITEMS_SEARCH_TERMS':
            return {
                ...state,
                templates: {
                    ...state.templates,
                    searchTerms: action.searchTerms,
                },
            };

        case '@CHECKLIST/ADD_TEMPLATE_ITEM':
            return {
                ...state,
                templates: {
                    ...state.templates,
                    templateItemsRequests: {
                        ...state.templates.templateItemsRequests,
                        [action.templateId]: {
                            ...state.templates.templateItemsRequests[action.templateId],
                            isFetching: state.templates.templateItemsRequests[action.templateId]?.isFetching || false,
                            didInvalidate: state.templates.templateItemsRequests[action.templateId]?.didInvalidate || false,
                            items: state.templates.templateItemsRequests[action.templateId]?.items || [],
                            selected: [...state.templates.templateItemsRequests[action.templateId].selected, action.itemId],
                        },
                    },
                },
            };

        case '@CHECKLIST/REMOVE_TEMPLATE_ITEM':
            return {
                ...state,
                templates: {
                    ...state.templates,
                    templateItemsRequests: {
                        ...state.templates.templateItemsRequests,
                        [action.templateId]: {
                            ...state.templates.templateItemsRequests[action.templateId],
                            isFetching: state.templates.templateItemsRequests[action.templateId]?.isFetching || false,
                            didInvalidate: state.templates.templateItemsRequests[action.templateId]?.didInvalidate || false,
                            items: state.templates.templateItemsRequests[action.templateId]?.items || [],
                            selected: state.templates.templateItemsRequests[action.templateId].selected.filter((i) => i !== action.itemId),
                        },
                    },
                },
            };

        case '@CHECKLIST/ADD_ITEMS_FROM_TEMPLATE_REQUEST':
            return {
                ...state,
                templates: {
                    ...state.templates,
                    isAddingTemplatedItemsRequest: true,
                },
            };

        case '@CHECKLIST/ADD_ITEMS_FROM_TEMPLATE_SUCCESS':
            return {
                ...state,
                checklistRequests: {
                    ...state.checklistRequests,
                    [action.eventId]: {
                        ...state.checklistRequests[action.eventId],
                        didInvalidate: true,
                    },
                },
                templates: {
                    ...state.templates,
                    isAddingTemplatedItemsRequest: false,
                },
            };

        case '@CHECKLIST/ADD_ITEMS_FROM_TEMPLATE_FAILURE':
            return {
                ...state,
                templates: {
                    ...state.templates,
                    isAddingTemplatedItemsRequest: false,
                },
            };

        case '@SIGNALR/CHECKLIST_ITEM_UPDATED':
        case '@SIGNALR/CHECKLIST_ITEM_CREATED':
        case '@SIGNALR/CHECKLIST_ITEM_DELETED':
            return {
                ...state,
                checklistRequests: {
                    ...state.checklistRequests,
                    [action.payload.eventId]: {
                        ...state.checklistRequests[action.payload.eventId],
                        didInvalidate: true,
                    },
                },
            };

        default:
            return state;
    }
};
