import { IRequest } from 'store/SharedModels';
import { AppReducer } from 'store';

export const DEFAULT_TAKE_SIZE = 3;

export interface ISearchRoomPlansRequest extends IRequest {
    readonly searchTerms: string;
    readonly skip: number;
    readonly take: number;
    readonly totalResults: number;
    readonly roomPlans: number[];
}

const unloadedState = () => {
    return {
        search: {
            didInvalidate: true,
            isFetching: false,
            searchTerms: '',
            skip: 0,
            take: DEFAULT_TAKE_SIZE,
            roomPlans: [],
            totalResults: 0,
        } as ISearchRoomPlansRequest,
        roomPlanRequests: {} as { readonly [roomPlanId: number]: IRequest },
        purchaseRequests: {} as { readonly [eventId: number]: { [roomPlanId: number]: boolean } },
    } as const;
};

export type RoomPlansState = ReturnType<typeof unloadedState>;

export const RoomPlansReducer: AppReducer<RoomPlansState> = (state = unloadedState(), action) => {
    switch (action.type) {
        case '@ROOM_PLANS/SEARCH_ROOM_PLANS_REQUEST':
            return {
                ...state,
                search: {
                    ...state.search,
                    isFetching: true,
                },
            };

        case '@ROOM_PLANS/SEARCH_ROOM_PLANS_SUCCESS':
            return {
                ...state,
                search: {
                    ...state.search,
                    isFetching: false,
                    didInvalidate: false,
                    totalResults: action.totalResults,
                    roomPlans: action.roomPlans,
                },
                roomPlanRequests: {
                    ...state.roomPlanRequests,
                    ...action.roomPlans.reduce<{ [roomPlanId: number]: IRequest }>((prev, curr) => {
                        prev[curr] = {
                            ...state.roomPlanRequests[curr],
                            isFetching: false,
                            didInvalidate: false,
                        };
                        return prev;
                    }, {}),
                },
            };

        case '@ROOM_PLANS/SEARCH_ROOM_PLANS_FAILURE':
            return {
                ...state,
                search: {
                    ...state.search,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        case '@ROOM_PLANS/SET_SEARCH_TERMS':
            return {
                ...state,
                search: {
                    ...state.search,
                    searchTerms: action.searchTerms,
                    skip: 0,
                    didInvalidate: true,
                },
            };

        case '@ROOM_PLANS/CHANGE_PAGE':
            return {
                ...state,
                search: {
                    ...state.search,
                    skip: action.skip,
                    take: action.take,
                    didInvalidate: true,
                },
            };

        case '@ROOM_PLANS/GET_ROOM_PLAN_REQUEST':
            return {
                ...state,
                roomPlanRequests: {
                    ...state.roomPlanRequests,
                    [action.roomPlanId]: {
                        ...state.roomPlanRequests[action.roomPlanId],
                        didInvalidate: state.roomPlanRequests[action.roomPlanId]?.didInvalidate || false,
                        isFetching: true,
                    },
                },
            };

        case '@ROOM_PLANS/GET_ROOM_PLAN_SUCCESS':
            return {
                ...state,
                roomPlanRequests: {
                    ...state.roomPlanRequests,
                    [action.roomPlanId]: {
                        ...state.roomPlanRequests[action.roomPlanId],
                        isFetching: false,
                        didInvalidate: false,
                    },
                },
            };

        case '@ROOM_PLANS/GET_ROOM_PLAN_FAILURE':
            return {
                ...state,
                roomPlanRequests: {
                    ...state.roomPlanRequests,
                    [action.roomPlanId]: {
                        ...state.roomPlanRequests[action.roomPlanId],
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        case '@ROOM_PLANS/PURCHASE_ROOM_PLAN_REQUEST':
            return {
                ...state,
                purchaseRequests: {
                    ...state.purchaseRequests,
                    [action.eventId]: {
                        ...state.purchaseRequests[action.eventId],
                        [action.roomPlanId]: true,
                    },
                },
            };

        case '@ROOM_PLANS/PURCHASE_ROOM_PLAN_SUCCESS':
            return {
                ...state,
                purchaseRequests: {
                    ...state.purchaseRequests,
                    [action.eventId]: {
                        ...state.purchaseRequests[action.eventId],
                        [action.roomPlanId]: false,
                    },
                },
            };

        case '@ROOM_PLANS/PURCHASE_ROOM_PLAN_FAILURE':
            return {
                ...state,
                purchaseRequests: {
                    ...state.purchaseRequests,
                    [action.eventId]: {
                        ...state.purchaseRequests[action.eventId],
                        [action.roomPlanId]: false,
                    },
                },
            };

        default:
            return state;
    }
};
