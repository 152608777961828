import { AppLayout } from 'components/layout/AppLayout';
import * as React from 'react';
import { LocalizedRoutes } from './LocalizedRoutes';

export class Routes extends React.PureComponent {
    public render() {
        return (
            <AppLayout>
                <LocalizedRoutes />
            </AppLayout>
        );
    }
}
