import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { boundMethod } from 'autobind-decorator';
import { RoutePaths } from 'components/Routes/RoutePaths';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ICookieConsentProps } from './CookieConsentModels';
import './CookieConsent.scss';

class CookieConsentComponent extends React.PureComponent<ICookieConsentProps & WithTranslation> {
    public componentDidMount() {
        const { onInit } = this.props;
        onInit();
    }

    public render() {
        const { t, showCookieConsent } = this.props;
        return showCookieConsent ? (
            <nav className="navbar navbar-expand-lg fixed-top navbar-light bg-light" role="alert">
                <span className="navbar-brand">
                    <FontAwesomeIcon icon={faInfoCircle} aria-hidden="true" />
                </span>
                <div className="navbar-text d-lg-none">
                    Cookie consent
                </div>
                <button
                    className="navbar-toggler"
                    aria-label="Toggle cookie consent banner"
                    onClick={this.handleWithdrawConsent}
                >
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="d-flex flex-fill align-items-center justify-content-between">                    
                    <div className="navbar-text">
                        {t('Common:CookieMessage')}
                    </div>
                    <div className="cookie-consent-action">
                        <a className="btn btn-info mx-2" href={RoutePaths.Legal.Privacy.url()}>
                            {t('Common:CookieReadMore')}
                        </a>
                        <button className="btn btn-outline-success mx-2" onClick={this.handleGrantConsent}>
                            {t('Common:CookieAcceptButton')}
                        </button>
                    </div>
                </div>
            </nav>
        ) : null;
    }

    @boundMethod
    private handleGrantConsent(): void {
        const { onGrantConsent } = this.props;
        onGrantConsent();
    }

    @boundMethod
    private handleWithdrawConsent(): void {
        const { onWithdrawConsent } = this.props;
        onWithdrawConsent();
    }
}

export const CookieConsent = withTranslation()(CookieConsentComponent);
