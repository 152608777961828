import { normalize } from 'normalizr';
import { ChecklistApi } from 'services/api';
import { ChecklistItem, JsonChecklistTemplateItem } from 'services/api/ApiClient';
import { ApiSchema } from 'services/api/ApiSchema';
import { Logger } from 'services/Logger';
import { PromiseStore } from 'services/PromiseStore';
import { SSRService } from 'services/ServerSideRenderingService';
import { AppThunkAction } from 'store';
import { mergeEntities, removeEntities } from 'store/normalizr/actions';
import { Selectors } from 'store/normalizr/selectors';
import {
    createAddItemsFromTemplateAction,
    createAddItemsFromTemplateFailureAction,
    createAddItemsFromTemplateSuccessAction,
    createDeleteItemRequestAction,
    createDeleteItemRequestFailureAction,
    createDeleteItemRequestSuccessAction,
    createGetChecklistAction,
    createGetChecklistFailureAction,
    createGetChecklistSuccessAction,
    createGetChecklistTemplateItemsAction,
    createGetChecklistTemplateItemsFailureAction,
    createGetChecklistTemplateItemsSuccessAction,
    createGetChecklistTemplatesAction,
    createGetChecklistTemplatesFailureAction,
    createGetChecklistTemplatesSuccessAction,
    createUpdateItemRequestAction,
    createUpdateItemRequestFailureAction,
    createUpdateItemRequestSuccessAction,
} from './actions';

export const getChecklist = (eventId: number): AppThunkAction<boolean> => (dispatch, getState) => {
    const state = getState();

    const request = state.checklists.checklistRequests[eventId];
    if (request && (request.isFetching || !request.didInvalidate)) {
        return PromiseStore.get('getChecklist', eventId);
    }

    dispatch(createGetChecklistAction(eventId));

    const fetchTask = ChecklistApi
        .getChecklist(eventId, undefined, 0, 3000)
        .then((data) => {
            const normalizedData = normalize(data, ApiSchema.JsonChecklistSchema);
            dispatch(mergeEntities(normalizedData.entities));
            dispatch(createGetChecklistSuccessAction(eventId));
            return !data.checklistItems?.length;
        })
        .catch((error: Error) => {
            dispatch(createGetChecklistFailureAction(eventId));
            Logger.logError(error);
            throw error;
        });

    SSRService.addTask(fetchTask, 'getChecklist');
    PromiseStore.set(fetchTask, 'getChecklist', eventId);

    return fetchTask;
};

export const updateItem = (eventId: number, item: ChecklistItem, tempId?: number): AppThunkAction => (dispatch, getState) => {
    const state = getState();

    const itemId = item.id || tempId || -1;
    const request = state.checklists.itemUpdateRequests[itemId.toString()];
    if (request) {
        return PromiseStore.get('updateItem', eventId, itemId);
    }

    dispatch(createUpdateItemRequestAction(itemId));

    const fetchTask = ChecklistApi
        .updateItem(eventId, item)
        .then((data) => {
            item.id = data.id;
            const normalizedData = normalize(item, ApiSchema.ChecklistItemSchema);
            dispatch(mergeEntities(normalizedData.entities));
            dispatch(createUpdateItemRequestSuccessAction(eventId, itemId));
        })
        .catch((error: Error) => {
            dispatch(createUpdateItemRequestFailureAction(itemId));
            Logger.logError(error);
            throw error;
        });

    SSRService.addTask(fetchTask, 'updateItem');
    PromiseStore.set(fetchTask, 'updateItem', eventId, itemId);

    return fetchTask;
};

export const deleteItem = (eventId: number, itemId: number): AppThunkAction => (dispatch, getState) => {
    const state = getState();

    const request = state.checklists.itemDeleteRequests[itemId];
    if (request) {
        return PromiseStore.get('deleteItem', eventId, itemId);
    }

    dispatch(createDeleteItemRequestAction(itemId));

    const fetchTask = ChecklistApi
        .removeItem(eventId, {
            dinnerId: eventId,
            id: itemId,
        })
        .then(() => {
            const checklist = Selectors.getJsonChecklist(eventId, state);
            if (checklist && checklist.checklistItems) {
                checklist.checklistItems = checklist.checklistItems.filter((item) => item.id !== itemId);
                const normalizedData = normalize(checklist, ApiSchema.JsonChecklistSchema);
                dispatch(mergeEntities(normalizedData.entities));
            }

            dispatch(removeEntities(ApiSchema.ChecklistItemSchema, [itemId]));
            dispatch(createDeleteItemRequestSuccessAction(eventId, itemId));
        })
        .catch((error: Error) => {
            dispatch(createDeleteItemRequestFailureAction(itemId));
            Logger.logError(error);
            throw error;
        });

    SSRService.addTask(fetchTask, 'deleteItem');
    PromiseStore.set(fetchTask, 'deleteItem', eventId, itemId);

    return fetchTask;
};

export const getChecklistTemplates = (): AppThunkAction => (dispatch, getState) => {
    const state = getState();

    const request = state.checklists.templates;
    if (request && (request.isFetching || !request.didInvalidate)) {
        return PromiseStore.get('getChecklistTemplates');
    }

    dispatch(createGetChecklistTemplatesAction());

    const fetchTask = ChecklistApi
        .getAllChecklistTemplates()
        .then((data) => {
            const normalizedData = normalize(data, ApiSchema.JsonChecklistTemplateSchemaArray);
            dispatch(mergeEntities(normalizedData.entities));
            dispatch(createGetChecklistTemplatesSuccessAction(normalizedData.result));
        })
        .catch((error: Error) => {
            dispatch(createGetChecklistTemplatesFailureAction());
            Logger.logError(error);
            throw error;
        });

    SSRService.addTask(fetchTask, 'getChecklistTemplates');
    PromiseStore.set(fetchTask, 'getChecklistTemplates');

    return fetchTask;
};

export const getChecklistTemplateItems = (checklistTemplateId: number): AppThunkAction => (dispatch, getState) => {
    const state = getState();

    const request = state.checklists.templates.templateItemsRequests[checklistTemplateId];
    if (request) {
        return PromiseStore.get('getChecklistTemplate', checklistTemplateId);
    }

    dispatch(createGetChecklistTemplateItemsAction(checklistTemplateId));

    const fetchTask = ChecklistApi
        .getChecklistTemplateItems(checklistTemplateId)
        .then((data) => {
            const normalizedData = normalize(data, ApiSchema.JsonChecklistTemplateItemSchemaArray);
            dispatch(mergeEntities(normalizedData.entities));
            dispatch(createGetChecklistTemplateItemsSuccessAction(checklistTemplateId, normalizedData.result));
        })
        .catch((error: Error) => {
            dispatch(createGetChecklistTemplateItemsFailureAction(checklistTemplateId));
            Logger.logError(error);
            throw error;
        });

    SSRService.addTask(fetchTask, 'getChecklistTemplate');
    PromiseStore.set(fetchTask, 'getChecklistTemplate', checklistTemplateId);

    return fetchTask;
};

export const addFromTemplate = (eventId: number, items: JsonChecklistTemplateItem[], eventDate?: Date): AppThunkAction => (dispatch, getState) => {
    const state = getState();

    if (state.checklists.templates.isAddingTemplatedItemsRequest) {
        return PromiseStore.get('addFromTemplate', eventId);
    }

    dispatch(createAddItemsFromTemplateAction());

    const fetchTask = ChecklistApi
        .addFromTemplate(eventId, {
            dinnerId: eventId,
            eventDate,
            checkListTemplateItems: items,
        })
        .then(() => {
            dispatch(createAddItemsFromTemplateSuccessAction(eventId));
        })
        .catch((error: Error) => {
            dispatch(createAddItemsFromTemplateFailureAction());
            Logger.logError(error);
            throw error;
        });

    SSRService.addTask(fetchTask, 'addFromTemplate');
    PromiseStore.set(fetchTask, 'addFromTemplate', eventId);

    return fetchTask;
};
