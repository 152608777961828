import { faRedo } from '@fortawesome/free-solid-svg-icons/faRedo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { boundMethod } from 'autobind-decorator';
import { RoutePaths } from 'components/Routes/RoutePaths';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Logger } from 'services/Logger';

interface IProps {
    className?: string;
    children: React.ReactNode;
}

interface IState {
    hasError: boolean;
}

export class ErrorBoundaryComponent extends React.Component<IProps & WithTranslation, IState> {
    public static getDerivedStateFromError() {
        return { hasError: true };
    }

    constructor(props: IProps & WithTranslation) {
        super(props);
        this.state = {
            hasError: false,
        };
    }

    public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        Logger.logError(error, errorInfo.componentStack);
    }

    public render() {
        const { t, className } = this.props;
        if (this.state.hasError) {
            return (
                <div className={className}>
                    <div className="alert alert-warning flex-grow-1 flex-shrink-1" role="alert">
                        <h4 className="alert-heading">
                            Something went wrong!
                        </h4>
                        <p>
                            Sorry for the inconvenience. You can refresh the display by hitting the button below.
                        </p>
                        <p>
                            If the issue persists <a className="alert-link" href={RoutePaths.Contact.url()}>contact us</a> or retry later.
                        </p>
                        <hr />
                        <p className="mb-0 d-flex justify-content-center">
                            <button className="btn btn-success" onClick={this.handleRefresh}>
                                <FontAwesomeIcon icon={faRedo} /> {t('Common:Refresh')}
                            </button>
                        </p>
                    </div>
                </div>
            );
        }

        return this.props.children;
    }

    @boundMethod
    private handleRefresh() {
        this.setState({
            hasError: false,
        });
    }
}

export const ErrorBoundary = withTranslation()(ErrorBoundaryComponent);
