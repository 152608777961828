
import { AppReducer } from 'store';

export interface ITemplateRequestsMap {
    readonly [template: string]: IPrintsRequestsMap;
}

export interface IPrintsRequestsMap {
    readonly [exportType: string]: IPrintRequest;
}

export interface IPrintRequest {
    readonly isFetching: boolean;
}


const unloadedState = () => {
    return {
        events: {} as { readonly [eventId: number]: ITemplateRequestsMap },
    } as const;
};

export type PrintsState = ReturnType<typeof unloadedState>;

export const PrintsReducer: AppReducer<PrintsState> = (state = unloadedState(), action) => {
    switch (action.type) {
        case '@PRINTS/PRINT_REQUEST':
            return {
                ...state,
                events: {
                    ...state.events,
                    [action.eventId]: {
                        ...state.events[action.eventId],
                        [action.template]: {
                            ...(state.events[action.eventId] && state.events[action.eventId][action.template]),
                            [action.exportType]: {
                                ...(state.events[action.eventId] && state.events[action.eventId][action.template] && state.events[action.eventId][action.template][action.exportType]),
                                isFetching: true,
                            },
                        },
                    },
                },
            };

        case '@PRINTS/PRINT_SUCCESS':
            return {
                ...state,
                events: {
                    ...state.events,
                    [action.eventId]: {
                        ...state.events[action.eventId],
                        [action.template]: {
                            ...(state.events[action.eventId] && state.events[action.eventId][action.template]),
                            [action.exportType]: {
                                ...(state.events[action.eventId] && state.events[action.eventId][action.template] && state.events[action.eventId][action.template][action.exportType]),
                                isFetching: false,
                            },
                        },
                    },
                },
            };

        case '@PRINTS/PRINT_FAILURE':
            return {
                ...state,
                events: {
                    ...state.events,
                    [action.eventId]: {
                        ...state.events[action.eventId],
                        [action.template]: {
                            ...(state.events[action.eventId] && state.events[action.eventId][action.template]),
                            [action.exportType]: {
                                ...(state.events[action.eventId] && state.events[action.eventId][action.template] && state.events[action.eventId][action.template][action.exportType]),
                                isFetching: false,
                            },
                        },
                    },
                },
            };

        default:
            return state;
    }
};
