export const createGetAppContextRequestAction = () => {
    return {
        type: '@APP_CONTEXT/GET_APP_CONTEXT_REQUEST',
    } as const;
};

export const createGetAppContextRequestSuccessAction = (appContextId: string) => {
    return {
        type: '@APP_CONTEXT/GET_APP_CONTEXT_SUCCESS',
        appContextId,
    } as const;
};

export const createGetAppContextRequestFailureAction = () => {
    return {
        type: '@APP_CONTEXT/GET_APP_CONTEXT_FAILURE',
    } as const;
};

export const createGetAppContextRequestInvalidationAction = () => {
    return {
        type: '@APP_CONTEXT/GET_APP_CONTEXT_INVALIDATION',
    } as const;
};

export const createConsentCookieRequestAction = () => {
    return {
        type: '@APP_CONTEXT/CONSENT_COOKIE_REQUEST',
    } as const;
};

export const createConsentCookieSuccessAction = () => {
    return {
        type: '@APP_CONTEXT/CONSENT_COOKIE_SUCCESS',
    } as const;
};

export const createConsentCookieFailureAction = () => {
    return {
        type: '@APP_CONTEXT/CONSENT_COOKIE_FAILURE',
    } as const;
};

export const createChangeLanguageRequestAction = () => {
    return {
        type: '@APP_CONTEXT/CHANGE_LANGUAGE_REQUEST',
    } as const;
};

export const createChangeLanguageRequestSuccessAction = () => {
    return {
        type: '@APP_CONTEXT/CHANGE_LANGUAGE_SUCCESS',
    } as const;
};

export const createChangeLanguageRequestFailureAction = () => {
    return {
        type: '@APP_CONTEXT/CHANGE_LANGUAGE_FAILURE',
    } as const;
};

export type AppContextAction =
    | ReturnType<typeof createGetAppContextRequestAction>
    | ReturnType<typeof createGetAppContextRequestSuccessAction>
    | ReturnType<typeof createGetAppContextRequestFailureAction>
    | ReturnType<typeof createGetAppContextRequestInvalidationAction>
    | ReturnType<typeof createConsentCookieRequestAction>
    | ReturnType<typeof createConsentCookieSuccessAction>
    | ReturnType<typeof createConsentCookieFailureAction>
    | ReturnType<typeof createChangeLanguageRequestAction>
    | ReturnType<typeof createChangeLanguageRequestSuccessAction>
    | ReturnType<typeof createChangeLanguageRequestFailureAction>;
