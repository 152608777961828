import i18next from 'i18next';
import { AppReducer } from 'store';
import { SignalRService } from 'services/SignalRService';
import { matchPath, match } from 'react-router-dom';

export interface IEventGroupsUsersMap {
    readonly [eventId: number]: IGroupUsers;
}

export interface IGroupUsers {
    readonly users: ISignalRUser[];
}

export interface ISignalRUser {
    readonly userName?: string;
    readonly userIdentifier?: string;
}

const unloadedState = () => {
    return {
        currentEventId: undefined as number | undefined,
        currentCustomUrl: undefined as string | undefined,
        eventGroupsUsers: {} as IEventGroupsUsersMap,
    } as const;
};

interface IEventRouteById {
    lang: string;
    eventId: string;
    route: string;
}

interface IEventRouteByCustomUrl {
    lang: string;
    customUrl: string;
    route: string;
}

export type SignalRState = ReturnType<typeof unloadedState>;

export const SignalRReducer: AppReducer<SignalRState> = (state = unloadedState(), action) => {
    let matchEventId: match<IEventRouteById> | null;
    let matchCustomUrl: match<IEventRouteByCustomUrl> | null;
    switch (action.type) {
        case '@SIGNALR/EVENT_GROUP_USER_LIST_UPDATED':
            return {
                ...state,
                eventGroupsUsers: {
                    ...state.eventGroupsUsers,
                    [action.payload.eventId]: {
                        ...state.eventGroupsUsers[action.payload.eventId],
                        users: action.payload.users,
                    },
                },
            };

        case '@@router/LOCATION_CHANGE':
            matchEventId = matchPath<IEventRouteById>(
                action.payload.location.pathname,
                { path: `/:lang([a-zA-Z]{2})/${i18next.t('Routing:Events')}/:eventId(\\d+)/:route*` });

            matchCustomUrl = matchPath<IEventRouteByCustomUrl>(
                action.payload.location.pathname,
                { path: `/:lang([a-zA-Z]{2})/public/:customUrl/:route*` });

            if (matchEventId && matchEventId.params.eventId) {
                if (state.currentEventId !== parseInt(matchEventId.params.eventId)) {
                    SignalRService.Instance.invoke('AddToEventGroup', parseInt(matchEventId.params.eventId));
                }
            } else if (matchCustomUrl && matchCustomUrl.params.customUrl) {
                if (state.currentCustomUrl !== matchCustomUrl.params.customUrl) {
                    SignalRService.Instance.invoke('AddToEventGroupByCustomUrl', matchCustomUrl.params.customUrl);
                }
            } else {
                if (state.currentEventId) {
                    SignalRService.Instance.invoke('RemoveFromEventGroup', state.currentEventId);
                }
                if (state.currentCustomUrl) {
                    SignalRService.Instance.invoke('RemoveFromEventGroupByCustomUrl', state.currentCustomUrl);
                }
            }

            return {
                ...state,
                currentEventId: matchEventId && matchEventId.params.eventId && parseInt(matchEventId.params.eventId) || undefined,
                currentCustomUrl: matchCustomUrl && matchCustomUrl.params.customUrl || undefined,
            };

        default:
            return state;
    }
};
