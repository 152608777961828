import { debounce, memoize } from 'lodash-es';
import { normalize } from 'normalizr';
import { SeatingPlanApi } from 'services/api';
import { Chair, ObjectFitType, Right, SeatingPlanConfiguration, Table, TableFitType, TableSaveResult } from 'services/api/ApiClient';
import { ApiSchema } from 'services/api/ApiSchema';
import { Logger } from 'services/Logger';
import { PromiseStore } from 'services/PromiseStore';
import { SSRService } from 'services/ServerSideRenderingService';
import { addChair, findAvailableSpace, getAverageChairDiameter, reorderChairs, rotateChairs } from 'services/TableService';
import { AppThunkAction, AppThunkDispatch } from 'store';
import { getConstraintReport } from 'store/constraintReports/thunk';
import { mergeEntities, removeEntities } from 'store/normalizr/actions';
import { Selectors } from 'store/normalizr/selectors';
import { hasRight, getRights } from 'store/rights/thunk';
import {
    createCreateObjectAction,
    createCreateObjectFailureAction,
    createCreateObjectSuccessAction,
    createCreateTableAction,
    createCreateTableFailureAction,
    createCreateTableSuccessAction,
    createDeleteObjectAction,
    createDeleteObjectFailureAction,
    createDeleteObjectSuccessAction,
    createDeleteTableAction,
    createDeleteTableFailureAction,
    createDeleteTableSuccessAction,
    createDeleteTableModifySuccessAction,
    createGetObjectsAction,
    createGetObjectsFailureAction,
    createGetObjectsSuccessAction,
    createGetSeatingPlanConfigurationAction,
    createGetSeatingPlanConfigurationFailureAction,
    createGetSeatingPlanConfigurationSuccessAction,
    createGetTablesAction,
    createGetTablesFailureAction,
    createGetTablesSuccessAction,
    createUpdateObjectAction,
    createUpdateObjectFailureAction,
    createUpdateObjectSuccessAction,
    createUpdateSeatingPlanConfigurationAction,
    createUpdateSeatingPlanConfigurationFailureAction,
    createUpdateSeatingPlanConfigurationSuccessAction,
    createUpdateTableAction,
    createUpdateTableFailureAction,
    createUpdateTableSuccessAction,
    createUpdateTableModifySuccessAction,
    createCreateTableModifySuccessAction,
    createCreateObjectModifySuccessAction,
    createUpdateObjectModifySuccessAction,
    createDeleteObjectModifySuccessAction,
} from './actions';

export const getSeatingPlanConfiguration = (eventId: number): AppThunkAction => (dispatch, getState) => {
    const state = getState();

    const request = state.seatingPlans.configurationRequests[eventId];
    if (request && (request.isFetching || !request.didInvalidate)) {
        return PromiseStore.get('getSeatingPlanConfiguration', eventId);
    }

    dispatch(createGetSeatingPlanConfigurationAction(eventId));

    const fetchTask = SeatingPlanApi
        .getSeatingPlanConfiguration(eventId)
        .then((data) => {
            const normalizedData = normalize(data, ApiSchema.SeatingPlanConfigurationSchema);
            dispatch(mergeEntities(normalizedData.entities));
            dispatch(createGetSeatingPlanConfigurationSuccessAction(eventId));
        })
        .catch((error: Error) => {
            dispatch(createGetSeatingPlanConfigurationFailureAction(eventId));
            Logger.logError(error);
            throw error;
        });

    SSRService.addTask(fetchTask, 'getSeatingPlanConfiguration');
    PromiseStore.set(fetchTask, 'getSeatingPlanConfiguration', eventId);

    return fetchTask;
};

export const isEmpty = (eventId: number): AppThunkAction<boolean> => (dispatch, getState) => {
    const state = getState();

    const tables = state.seatingPlans.fetchTablesRequests[eventId]?.tables || [];
    const objects = state.seatingPlans.fetchObjectsRequests[eventId]?.objects || [];
    return Promise.resolve(tables.length == 0 && objects.length == 0);
};

export const getTables = (eventId: number): AppThunkAction => (dispatch, getState) => {
    const state = getState();

    const request = state.seatingPlans.fetchTablesRequests[eventId];
    if (request && (request.isFetching || !request.didInvalidate)) {
        return PromiseStore.get('getTables', eventId);
    }

    dispatch(createGetTablesAction(eventId));

    const fetchTask = SeatingPlanApi
        .getTables(eventId)
        .then((data) => {
            const normalizedData = normalize(data, ApiSchema.TableSchemaArray);
            dispatch(mergeEntities(normalizedData.entities));
            dispatch(createGetTablesSuccessAction(eventId, normalizedData.result));
        })
        .catch((error: Error) => {
            dispatch(createGetTablesFailureAction(eventId));
            Logger.logError(error);
            throw error;
        });

    SSRService.addTask(fetchTask, 'getTables');
    PromiseStore.set(fetchTask, 'getTables', eventId);

    return fetchTask;
};

export const getObjects = (eventId: number): AppThunkAction => (dispatch, getState) => {
    const state = getState();

    const request = state.seatingPlans.fetchObjectsRequests[eventId];
    if (request && (request.isFetching || !request.didInvalidate)) {
        return PromiseStore.get('getObjects', eventId);
    }

    dispatch(createGetObjectsAction(eventId));

    const fetchTask = SeatingPlanApi
        .getObjects(eventId)
        .then((data) => {
            const normalizedData = normalize(data, ApiSchema.PlanObjectSchemaArray);
            dispatch(mergeEntities(normalizedData.entities));
            dispatch(createGetObjectsSuccessAction(eventId, normalizedData.result));
        })
        .catch((error: Error) => {
            dispatch(createGetObjectsFailureAction(eventId));
            Logger.logError(error);
            throw error;
        });

    SSRService.addTask(fetchTask, 'getObjects');
    PromiseStore.set(fetchTask, 'getObjects', eventId);

    return fetchTask;
};

export const updateSeatingPlanScale = (eventId: number, scale: number): AppThunkAction => (dispatch, getState) => {
    const state = getState();
    const oldConfiguration = Selectors.getSeatingPlanConfiguration(eventId, state);
    if (oldConfiguration) {
        const newConfiguration = {
            ...oldConfiguration,
            scale: Math.round(scale * 100) / 100,
        };
        const normalizedData = normalize(newConfiguration, ApiSchema.SeatingPlanConfigurationSchema);
        dispatch(mergeEntities(normalizedData.entities));

        debouncedUpdateConfiguration(dispatch, eventId);
    }
    return Promise.resolve();
};

export const updateSeatingPlanDisplayGuests = (eventId: number, displayGuests: boolean): AppThunkAction => (dispatch, getState) => {
    const state = getState();
    const oldConfiguration = Selectors.getSeatingPlanConfiguration(eventId, state);
    if (oldConfiguration) {
        const newConfiguration = {
            ...oldConfiguration,
            displayGuests,
        };
        const normalizedData = normalize(newConfiguration, ApiSchema.SeatingPlanConfigurationSchema);
        dispatch(mergeEntities(normalizedData.entities));
    }
    return Promise.resolve();
};

export const updateSeatingPlanAreaWidth = (eventId: number, areaWidth: number): AppThunkAction => (dispatch, getState) => {
    const state = getState();
    const oldConfiguration = Selectors.getSeatingPlanConfiguration(eventId, state);
    if (oldConfiguration) {
        const newConfiguration: SeatingPlanConfiguration = {
            ...oldConfiguration,
            areaWidth,
        };
        const normalizedData = normalize(newConfiguration, ApiSchema.SeatingPlanConfigurationSchema);
        dispatch(mergeEntities(normalizedData.entities));

        debouncedUpdateConfiguration(dispatch, eventId);
    }
    return Promise.resolve();
};

export const updateSeatingPlanAreaHeight = (eventId: number, areaHeight: number): AppThunkAction => (dispatch, getState) => {
    const state = getState();
    const oldConfiguration = Selectors.getSeatingPlanConfiguration(eventId, state);
    if (oldConfiguration) {
        const newConfiguration: SeatingPlanConfiguration = {
            ...oldConfiguration,
            areaHeight,
        };
        const normalizedData = normalize(newConfiguration, ApiSchema.SeatingPlanConfigurationSchema);
        dispatch(mergeEntities(normalizedData.entities));

        debouncedUpdateConfiguration(dispatch, eventId);
    }
    return Promise.resolve();
};

export const updateDefaultObjectColor = (eventId: number, defaultObjectColor: string): AppThunkAction => (dispatch, getState) => {
    const state = getState();
    const oldConfiguration = Selectors.getSeatingPlanConfiguration(eventId, state);
    if (oldConfiguration) {
        const newConfiguration: SeatingPlanConfiguration = {
            ...oldConfiguration,
            defaultColorObject: defaultObjectColor,
        };
        const normalizedData = normalize(newConfiguration, ApiSchema.SeatingPlanConfigurationSchema);
        dispatch(mergeEntities(normalizedData.entities));

        debouncedUpdateConfiguration(dispatch, eventId);
    }
    return Promise.resolve();
};

export const updateDefaultChairColor = (eventId: number, defaultChairColor: string): AppThunkAction => (dispatch, getState) => {
    const state = getState();
    const oldConfiguration = Selectors.getSeatingPlanConfiguration(eventId, state);
    if (oldConfiguration) {
        const newConfiguration: SeatingPlanConfiguration = {
            ...oldConfiguration,
            defaultColorChair: defaultChairColor,
        };
        const normalizedData = normalize(newConfiguration, ApiSchema.SeatingPlanConfigurationSchema);
        dispatch(mergeEntities(normalizedData.entities));

        debouncedUpdateConfiguration(dispatch, eventId);
    }
    return Promise.resolve();
};

export const updateDefaultTableColor = (eventId: number, defaultTableColor: string): AppThunkAction => (dispatch, getState) => {
    const state = getState();
    const oldConfiguration = Selectors.getSeatingPlanConfiguration(eventId, state);
    if (oldConfiguration) {
        const newConfiguration: SeatingPlanConfiguration = {
            ...oldConfiguration,
            defaultColorTable: defaultTableColor,
        };
        const normalizedData = normalize(newConfiguration, ApiSchema.SeatingPlanConfigurationSchema);
        dispatch(mergeEntities(normalizedData.entities));

        debouncedUpdateConfiguration(dispatch, eventId);
    }
    return Promise.resolve();
};

export const updateDefaultGuestColor = (eventId: number, defaultGuestColor: string): AppThunkAction => (dispatch, getState) => {
    const state = getState();
    const oldConfiguration = Selectors.getSeatingPlanConfiguration(eventId, state);
    if (oldConfiguration) {
        const newConfiguration: SeatingPlanConfiguration = {
            ...oldConfiguration,
            defaultColorGuest: defaultGuestColor,
        };
        const normalizedData = normalize(newConfiguration, ApiSchema.SeatingPlanConfigurationSchema);
        dispatch(mergeEntities(normalizedData.entities));

        debouncedUpdateConfiguration(dispatch, eventId);
    }
    return Promise.resolve();
};

export const updateDefaultMaleGuestColor = (eventId: number, defaultMaleGuestColor: string): AppThunkAction => (dispatch, getState) => {
    const state = getState();
    const oldConfiguration = Selectors.getSeatingPlanConfiguration(eventId, state);
    if (oldConfiguration) {
        const newConfiguration: SeatingPlanConfiguration = {
            ...oldConfiguration,
            defaultColorGuestMale: defaultMaleGuestColor,
        };
        const normalizedData = normalize(newConfiguration, ApiSchema.SeatingPlanConfigurationSchema);
        dispatch(mergeEntities(normalizedData.entities));

        debouncedUpdateConfiguration(dispatch, eventId);
    }
    return Promise.resolve();
};

export const updateDefaultFemaleGuestColor = (eventId: number, defaultFemaleGuestColor: string): AppThunkAction => (dispatch, getState) => {
    const state = getState();
    const oldConfiguration = Selectors.getSeatingPlanConfiguration(eventId, state);
    if (oldConfiguration) {
        const newConfiguration: SeatingPlanConfiguration = {
            ...oldConfiguration,
            defaultColorGuestFemale: defaultFemaleGuestColor,
        };
        const normalizedData = normalize(newConfiguration, ApiSchema.SeatingPlanConfigurationSchema);
        dispatch(mergeEntities(normalizedData.entities));

        debouncedUpdateConfiguration(dispatch, eventId);
    }
    return Promise.resolve();
};

export const updateSeatingPlanDisplayNames = (eventId: number, displayNames: boolean): AppThunkAction => (dispatch, getState) => {
    const state = getState();
    const oldConfiguration = Selectors.getSeatingPlanConfiguration(eventId, state);
    if (oldConfiguration) {
        const newConfiguration = {
            ...oldConfiguration,
            displayGuestTags: displayNames,
        };
        const normalizedData = normalize(newConfiguration, ApiSchema.SeatingPlanConfigurationSchema);
        dispatch(mergeEntities(normalizedData.entities));

        debouncedUpdateConfiguration(dispatch, eventId);
    }
    return Promise.resolve();
};

export const updateDefaultDisplayNotSeatedGuestOnlyFilter = (eventId: number, notSeatedOnly: boolean): AppThunkAction => (dispatch, getState) => {
    const state = getState();
    const oldConfiguration = Selectors.getSeatingPlanConfiguration(eventId, state);
    if (oldConfiguration) {
        const newConfiguration = {
            ...oldConfiguration,
            displayUnseatedGuestsOnly: notSeatedOnly,
        };
        const normalizedData = normalize(newConfiguration, ApiSchema.SeatingPlanConfigurationSchema);
        dispatch(mergeEntities(normalizedData.entities));

        debouncedUpdateConfiguration(dispatch, eventId);
    }
    return Promise.resolve();
};

export const updateObjectPosition = (eventId: number, objectId: number, top: number, left: number): AppThunkAction => (dispatch, getState) => {
    const state = getState();
    const oldObject = Selectors.getPlanObject(objectId, state);
    if (oldObject) {
        const newObject = {
            ...oldObject,
            left,
            top,
        };
        const normalizedData = normalize(newObject, ApiSchema.PlanObjectSchema);
        dispatch(mergeEntities(normalizedData.entities));

        debouncedUpdateObject(dispatch, eventId, objectId);
    }
    return Promise.resolve();
};

export const updateTablePosition = (eventId: number, tableId: number, top: number, left: number): AppThunkAction => (dispatch, getState) => {
    const state = getState();
    const oldTable = Selectors.getTable(tableId, state);
    if (oldTable) {
        const newTable = {
            ...oldTable,
            left,
            top,
        };
        if (newTable.chairs) {
            const translateX = newTable.left - oldTable.left;
            const translateY = newTable.top - oldTable.top;
            newTable.chairs.forEach((chair) => {
                chair.left += translateX;
                chair.top += translateY;
            });
        }
        const normalizedData = normalize(newTable, ApiSchema.TableSchema);
        dispatch(mergeEntities(normalizedData.entities));

        debouncedUpdateTable(dispatch, eventId, tableId);
    }
    return Promise.resolve();
};

export const updateChairPosition = (eventId: number, tableId: number, chairId: number, top: number, left: number): AppThunkAction => (dispatch, getState) => {
    const state = getState();
    const oldTable = Selectors.getTable(tableId, state);
    if (oldTable) {
        const newTable = {
            ...oldTable,
        };

        const chair = newTable.chairs?.find((c) => c.id === chairId);
        if (chair) {
            chair.left = left;
            chair.top = top;
            
            const normalizedData = normalize(newTable, ApiSchema.TableSchema);
            dispatch(mergeEntities(normalizedData.entities));

            debouncedUpdateTable(dispatch, eventId, tableId);
        }
    }
    return Promise.resolve();
};

export const unseatGuest = (eventId: number, guestId: number): AppThunkAction => (dispatch, getState) => {
    const state = getState();
    const request = state.seatingPlans.fetchTablesRequests[eventId];
    const oldTables = Selectors.getTableArray(request?.tables, state);
    if (oldTables) {
        oldTables.forEach((oldTable) => {
            const newTable = {
                ...oldTable,
            };
            if (newTable.chairs) {
                const chairs = newTable.chairs.filter((c) => c.guestId === guestId);
                if (chairs.length) {
                    chairs.forEach((chair) => {
                        chair.guestId = null;
                    });
                    const normalizedData = normalize(newTable, ApiSchema.TableSchema);
                    dispatch(mergeEntities(normalizedData.entities));

                    debouncedUpdateTable(dispatch, eventId, newTable.id || 0);
                }
            }
        });
    }
    return Promise.resolve();
};

export const seatGuest = (eventId: number, chairId: number, guestId: number): AppThunkAction => (dispatch, getState) => {
    const state = getState();
    const request = state.seatingPlans.fetchTablesRequests[eventId];
    const oldTables = Selectors.getTableArray(request.tables, state);
    if (oldTables) {
        oldTables.forEach((oldTable) => {
            const newTable = {
                ...oldTable,
            };
            if (newTable.chairs) {
                const chairs = newTable.chairs.filter((c) => c.guestId === guestId);
                if (chairs.length) {
                    chairs.forEach((c) => {
                        c.guestId = null;
                    });
                }
                const chair = newTable.chairs.find((c) => c.id === chairId);
                if (chair) {
                    chair.guestId = guestId;
                }

                if (chairs.length || chair) {
                    const normalizedData = normalize(newTable, ApiSchema.TableSchema);
                    dispatch(mergeEntities(normalizedData.entities));

                    debouncedUpdateTable(dispatch, eventId, newTable.id || 0);
                }
            }
        });
    }
    return Promise.resolve();
};

export interface ICreateTable {
    name?: string | null;
    color?: string | null;
    fit: TableFitType;
    isLocked: boolean;
    width: number;
    height: number;
    numberOfChairs: number;
    left?: number;
    top?: number;
    angle?: number;
}

const getFrontFakeId = () => {
    return Date.now()%1000000;
}

export interface IModifyOrUpdateResult {
    id: number;
    noUpdate: boolean;
}

export const createTable = (eventId: number, tableOptions: ICreateTable): AppThunkAction<IModifyOrUpdateResult> => (dispatch, getState) => {
    return dispatch(getRights(eventId)).then(() => {
        const state = getState();
        if (!hasRight(state, eventId, Right.SeatingPlanModify)) {
            return Promise.reject('Unauthorized!');
        }

        dispatch(createCreateTableAction(eventId));

        const chairs: Chair[] = [];
        for (let i = 0; i < tableOptions.numberOfChairs; i++) {
            const chair: Chair = {
                width: .5,
                height: .5,
                number: i + 1,
                left: 0,
                top: 0,
                zoom: 100,
            };
            chairs.push(chair);
        }

        const table = {
            left: tableOptions.width * 100 / 2 + 50,
            top: tableOptions.height * 100 / 2 + 50,
            angle: 0,
            zoom: 100,
            numberOfFreeChairs: tableOptions.numberOfChairs,
            ...tableOptions,
            chairs,
            id: undefined,
        };

        if (!tableOptions.left || !tableOptions.top) {
            const configuration = Selectors.getSeatingPlanConfiguration(eventId, state);
            if (configuration) {
                const tablesRequest = state.seatingPlans.fetchTablesRequests[eventId];
                const tables = Selectors.getTableArray(tablesRequest.tables, state);
                const objectsRequest = state.seatingPlans.fetchObjectsRequests[eventId];
                const objects = Selectors.getPlanObjectArray(objectsRequest.objects, state);
                const availableSpace = findAvailableSpace(
                    configuration.areaWidth * 100,
                    configuration.areaHeight * 100,
                    [...tables.map((t) => ({ ...t, margin: getAverageChairDiameter(t.chairs) * 100 })), ...objects.map((o) => ({ ...o, margin: 0 }))],
                    { ...table, margin: getAverageChairDiameter(table.chairs) * 100 },
                );
                table.left = availableSpace.x;
                table.top = availableSpace.y;
            }
        }

        const tableWithReorderedChairs = reorderChairs(table);

        if (hasRight(state, eventId, Right.SeatingPlanUpdate)) {
            const fetchTask = SeatingPlanApi
            .updateTable(eventId, tableWithReorderedChairs)
            .then((result) => {
                const normalizedData = normalize(result.data, ApiSchema.TableSchema);
                dispatch(mergeEntities(normalizedData.entities));
                dispatch(createCreateTableSuccessAction(eventId, result.id));
                return {id:result.id,noUpdate:false};
            })
            .catch((error: Error) => {
                dispatch(createCreateTableFailureAction(eventId));
                Logger.logError(error);
                throw error;
            });
            return fetchTask;
        }else{
            const tableId:number = getFrontFakeId();
            tableWithReorderedChairs.id = tableId;
            tableWithReorderedChairs.chairs?.forEach((c,i) => {
                c.id = tableId+i;
                c.tableId = tableId;
            });
            const normalizedData = normalize(tableWithReorderedChairs, ApiSchema.TableSchema);
            dispatch(mergeEntities(normalizedData.entities));
            dispatch(createCreateTableModifySuccessAction(eventId, tableId));
            return Promise.resolve({id:tableId, noUpdate:true});
        }
    });
};

export const duplicateTable = (eventId: number, tableId: number): AppThunkAction<IModifyOrUpdateResult> => (dispatch, getState) => {
    const state = getState();
    const table = Selectors.getTable(tableId, state);
    if (!table) {
        Logger.logError(new Error(`Cannot find the table to duplicate: eventId:${eventId}, tableId:${tableId}`));
        return Promise.reject('Cannot find the table to duplicate!');
    }
    return dispatch(createTable(eventId, {
        ...table,
        numberOfChairs: table.chairs ? table.chairs.length : 0,
        left: undefined,
        top: undefined,
    }));
};

export const deleteTable = (eventId: number, tableId: number): AppThunkAction => (dispatch, getState) => {
    return dispatch(getRights(eventId)).then(() => {
        const state = getState();
        dispatch(createDeleteTableAction(eventId, tableId));
        if (hasRight(state, eventId, Right.SeatingPlanUpdate)) {
            const fetchTask = SeatingPlanApi
                .removeTable(eventId, tableId)
                .then(() => {
                    dispatch(createDeleteTableSuccessAction(eventId, tableId));
                    dispatch(removeEntities(ApiSchema.TableSchema, [tableId]));
                    dispatch(getConstraintReport(eventId));
                })
                .catch((error: Error) => {
                    dispatch(createDeleteTableFailureAction(eventId, tableId));
                    Logger.logError(error);
                    throw error;
                });
            return fetchTask;
        }else{
            dispatch(createDeleteTableModifySuccessAction(eventId, tableId));
            dispatch(removeEntities(ApiSchema.TableSchema, [tableId]));
            return Promise.resolve();
        }
    });
};

export const updateTableName = (eventId: number, tableId: number, name?: string): AppThunkAction => (dispatch, getState) => {
    const state = getState();
    const oldTable = Selectors.getTable(tableId, state);
    if (oldTable) {
        const newTable = {
            ...oldTable,
            name,
        };
        const normalizedData = normalize(newTable, ApiSchema.TableSchema);
        dispatch(mergeEntities(normalizedData.entities));

        debouncedUpdateTable(dispatch, eventId, tableId);
    }
    return Promise.resolve();
};

export const updateTableFit = (eventId: number, tableId: number, fit: TableFitType): AppThunkAction => (dispatch, getState) => {
    const state = getState();
    const oldTable = Selectors.getTable(tableId, state);
    if (oldTable) {
        const newTable = {
            ...oldTable,
            fit,
        };
        const normalizedData = normalize(newTable, ApiSchema.TableSchema);
        dispatch(mergeEntities(normalizedData.entities));

        debouncedUpdateTable(dispatch, eventId, tableId);
    }
    return Promise.resolve();
};

export const updateTableColor = (eventId: number, tableId: number, color?: string): AppThunkAction => (dispatch, getState) => {
    const state = getState();
    const oldTable = Selectors.getTable(tableId, state);
    if (oldTable) {
        const newTable = {
            ...oldTable,
            color,
        };
        const normalizedData = normalize(newTable, ApiSchema.TableSchema);
        dispatch(mergeEntities(normalizedData.entities));

        debouncedUpdateTable(dispatch, eventId, tableId);
    }
    return Promise.resolve();
};

export const updateTableWidth = (eventId: number, tableId: number, width: number): AppThunkAction => (dispatch, getState) => {
    const state = getState();
    const oldTable = Selectors.getTable(tableId, state);
    if (oldTable) {
        const newTable = {
            ...oldTable,
            width,
        };
        const normalizedData = normalize(newTable, ApiSchema.TableSchema);
        dispatch(mergeEntities(normalizedData.entities));

        debouncedUpdateTable(dispatch, eventId, tableId);
    }
    return Promise.resolve();
};

export const updateTableHeight = (eventId: number, tableId: number, height: number): AppThunkAction => (dispatch, getState) => {
    const state = getState();
    const oldTable = Selectors.getTable(tableId, state);
    if (oldTable) {
        const newTable = {
            ...oldTable,
            height,
        };
        const normalizedData = normalize(newTable, ApiSchema.TableSchema);
        dispatch(mergeEntities(normalizedData.entities));

        debouncedUpdateTable(dispatch, eventId, tableId);
    }
    return Promise.resolve();
};

export const updateTableAngle = (eventId: number, tableId: number, angle: number): AppThunkAction => (dispatch, getState) => {
    const state = getState();
    const oldTable = Selectors.getTable(tableId, state);
    if (oldTable) {
        const chairs = rotateChairs(oldTable.chairs || [], angle - oldTable.angle, { x: oldTable.left, y: oldTable.top });
        const newTable = {
            ...oldTable,
            angle,
            chairs,
        };

        const normalizedData = normalize(newTable, ApiSchema.TableSchema);
        dispatch(mergeEntities(normalizedData.entities));

        debouncedUpdateTable(dispatch, eventId, tableId);
    }
    return Promise.resolve();
};

export const updateTableLock = (eventId: number, tableId: number, isLocked: boolean): AppThunkAction => (dispatch, getState) => {
    const state = getState();
    const oldTable = Selectors.getTable(tableId, state);
    if (oldTable) {
        const newTable = {
            ...oldTable,
            isLocked,
        };
        const normalizedData = normalize(newTable, ApiSchema.TableSchema);
        dispatch(mergeEntities(normalizedData.entities));

        debouncedUpdateTable(dispatch, eventId, tableId);
    }
    return Promise.resolve();
};

export const reorderTableChairs = (eventId: number, tableId: number): AppThunkAction => (dispatch, getState) => {
    const state = getState();
    const oldTable = Selectors.getTable(tableId, state);
    if (oldTable) {
        const newTable = reorderChairs(oldTable);
        const normalizedData = normalize(newTable, ApiSchema.TableSchema);
        dispatch(mergeEntities(normalizedData.entities));

        debouncedUpdateTable(dispatch, eventId, tableId);
    }
    return Promise.resolve();
};

export const updateTableNumberOfChairs = (eventId: number, tableId: number, numberOfChairs: number): AppThunkAction => (dispatch, getState) => {
    const state = getState();
    const oldTable = Selectors.getTable(tableId, state);
    if (oldTable) {
        const chairs = oldTable.chairs ? [...oldTable.chairs].sort((chairA, chairB) => {
            return (chairA.number < chairB.number) ? 1 : (chairA.number > chairB.number) ? -1 : 0;
        }) : [];
        if (chairs.length > numberOfChairs) {
            while (chairs.length > numberOfChairs) {
                const emptyChairIndex = chairs.findIndex((chair) => !chair.guestId);
                if (emptyChairIndex >= 0) {
                    const emptyChair = chairs[emptyChairIndex];
                    chairs.splice(emptyChairIndex, 1);
                    const chairsToChangeNumber = chairs.filter((chair) => chair.number > emptyChair.number);
                    chairsToChangeNumber.forEach((chair) => {
                        chair.number -= 1;
                    });
                } else {
                    chairs.splice(chairs.length - 1, 1);
                }
            }

            const newTable = {
                ...oldTable,
                chairs,
            };

            const normalizedData = normalize(newTable, ApiSchema.TableSchema);
            dispatch(mergeEntities(normalizedData.entities));

            debouncedUpdateTable(dispatch, eventId, newTable.id || 0);
        } else if (chairs.length < numberOfChairs) {
            let newTable: Table = {
                ...oldTable,
                chairs,
            };
            for (let i = chairs.length; i < numberOfChairs; i++) {
                const chair: Chair = {
                    width: .5,
                    height: .5,
                    number: i + 1,
                    left: 0,
                    top: 0,
                    zoom: 100,
                };
                newTable = addChair(newTable, chair);
            }
            dispatch(updateTable(eventId, newTable))
                .then((result) => {
                    const updatedTable = {
                        ...oldTable,
                        ...result.data,
                    };

                    const normalizedData = normalize(updatedTable, ApiSchema.TableSchema);
                    dispatch(mergeEntities(normalizedData.entities));
                });
        }
    }
    return Promise.resolve();
};

const updateTableById = (eventId: number, tableId: number): AppThunkAction<TableSaveResult> => (dispatch, getState) => {
    const state = getState();
    const table = Selectors.getTable(tableId, state);
    if (!table) {
        return Promise.reject('Table not found!');
    }
    return dispatch(updateTable(eventId, table));
};

export const updateTable = (eventId: number, table: Table): AppThunkAction<TableSaveResult> => (dispatch, getState) => {
    return dispatch(getRights(eventId)).then(() => {
        const state = getState();

        if (!hasRight(state, eventId, Right.SeatingPlanModify)) {
            return Promise.reject('Unauthorized!');
        }

        if (table && table.id) {
            const tableId = table.id;
            dispatch(createUpdateTableAction(eventId, tableId));
            if (hasRight(state, eventId, Right.SeatingPlanUpdate)) {
                const fetchTask = SeatingPlanApi
                    .updateTable(eventId, table)
                    .then((result) => {
                        dispatch(createUpdateTableSuccessAction(eventId, tableId));
                        return result;
                    })
                    .catch((error: Error) => {
                        dispatch(createUpdateTableFailureAction(eventId, tableId));
                        Logger.logError(error);
                        throw error;
                    });
                return fetchTask;
            }else{
                dispatch(createUpdateTableModifySuccessAction(eventId, tableId));
                const baseId:number = getFrontFakeId();
                table.chairs?.forEach((c,i) => {
                    if(!c.tableId){
                        c.tableId = tableId;
                        c.id = baseId+i;
                    }
                })
                const result = {
                    ...table,
                    numberOfChairs: table.chairs?.length,
                    numberOfFreeChairs: table.chairs?.filter(c => !c.guestId).length || table.numberOfFreeChairs,
                }
                return Promise.resolve({success:true, id:tableId, data:result});
            }
        }
        return Promise.reject('Table not found!');
    });
};

const memoizeDebouncedUpdateTables = memoize((tableId: number) => {
    return debounce((dispatch: AppThunkDispatch, eventId: number) => {
        dispatch(updateTableById(eventId, tableId)).catch(void 0);
    }, 300);
});

const debouncedUpdateTable = (dispatch: AppThunkDispatch, eventId: number, tableId: number) => {
    return memoizeDebouncedUpdateTables(tableId)(dispatch, eventId);
};

export interface ICreateObject {
    name?: string | null;
    color?: string | null;
    fit: ObjectFitType;
    width: number;
    height: number;
    left?: number;
    top?: number;
    angle?: number;
}

export const createObject = (eventId: number, objectOptions: ICreateObject): AppThunkAction<IModifyOrUpdateResult> => (dispatch, getState) => {
    return dispatch(getRights(eventId)).then(() => {
        const state = getState();
        dispatch(createCreateObjectAction(eventId));

        const object = {
            left: objectOptions.width * 100 / 2 + 50,
            top: objectOptions.height * 100 / 2 + 50,
            angle: 0,
            zoom: 100,
            ...objectOptions,
            id: undefined,
        };

        if (!objectOptions.left || !objectOptions.top) {
            const configuration = Selectors.getSeatingPlanConfiguration(eventId, state);
            if (configuration) {
                const tablesRequest = state.seatingPlans.fetchTablesRequests[eventId];
                const tables = Selectors.getTableArray(tablesRequest.tables, state);
                const objectsRequest = state.seatingPlans.fetchObjectsRequests[eventId];
                const objects = Selectors.getPlanObjectArray(objectsRequest.objects, state);
                const availableSpace = findAvailableSpace(
                    configuration.areaWidth * 100,
                    configuration.areaHeight * 100,
                    [...tables.map((t) => ({ ...t, margin: getAverageChairDiameter(t.chairs) * 100 })), ...objects.map((o) => ({ ...o, margin: 0 }))],
                    { ...object, margin: 0 },
                );
                object.left = availableSpace.x;
                object.top = availableSpace.y;
            }
        }

        if (hasRight(state, eventId, Right.SeatingPlanUpdate)) {
            const fetchTask = SeatingPlanApi
                .updateObject(eventId, object)
                .then((result) => {
                    const normalizedData = normalize(result.data, ApiSchema.PlanObjectSchema);
                    dispatch(mergeEntities(normalizedData.entities));
                    dispatch(createCreateObjectSuccessAction(eventId, result.id));
                    return {id:result.id, noUpdate:false};
                })
                .catch((error: Error) => {
                    dispatch(createCreateObjectFailureAction(eventId));
                    Logger.logError(error);
                    throw error;
                });
            return fetchTask;
        } else {
            const objectId:number = getFrontFakeId();
            const result = {
                ...object,
                id:objectId,
            }
            const normalizedData = normalize(result, ApiSchema.PlanObjectSchema);
            dispatch(mergeEntities(normalizedData.entities));
            dispatch(createCreateObjectModifySuccessAction(eventId, objectId));
            
            return Promise.resolve({id:objectId, noUpdate:true});
        }
    });
};

export const duplicateObject = (eventId: number, objectId: number): AppThunkAction<IModifyOrUpdateResult> => (dispatch, getState) => {
    const state = getState();
    const object = Selectors.getPlanObject(objectId, state);
    if (!object) {
        Logger.logError(new Error(`Cannot find the object to duplicate: eventId:${eventId}, objectId:${objectId}`));
        return Promise.reject('Cannot find the object to duplicate!');
    }
    return dispatch(createObject(eventId, {
        ...object,
        left: undefined,
        top: undefined,
    }));
};

export const deleteObject = (eventId: number, objectId: number): AppThunkAction => (dispatch, getState) => {
    return dispatch(getRights(eventId)).then(() => {
        const state = getState();
        dispatch(createDeleteObjectAction(eventId, objectId));

        if (hasRight(state, eventId, Right.SeatingPlanUpdate)) {
            const fetchTask = SeatingPlanApi
                .removeObject(eventId, objectId)
                .then(() => {
                    dispatch(createDeleteObjectSuccessAction(eventId, objectId));
                    dispatch(removeEntities(ApiSchema.PlanObjectSchema, [objectId]));
                })
                .catch((error: Error) => {
                    dispatch(createDeleteObjectFailureAction(eventId, objectId));
                    Logger.logError(error);
                    throw error;
                });
            return fetchTask;
        } else {
            dispatch(createDeleteObjectModifySuccessAction(eventId, objectId));
            dispatch(removeEntities(ApiSchema.PlanObjectSchema, [objectId]));
        }
    });
};

export const updateObjectName = (eventId: number, objectId: number, name?: string): AppThunkAction => (dispatch, getState) => {
    const state = getState();
    const oldObject = Selectors.getPlanObject(objectId, state);
    if (oldObject) {
        const newObject = {
            ...oldObject,
            name,
        };
        const normalizedData = normalize(newObject, ApiSchema.PlanObjectSchema);
        dispatch(mergeEntities(normalizedData.entities));

        debouncedUpdateObject(dispatch, eventId, objectId);
    }
    return Promise.resolve();
};

export const updateObjectFit = (eventId: number, objectId: number, fit: ObjectFitType): AppThunkAction => (dispatch, getState) => {
    const state = getState();
    const oldObject = Selectors.getPlanObject(objectId, state);
    if (oldObject) {
        const newObject = {
            ...oldObject,
            fit,
        };
        const normalizedData = normalize(newObject, ApiSchema.PlanObjectSchema);
        dispatch(mergeEntities(normalizedData.entities));

        debouncedUpdateObject(dispatch, eventId, objectId);
    }
    return Promise.resolve();
};

export const updateObjectColor = (eventId: number, objectId: number, color?: string): AppThunkAction => (dispatch, getState) => {
    const state = getState();
    const oldObject = Selectors.getPlanObject(objectId, state);
    if (oldObject) {
        const newObject = {
            ...oldObject,
            color,
        };
        const normalizedData = normalize(newObject, ApiSchema.PlanObjectSchema);
        dispatch(mergeEntities(normalizedData.entities));

        debouncedUpdateObject(dispatch, eventId, objectId);
    }
    return Promise.resolve();
};

export const updateObjectWidth = (eventId: number, objectId: number, width: number): AppThunkAction => (dispatch, getState) => {
    const state = getState();
    const oldObject = Selectors.getPlanObject(objectId, state);
    if (oldObject) {
        const newObject = {
            ...oldObject,
            width,
        };
        const normalizedData = normalize(newObject, ApiSchema.PlanObjectSchema);
        dispatch(mergeEntities(normalizedData.entities));

        debouncedUpdateObject(dispatch, eventId, objectId);
    }
    return Promise.resolve();
};

export const updateObjectHeight = (eventId: number, objectId: number, height: number): AppThunkAction => (dispatch, getState) => {
    const state = getState();
    const oldObject = Selectors.getPlanObject(objectId, state);
    if (oldObject) {
        const newObject = {
            ...oldObject,
            height,
        };
        const normalizedData = normalize(newObject, ApiSchema.PlanObjectSchema);
        dispatch(mergeEntities(normalizedData.entities));

        debouncedUpdateObject(dispatch, eventId, objectId);
    }
    return Promise.resolve();
};

export const updateObjectAngle = (eventId: number, objectId: number, angle: number): AppThunkAction => (dispatch, getState) => {
    const state = getState();
    const oldObject = Selectors.getPlanObject(objectId, state);
    if (oldObject) {
        const newObject = {
            ...oldObject,
            angle,
        };

        const normalizedData = normalize(newObject, ApiSchema.PlanObjectSchema);
        dispatch(mergeEntities(normalizedData.entities));

        debouncedUpdateObject(dispatch, eventId, objectId);
    }
    return Promise.resolve();
};

const updateObject = (eventId: number, objectId: number): AppThunkAction => (dispatch, getState) => {
    return dispatch(getRights(eventId)).then(() => {
        const state = getState();
        const object = Selectors.getPlanObject(objectId, state);
        if (object) {
            dispatch(createUpdateObjectAction(objectId));

            if (hasRight(state, eventId, Right.SeatingPlanUpdate)) {
                const fetchTask = SeatingPlanApi
                    .updateObject(eventId, object)
                    .then(() => {
                        dispatch(createUpdateObjectSuccessAction(objectId));
                    })
                    .catch((error: Error) => {
                        dispatch(createUpdateObjectFailureAction(objectId));
                        Logger.logError(error);
                        throw error;
                    });
                return fetchTask;
            } else {
                dispatch(createUpdateObjectModifySuccessAction(objectId));
            }
        }
        return Promise.reject('Object not found!');
    });
};

const memoizeDebouncedUpdateObjects = memoize((objectId: number) => {
    return debounce((dispatch: AppThunkDispatch, eventId: number) => {
        dispatch(updateObject(eventId, objectId)).catch(void 0);
    }, 300);
});

const debouncedUpdateObject = (dispatch: AppThunkDispatch, eventId: number, objectId: number) => {
    return memoizeDebouncedUpdateObjects(objectId)(dispatch, eventId);
};

const updateConfiguration = (eventId: number): AppThunkAction => (dispatch, getState) => {
    const state = getState();
    const configuration = Selectors.getSeatingPlanConfiguration(eventId, state);
    if (configuration) {
        dispatch(createUpdateSeatingPlanConfigurationAction(eventId));

        const fetchTask = SeatingPlanApi
            .updateConfiguration(eventId, configuration)
            .then(() => {
                dispatch(createUpdateSeatingPlanConfigurationSuccessAction(eventId));
            })
            .catch((error: Error) => {
                dispatch(createUpdateSeatingPlanConfigurationFailureAction(eventId));
                Logger.logError(error);
                throw error;
            });
        return fetchTask;
    }
    return Promise.reject('Area not found!');
};

export const debouncedUpdateConfiguration = debounce((dispatch: AppThunkDispatch, eventId: number) => {
    dispatch(updateConfiguration(eventId)).catch(void 0);
}, 300);
