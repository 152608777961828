import { normalize } from 'normalizr';
import { InformationApi } from 'services/api';
import { Place, Presentation } from 'services/api/ApiClient';
import { ApiSchema } from 'services/api/ApiSchema';
import { Logger } from 'services/Logger';
import { PromiseStore } from 'services/PromiseStore';
import { SSRService } from 'services/ServerSideRenderingService';
import { AppThunkAction } from 'store';
import { mergeEntities, removeEntities } from 'store/normalizr/actions';
import { Selectors } from 'store/normalizr/selectors';
import {
    createDeletePlaceAction,
    createDeletePlaceFailureAction,
    createDeletePlaceSuccessAction,
    createGetInformationAction,
    createGetInformationFailureAction,
    createGetInformationSuccessAction,
    createUpdateInformationAction,
    createUpdateInformationFailureAction,
    createUpdateInformationSuccessAction,
    createUpdatePlaceAction,
    createUpdatePlaceFailureAction,
    createUpdatePlaceSuccessAction,
} from './actions';

export const getInformation = (eventId: number): AppThunkAction<boolean> => (dispatch, getState) => {
    const state = getState();

    const request = state.information.informationDetailsRequests[eventId];
    if (request && (request.isFetching || !request.didInvalidate)) {
        return PromiseStore.get('getInformation', eventId);
    }

    dispatch(createGetInformationAction(eventId));

    const fetchTask = InformationApi
        .getInformation(eventId)
        .then((data) => {
            const normalizedData = normalize(data, ApiSchema.JsonInformationSchema);
            dispatch(mergeEntities(normalizedData.entities));
            dispatch(createGetInformationSuccessAction(eventId));
            return !data.presentation?.value && !data.hotels?.length && !data.access?.length;
        })
        .catch((error: Error) => {
            dispatch(createGetInformationFailureAction(eventId));
            Logger.logError(error);
            throw error;
        });

    SSRService.addTask(fetchTask, 'getInformation');
    PromiseStore.set(fetchTask, 'getInformation', eventId);

    return fetchTask;
};

export const updateInformation = (eventId: number, presentation: Presentation): AppThunkAction => (dispatch, getState) => {
    const state = getState();

    const request = state.information.informationUpdateRequests[eventId];
    if (request) {
        return PromiseStore.get('updateInformation', eventId);
    }

    dispatch(createUpdateInformationAction(eventId));

    const fetchTask = InformationApi
        .updatePresentation(eventId, presentation)
        .then(() => {
            const information = Selectors.getJsonInformation(eventId, state);
            if (information) {
                information.presentation = presentation;
                const normalizedData = normalize(information, ApiSchema.JsonInformationSchema);
                dispatch(mergeEntities(normalizedData.entities));
            }
            dispatch(createUpdateInformationSuccessAction(eventId));
        })
        .catch((error: Error) => {
            dispatch(createUpdateInformationFailureAction(eventId));
            Logger.logError(error);
            throw error;
        });

    SSRService.addTask(fetchTask, 'updateInformation');
    PromiseStore.set(fetchTask, 'updateInformation', eventId);

    return fetchTask;
};

export const deletePlace = (eventId: number, placeId: number): AppThunkAction => (dispatch, getState) => {
    const state = getState();

    const request = state.information.placeDeleteRequests[placeId];
    if (request) {
        return PromiseStore.get('deletePlace', eventId, placeId);
    }

    dispatch(createDeletePlaceAction(placeId));

    const fetchTask = InformationApi
        .remove(eventId, placeId)
        .then(() => {
            dispatch(removeEntities(ApiSchema.PlaceSchema, [placeId]));
            dispatch(createDeletePlaceSuccessAction(placeId, eventId));
        })
        .catch((error: Error) => {
            dispatch(createDeletePlaceFailureAction(placeId));
            Logger.logError(error);
            throw error;
        });

    SSRService.addTask(fetchTask, 'deletePlace');
    PromiseStore.set(fetchTask, 'deletePlace', eventId, placeId);

    return fetchTask;
};

export const updatePlace = (eventId: number, place: Place, tempId?: number): AppThunkAction => (dispatch, getState) => {
    const state = getState();

    const placeId = place.id || tempId || -1;
    const request = state.information.placeUpdateRequests[placeId];
    if (request) {
        return PromiseStore.get('updatePlace', eventId, placeId);
    }

    dispatch(createUpdatePlaceAction(placeId));

    const fetchTask = InformationApi
        .updatePlace(eventId, place)
        .then(() => {
            if (place.id) {
                const normalizedData = normalize(place, ApiSchema.PlaceSchema);
                dispatch(mergeEntities(normalizedData.entities));
            }
            dispatch(createUpdatePlaceSuccessAction(placeId, eventId));
        })
        .catch((error: Error) => {
            dispatch(createUpdatePlaceFailureAction(placeId));
            Logger.logError(error);
            throw error;
        });

    SSRService.addTask(fetchTask, 'updatePlace');
    PromiseStore.set(fetchTask, 'updatePlace', eventId, placeId);

    return fetchTask;
};
