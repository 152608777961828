import { AppReducer } from 'store';

export interface IRobotState {
    readonly visible: boolean;
    readonly state: RobotState[];
}

export enum RobotState {
    Startup = 'startup',
    Computing = 'computing',
    Shutdown = 'shutdown',
}

const unloadedState = () => {
    return {
    } as { readonly [robotId: string]: IRobotState };
};

export type RobotsState = ReturnType<typeof unloadedState>;

export const RobotsReducer: AppReducer<RobotsState> = (state = unloadedState(), action) => {
    switch (action.type) {
        case '@ROBOTS/SHOW':
            return {
                ...state,
                [action.robotId]: {
                    ...state[action.robotId],
                    state: state[action.robotId]?.state || [],
                    visible: true,
                },
            };

        case '@ROBOTS/HIDE':
            return {
                ...state,
                [action.robotId]: {
                    ...state[action.robotId],
                    state: state[action.robotId]?.state || [],
                    visible: false,
                },
            };

        case '@ROBOTS/START':
            return {
                ...state,
                [action.robotId]: {
                    ...state[action.robotId],
                    state: [RobotState.Startup, RobotState.Computing],
                },
            };

        case '@ROBOTS/STOP':
            return {
                ...state,
                [action.robotId]: {
                    ...state[action.robotId],
                    state: [RobotState.Shutdown],
                },
            };

        default:
            return state;
    }
};
