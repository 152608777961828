import { AppReducer } from 'store';
import { IRequest } from 'store/SharedModels';

export const DEFAULT_THEMES_TAKE_SIZE = 5;

export interface IThemesRequest extends IRequest {
    readonly themes: number[];
}

const unloadedState = () => {
    return {
        themes: {
            isFetching: false,
            didInvalidate: true,
            themes: [],
        } as IThemesRequest,
    } as const;
};

export type ThemesState = ReturnType<typeof unloadedState>;

export const ThemesReducer: AppReducer<ThemesState> = (state = unloadedState(), action) => {
    switch (action.type) {
        case '@THEMES/GET_THEMES_REQUEST':
            return {
                ...state,
                themes: {
                    ...state.themes,
                    isFetching: true,
                },
            };

        case '@THEMES/GET_THEMES_SUCCESS':
            return {
                ...state,
                themes: {
                    ...state.themes,
                    isFetching: false,
                    didInvalidate: false,
                    themes: action.themes,
                },
            };

        case '@THEMES/GET_THEMES_FAILURE':
            return {
                ...state,
                themes: {
                    ...state.themes,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        default:
            return state;
    }
};
