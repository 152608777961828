
import { AppReducer } from 'store';

export interface IImportedValue {
    readonly data: string[];
    readonly isSaving: boolean;
    readonly isSaved: boolean;
    readonly error?: string;
}

export enum Encoding {
    UTF8 = 'UTF-8',
    UTF16 = 'UTF-16',
    ANSI = 'windows-1252',
}

export enum CsvHeaderType {
    IgnoredData = 'IgnoredData',
    Sex = 'Sex',
    FirstName = 'FirstName',
    LastName = 'LastName',
    Email = 'Email',
    Address = 'Address',
    Presence = 'Presence',
    Age = 'Age',
    Information = 'Information',
}

const unloadedState = () => {
    return {
        isImporting: false as boolean,
        file: undefined as File | undefined,
        encoding: Encoding.UTF8 as Encoding,
        header: [CsvHeaderType.Sex, CsvHeaderType.FirstName, CsvHeaderType.LastName, CsvHeaderType.Email, CsvHeaderType.Address, CsvHeaderType.Presence, CsvHeaderType.Age, CsvHeaderType.Information] as CsvHeaderType[],
        guests: [] as IImportedValue[],
    } as const;
};

export type GuestsCsvImportState = ReturnType<typeof unloadedState>;

export const GuestsCsvImportReducer: AppReducer<GuestsCsvImportState> = (state = unloadedState(), action) => {
    switch (action.type) {
        case '@GUESTS_CSV_IMPORT/IMPORT_FILE':
            return {
                ...state,
                isImporting: true,
                file: action.file,
            };

        case '@GUESTS_CSV_IMPORT/IMPORT_FILE_SUCCESS':
            return {
                ...state,
                isImporting: false,
                guests: action.guests.map<IImportedValue>((data) => ({
                    data,
                    isSaved: false,
                    isSaving: false,
                })),
            };

        case '@GUESTS_CSV_IMPORT/IMPORT_FILE_FAILURE':
            return {
                ...state,
                isImporting: false,
            };

        case '@GUESTS_CSV_IMPORT/CHANGE_ENCODING':
            return {
                ...state,
                encoding: action.encoding,
            };

        case '@GUESTS_CSV_IMPORT/REMOVE_GUEST':
            return {
                ...state,
                guests: state.guests.filter((_, index) => index !== action.index),
            };

        case '@GUESTS_CSV_IMPORT/CHANGE_DATA':
            return {
                ...state,
                guests: state.guests.map((g, index) => {
                    if (index === action.rowIndex) {
                        const newGuest = {
                            ...g,
                            data: [
                                ...g.data,
                            ],
                        };
                        newGuest.data[action.colIndex] = action.value;
                        return newGuest;
                    }
                    return g;
                }),
            };

        case '@GUESTS_CSV_IMPORT/MODIFY_GUEST':
            return {
                ...state,
                guests: state.guests.map((g, index) => {
                    if (index === action.index) {
                        return {
                            ...g,
                            isSaved: false,
                        };
                    }
                    return g;
                }),
            };

        case '@GUESTS_CSV_IMPORT/CORRECT_GUEST':
            return {
                ...state,
                guests: state.guests.map((g, index) => {
                    if (index === action.index) {
                        return {
                            ...g,
                            error: undefined,
                        };
                    }
                    return g;
                }),
            };

        case '@GUESTS_CSV_IMPORT/SWAP_HEADERS':
            {
                const header = [...state.header];
                if (action.newHeader != CsvHeaderType.IgnoredData) {
                    const newHeaderIndex = header.indexOf(action.newHeader);
                    header[newHeaderIndex] = action.oldHeader;
                }
                header[action.index] = action.newHeader;
                return {
                    ...state,
                    header,
                };
            }

        case '@GUESTS_CSV_IMPORT/CREATE_GUESTS':
            return {
                ...state,
                guests: state.guests.map((g) => {
                    if (!g.isSaved && !g.isSaving && !g.error) {
                        return {
                            ...g,
                            isSaving: true,
                        };
                    }
                    return g;
                }),
            };

        case '@GUESTS_CSV_IMPORT/CREATE_GUEST_SUCCESS':
            return {
                ...state,
                guests: state.guests.map((g, index) => {
                    if (index === action.index) {
                        return {
                            ...g,
                            isSaving: false,
                            isSaved: true,
                        };
                    }
                    return g;
                }),
            };

        case '@GUESTS_CSV_IMPORT/CREATE_GUEST_FAILURE':
            return {
                ...state,
                guests: state.guests.map((g, index) => {
                    if (index === action.index) {
                        return {
                            ...g,
                            isSaving: false,
                            error: action.error,
                        };
                    }
                    return g;
                }),
            };

        default:
            return state;
    }
};
