import { RobotList } from 'config/constants';
import i18next from 'i18next';
import { debounce, memoize } from 'lodash-es';
import { normalize } from 'normalizr';
import { toastr } from 'react-redux-toastr';
import { SeatingPlanResolverApi } from 'services/api';
import { ConstraintReportMode, GroupModel, Right, SPResolverCriterionModel, SPResolverGroupType, StringSaveResult, TableSaveResult } from 'services/api/ApiClient';
import { ApiSchema } from 'services/api/ApiSchema';
import { Logger } from 'services/Logger';
import { PromiseStore } from 'services/PromiseStore';
import { SSRService } from 'services/ServerSideRenderingService';
import { AppThunkAction, AppThunkDispatch } from 'store';
import { mergeEntities, removeEntities } from 'store/normalizr/actions';
import { Selectors } from 'store/normalizr/selectors';
import { hasRight, getRights } from 'store/rights/thunk';
import { createHideRobotAction, createShowRobotAction, createStartRobotAction, createStopRobotAction } from 'store/robots/actions';
import { updateTable } from 'store/seatingPlans/thunk';
import {
    createCreateGroupRequestAction,
    createCreateGroupRequestFailureAction,
    createCreateGroupRequestSuccessAction,
    createDeleteGroupRequestAction,
    createDeleteGroupRequestFailureAction,
    createDeleteGroupRequestSuccessAction,
    createGetAffinityGroupsRequestAction,
    createGetAffinityGroupsRequestFailureAction,
    createGetAffinityGroupsRequestSuccessAction,
    createGetConfigurationRequestAction,
    createGetConfigurationRequestFailureAction,
    createGetConfigurationRequestSuccessAction,
    createGetConstraintReportsRequestAction,
    createGetConstraintReportsRequestFailureAction,
    createGetConstraintReportsRequestSuccessAction,
    createImproveSeatingPlanFailureAction,
    createImproveSeatingPlanRequestAction,
    createImproveSeatingPlanSuccessAction,
    createInvalidateImprovementResultAction,
    createSaveConfigurationRequestAction,
    createSaveConfigurationRequestFailureAction,
    createSaveConfigurationRequestSuccessAction,
    createUpdateGroupAction,
    createUpdateGroupFailureAction,
    createUpdateGroupSuccessAction,
    createAddGuestAffinityRequestAction,
    createAddGuestAffinityRequestFailureAction,
    createAddGuestAffinityRequestSuccessAction,
    createRemoveGuestAffinityRequestAction,
    createRemoveGuestAffinityRequestFailureAction,
    createRemoveGuestAffinityRequestSuccessAction,
} from './actions';

export const getConstraintReport = (eventId: number): AppThunkAction => (dispatch, getState) => {
    const state = getState();

    const request = state.constraintReports.constraintReportsRequests[eventId];
    if (request && (request.isFetching || !request.didInvalidate)) {
        return PromiseStore.get('getConstraintReport', eventId);
    }

    dispatch(createGetConstraintReportsRequestAction(eventId));

    const fetchTask = SeatingPlanResolverApi
        .checkConstraints(eventId, ConstraintReportMode.GroupedBySeverity)
        .then((data) => {
            if (data) {
                const normalizedData = normalize(data, ApiSchema.ConstraintsReportModelSaveResultSchema);
                dispatch(mergeEntities(normalizedData.entities));
            }
            dispatch(createGetConstraintReportsRequestSuccessAction(eventId, data.data?.nextUsageDate && new Date(data.data?.nextUsageDate) || undefined));
        })
        .catch((error: Error) => {
            dispatch(createGetConstraintReportsRequestFailureAction(eventId));
            Logger.logError(error);
            throw error;
        });

    SSRService.addTask(fetchTask, 'getConstraintReport');
    PromiseStore.set(fetchTask, 'getConstraintReport', eventId);

    return fetchTask;
};

export const getConfiguration = (eventId: number): AppThunkAction => (dispatch, getState) => {
    const state = getState();

    const request = state.constraintReports.fetchConfigurationRequests[eventId];
    if (request && (request.isFetching || !request.didInvalidate)) {
        return PromiseStore.get('getConfiguration', eventId);
    }

    dispatch(createGetConfigurationRequestAction(eventId));

    const fetchTask = SeatingPlanResolverApi
        .getConfiguration(eventId)
        .then((data) => {
            if (data) {
                const normalizedData = normalize(data, ApiSchema.SPResolverCriterionModelSchema);
                dispatch(mergeEntities(normalizedData.entities));
            }
            dispatch(createGetConfigurationRequestSuccessAction(eventId));
        })
        .catch((error: Error) => {
            dispatch(createGetConfigurationRequestFailureAction(eventId));
            Logger.logError(error);
            throw error;
        });

    SSRService.addTask(fetchTask, 'getConfiguration');
    PromiseStore.set(fetchTask, 'getConfiguration', eventId);

    return fetchTask;
};

export const saveConfiguration = (eventId: number, configuration: SPResolverCriterionModel): AppThunkAction => (dispatch, getState) => {
    return dispatch(getRights(eventId)).then(() => {
        const state = getState();

        if (!hasRight(state, eventId, Right.SeatingPlanUpdate)) {
            return Promise.reject('Unauthorized!');
        }

        const request = state.constraintReports.saveConfigurationRequests[eventId];
        if (request) {
            return PromiseStore.get('saveConfiguration', eventId);
        }

        dispatch(createSaveConfigurationRequestAction(eventId));

        const fetchTask = SeatingPlanResolverApi
            .saveConfiguration(eventId, configuration)
            .then(() => {
                const normalizedData = normalize(configuration, ApiSchema.SPResolverCriterionModelSchema);
                dispatch(mergeEntities(normalizedData.entities));
                dispatch(createSaveConfigurationRequestSuccessAction(eventId));
            })
            .catch((error: Error) => {
                dispatch(createSaveConfigurationRequestFailureAction(eventId));
                Logger.logError(error);
                throw error;
            });

        SSRService.addTask(fetchTask, 'saveConfiguration');
        PromiseStore.set(fetchTask, 'saveConfiguration', eventId);

        return fetchTask;
    });
};

export const getGroups = (eventId: number): AppThunkAction => (dispatch, getState) => {
    const state = getState();

    const request = state.constraintReports.fetchGroupsRequests[eventId];
    if (request && (request.isFetching || !request.didInvalidate)) {
        return PromiseStore.get('getGroups', eventId);
    }

    dispatch(createGetAffinityGroupsRequestAction(eventId));

    const fetchTask = SeatingPlanResolverApi
        .loadGroups(eventId)
        .then((data) => {
            const normalizedData = normalize(data, ApiSchema.GroupModelSchemaArray);
            dispatch(mergeEntities(normalizedData.entities));
            dispatch(createGetAffinityGroupsRequestSuccessAction(eventId, normalizedData.result));
        })
        .catch((error: Error) => {
            dispatch(createGetAffinityGroupsRequestFailureAction(eventId));
            Logger.logError(error);
            throw error;
        });

    SSRService.addTask(fetchTask, 'getGroups');
    PromiseStore.set(fetchTask, 'getGroups', eventId);

    return fetchTask;
};

export const createGroup = (eventId: number, groupType: SPResolverGroupType): AppThunkAction => (dispatch, getState) => {
    const state = getState();

    const request = state.constraintReports.createGroupRequests[eventId];
    if (request) {
        return PromiseStore.get('createGroup', eventId);
    }

    dispatch(createCreateGroupRequestAction(eventId));

    const newGroup: GroupModel = {
        type: groupType,
    };
    const fetchTask = SeatingPlanResolverApi
        .updateGroup(eventId, newGroup)
        .then((data) => {
            newGroup.id = data.id;
            const normalizedData = normalize(newGroup, ApiSchema.GroupModelSchema);
            dispatch(mergeEntities(normalizedData.entities));
            dispatch(createCreateGroupRequestSuccessAction(eventId, data.id));
        })
        .catch((error: Error) => {
            dispatch(createCreateGroupRequestFailureAction(eventId));
            Logger.logError(error);
            throw error;
        });

    SSRService.addTask(fetchTask, 'createGroup');
    PromiseStore.set(fetchTask, 'createGroup', eventId);

    return fetchTask;
};

export const deleteGroup = (eventId: number, groupId: number): AppThunkAction => (dispatch, getState) => {
    const state = getState();

    const request = state.constraintReports.deleteGroupRequests[groupId];
    if (request) {
        return PromiseStore.get('deleteGroup', eventId, groupId);
    }

    dispatch(createDeleteGroupRequestAction(eventId, groupId));

    const fetchTask = SeatingPlanResolverApi
        .removeGroup(eventId, groupId)
        .then(() => {
            dispatch(createDeleteGroupRequestSuccessAction(eventId, groupId));
            dispatch(removeEntities(ApiSchema.GroupModelSchema, [groupId]));
        })
        .catch((error: Error) => {
            dispatch(createDeleteGroupRequestFailureAction(eventId, groupId));
            Logger.logError(error);
            throw error;
        });

    SSRService.addTask(fetchTask, 'deleteGroup');
    PromiseStore.set(fetchTask, 'deleteGroup', eventId, groupId);

    return fetchTask;
};

export const updateGroupName = (eventId: number, groupId: number, name?: string): AppThunkAction => (dispatch, getState) => {
    return dispatch(getRights(eventId)).then(() => {
        const state = getState();

        if (!hasRight(state, eventId, Right.SeatingPlanUpdate)) {
            return Promise.reject('Unauthorized!');
        }

        const oldGroup = Selectors.getGroupModel(groupId, state);
        if (oldGroup) {
            const newGroup = {
                ...oldGroup,
                name,
            };
            const normalizedData = normalize(newGroup, ApiSchema.GroupModelSchema);
            dispatch(mergeEntities(normalizedData.entities));

            debouncedUpdateGroup(dispatch, eventId, groupId);
        }
        return Promise.resolve();
    });
};

export const updateGroupGuests = (eventId: number, groupId: number, guestIds: number[]): AppThunkAction => (dispatch, getState) => {
    return dispatch(getRights(eventId)).then(() => {
        const state = getState();

        if (!hasRight(state, eventId, Right.SeatingPlanUpdate)) {
            return Promise.reject('Unauthorized!');
        }

        const request = state.constraintReports.updateGroupRequests[groupId];
        if (request) {
            return PromiseStore.get('updateGroupGuests', eventId, groupId);
        }

        dispatch(createUpdateGroupAction(eventId, groupId));

        const oldGroup = Selectors.getGroupModel(groupId, state);

        if (!oldGroup) {
            return Promise.reject('Group not found!');
        }

        const guestsToAdd = guestIds.filter((id) => !oldGroup.guests?.includes(id));
        const guestsToRemove = oldGroup.guests?.filter((id) => !guestIds.includes(id)) || [];

        const tasks = [
            ...guestsToAdd.map((guestId) => SeatingPlanResolverApi.addGroupGuest(eventId, groupId, guestId)),
            ...guestsToRemove.map((guestId) => SeatingPlanResolverApi.removeGroupGuest(eventId, groupId, guestId)),
        ];

        const newGroup = {
            ...oldGroup,
            guests: guestIds,
        };
        const normalizedData = normalize(newGroup, ApiSchema.GroupModelSchema);
        dispatch(mergeEntities(normalizedData.entities));

        const fetchTask = Promise
            .all(tasks)
            .then(() => {
                dispatch(createUpdateGroupSuccessAction(eventId, groupId));
            })
            .catch((error: Error) => {
                dispatch(createUpdateGroupFailureAction(eventId, groupId));

                const normalizedOldData = normalize(oldGroup, ApiSchema.GroupModelSchema);
                dispatch(mergeEntities(normalizedOldData.entities));

                Logger.logError(error);
                throw error;
            });

        SSRService.addTask(fetchTask, 'updateGroupGuests');
        PromiseStore.set(fetchTask, 'updateGroupGuests', eventId, groupId);

        return fetchTask;
    });
};

export const improveSeatingPlan = (eventId: number): AppThunkAction => (dispatch, getState) => {
    const state = getState();

    const request = state.constraintReports.improveRequests[eventId];
    if (request && request.isFetching) {
        return PromiseStore.get('improveSeatingPlan', eventId);
    }

    dispatch(createShowRobotAction(RobotList.SPNono));
    dispatch(createStartRobotAction(RobotList.SPNono));

    dispatch(createImproveSeatingPlanRequestAction(eventId));

    const fetchTask = SeatingPlanResolverApi
        .improve(eventId)
        .then((data) => {
            dispatch(createStopRobotAction(RobotList.SPNono));
            if (data.data) {
                const normalizedData = normalize(data.data, ApiSchema.ImprovementResultSchema);
                dispatch(mergeEntities(normalizedData.entities));

                if (data.data.isSuccess) {
                    dispatch(createImproveSeatingPlanSuccessAction(eventId, normalizedData.result, data.data?.nextUsageDate && new Date(data.data?.nextUsageDate) || undefined));
                    toastr.success(
                        i18next.t('Common:Success'),
                        i18next.t('_SeatingPlan:SeatingPlanResolverController_Improve_I_tried__0__different_solutions__and_I_found_a_better_one__D', data.data),
                    );
                } else {
                    dispatch(createImproveSeatingPlanFailureAction(eventId));
                    toastr.error(
                        i18next.t('Common:Failure'),
                        i18next.t('_SeatingPlan:SeatingPlanResolverController_Improve_I_tried__0__different_solutions__but_I_didn_t_find_a_better_one___',
                            {
                                ...data.data,
                                solutionsTried: data.data.solutionsTried || 1,
                            }),
                    );
                }
            } else {
                dispatch(createImproveSeatingPlanFailureAction(eventId));
                toastr.error(
                    i18next.t('Common:Error'),
                    data.missingFeature ? i18next.t('Common:Access_denied') : i18next.t('Common:Failure'));
            }
        })
        .catch((error: Error) => {
            dispatch(createImproveSeatingPlanFailureAction(eventId));
            Logger.logError(error);
            throw error;
        })
        .finally(() => {
            dispatch(createHideRobotAction(RobotList.SPNono));
        });

    SSRService.addTask(fetchTask, 'improveSeatingPlan');
    PromiseStore.set(fetchTask, 'improveSeatingPlan', eventId);

    return fetchTask;
};

export const cancelImprovement = (eventId: number): AppThunkAction => (dispatch) => {
    dispatch(createInvalidateImprovementResultAction(eventId));
    return Promise.resolve();
};

export const applyImprovement = (eventId: number): AppThunkAction => (dispatch, getState) => {
    const state = getState();

    const request = state.constraintReports.improveRequests[eventId];
    if (!request || !request.improvementResult || request.didInvalidate) {
        return Promise.resolve();
    }

    dispatch(createShowRobotAction(RobotList.SPNono));
    dispatch(createStartRobotAction(RobotList.SPNono));

    const tasks: Array<Promise<TableSaveResult>> = [];

    const improvementResult = Selectors.getImprovementResult(request.improvementResult, state);
    if (improvementResult) {
        const tableIds = improvementResult.tables?.map((t) => t.id) || [];
        const tables = Selectors.getTableArray(tableIds, state);

        improvementResult.tables?.forEach((improvedTable) => {
            const table = tables.find((t) => t.id === improvedTable.id);
            if (table) {
                table.chairs?.forEach((chair) => {
                    const improvedTableGuests = improvedTable.guests;
                    chair.guestId = improvedTableGuests && improvedTableGuests.length >= chair.number ? improvedTableGuests[chair.number - 1] : undefined;
                });

                const normalizedData = normalize(table, ApiSchema.TableSchema);
                dispatch(mergeEntities(normalizedData.entities));

                tasks.push(dispatch(updateTable(eventId, table)));
            }
        });
    }

    return Promise.all(tasks)
        .then(() => {
            dispatch(createInvalidateImprovementResultAction(eventId));
            dispatch(createStopRobotAction(RobotList.SPNono));
            dispatch(createHideRobotAction(RobotList.SPNono));
        });
};

export const addGuestAffinity = (eventId: number, guestId: number, type: SPResolverGroupType): AppThunkAction => (dispatch, getState) => {
    const state = getState();

    const request = state.constraintReports.updateGuestAffinityRequests[guestId];
    if (request) {
        return PromiseStore.get('addGuestAffinity', eventId, guestId);
    }

    dispatch(createAddGuestAffinityRequestAction(eventId, guestId));

    const fetchTask = SeatingPlanResolverApi
        .addGuestAffinity(eventId, guestId, type)
        .then(() => {
            dispatch(createAddGuestAffinityRequestSuccessAction(eventId, guestId));
        })
        .catch((error: Error) => {
            dispatch(createAddGuestAffinityRequestFailureAction(eventId, guestId));
            Logger.logError(error);
            throw error;
        });

    SSRService.addTask(fetchTask, 'addGuestAffinity');
    PromiseStore.set(fetchTask, 'addGuestAffinity', eventId, guestId);

    return fetchTask;
};

export const removeGuestAffinity = (eventId: number, guestId: number): AppThunkAction => (dispatch, getState) => {
    const state = getState();

    const request = state.constraintReports.updateGuestAffinityRequests[guestId];
    if (request) {
        return PromiseStore.get('removeGuestAffinity', eventId, guestId);
    }

    dispatch(createRemoveGuestAffinityRequestAction(eventId, guestId));

    const fetchTask = SeatingPlanResolverApi
        .removeGuestAffinity(eventId, guestId)
        .then(() => {
            dispatch(createRemoveGuestAffinityRequestSuccessAction(eventId, guestId));
        })
        .catch((error: Error) => {
            dispatch(createRemoveGuestAffinityRequestFailureAction(eventId, guestId));
            Logger.logError(error);
            throw error;
        });

    SSRService.addTask(fetchTask, 'removeGuestAffinity');
    PromiseStore.set(fetchTask, 'removeGuestAffinity', eventId, guestId);

    return fetchTask;
};

const updateGroupById = (eventId: number, groupId: number): AppThunkAction<StringSaveResult> => (dispatch, getState) => {
    const state = getState();
    const group = Selectors.getGroupModel(groupId, state);
    if (!group) {
        return Promise.reject('Group not found!');
    }
    return dispatch(updateGroup(eventId, group));
};

const updateGroup = (eventId: number, group: GroupModel): AppThunkAction<StringSaveResult> => (dispatch) => {
    if (group && group.id) {
        const groupId = group.id;
        dispatch(createUpdateGroupAction(eventId, groupId));

        const fetchTask = SeatingPlanResolverApi
            .updateGroup(eventId, group)
            .then((result) => {
                dispatch(createUpdateGroupSuccessAction(eventId, groupId));
                return result;
            })
            .catch((error: Error) => {
                dispatch(createUpdateGroupFailureAction(eventId, groupId));
                Logger.logError(error);
                throw error;
            });
        return fetchTask;
    }
    return Promise.reject('Group not found!');
};

const memoizeDebouncedUpdateGroups = memoize((groupId: number) => {
    return debounce((dispatch: AppThunkDispatch, eventId: number) => {
        dispatch(updateGroupById(eventId, groupId)).catch(void 0);
    }, 300);
});

const debouncedUpdateGroup = (dispatch: AppThunkDispatch, eventId: number, groupId: number) => {
    return memoizeDebouncedUpdateGroups(groupId)(dispatch, eventId);
};
