import { isArray } from 'lodash-es';

export class PromiseStore {
    public static set(promise: Promise<unknown>, ...keyArgs: (string | number | undefined)[]) {
        const key = PromiseStore.getKey(keyArgs);
        PromiseStore.store[key] = promise;
    }

    public static get<T>(...keyArgs: (string | number | undefined)[]) {
        const key = PromiseStore.getKey(keyArgs);
        return PromiseStore.store[key] as Promise<T> || Promise.resolve<T | undefined>(undefined);
    }

    private static store: { [key: string]: Promise<unknown> } = {};

    private static getKey(keyArgs: (string | number | undefined)[]) {
        const reducedKeyArgs = keyArgs && keyArgs.map((keyArg) => {
            if (keyArg && isArray(keyArg)) {
                return keyArg.sort().join('-');
            }
            return keyArg;
        }) || [];
        return reducedKeyArgs.length ? reducedKeyArgs.join('_') : '';
    }
}
