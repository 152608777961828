import { AppReducer } from 'store';
import { IRequest } from 'store/SharedModels';

export interface IBlogArticleListRequest extends IRequest { readonly articles: number[] }
export interface IBlogArticleRequest extends IRequest { readonly articleId?: number }

const unloadedState = () => {
    return {
        blogArticleListRequests: {} as { readonly [lang: string]: IBlogArticleListRequest },
        blogArticleRequests: {} as {
            readonly [articleKey: string]: {
                readonly [lang: string]: IBlogArticleRequest,
            },
        },
    } as const;
};

export type BlogState = ReturnType<typeof unloadedState>;

export const BlogReducer: AppReducer<BlogState> = (state = unloadedState(), action) => {
    switch (action.type) {
        case '@BLOG/GET_BLOG_ARTICLE_LIST_REQUEST':
            return {
                ...state,
                blogArticleListRequests: {
                    ...state.blogArticleListRequests,
                    [action.lang.toLowerCase()]: {
                        ...state.blogArticleListRequests[action.lang.toLowerCase()],
                        articles: state.blogArticleListRequests[action.lang.toLowerCase()]?.articles || [],
                        didInvalidate: state.blogArticleListRequests[action.lang.toLowerCase()]?.didInvalidate || false,
                        isFetching: true,
                    },
                },
            };

        case '@BLOG/GET_BLOG_ARTICLE_LIST_SUCCESS':
            return {
                ...state,
                blogArticleListRequests: {
                    ...state.blogArticleListRequests,
                    [action.lang.toLowerCase()]: {
                        ...state.blogArticleListRequests[action.lang.toLowerCase()],
                        didInvalidate: false,
                        isFetching: false,
                        articles: action.articles,
                    },
                },
            };

        case '@BLOG/GET_BLOG_ARTICLE_LIST_FAILURE':
            return {
                ...state,
                blogArticleListRequests: {
                    ...state.blogArticleListRequests,
                    [action.lang.toLowerCase()]: {
                        ...state.blogArticleListRequests[action.lang.toLowerCase()],
                        didInvalidate: true,
                        isFetching: false,
                    },
                },
            };

        case '@BLOG/GET_BLOG_ARTICLE_REQUEST':
            return {
                ...state,
                blogArticleRequests: {
                    ...state.blogArticleRequests,
                    [action.articleKey.toLowerCase()]: {
                        ...state.blogArticleRequests[action.articleKey.toLowerCase()],
                        [action.lang.toLowerCase()]: {
                            ...state.blogArticleRequests[action.articleKey.toLowerCase()]?.[action.lang.toLowerCase()],
                            didInvalidate: state.blogArticleRequests[action.articleKey.toLowerCase()]?.[action.lang.toLowerCase()]?.didInvalidate || false,
                            isFetching: true,
                        },
                    },
                },
            };

        case '@BLOG/GET_BLOG_ARTICLE_SUCCESS':
            return {
                ...state,
                blogArticleRequests: {
                    ...state.blogArticleRequests,
                    [action.articleKey.toLowerCase()]: {
                        ...state.blogArticleRequests[action.articleKey.toLowerCase()],
                        [action.lang.toLowerCase()]: {
                            ...state.blogArticleRequests[action.articleKey.toLowerCase()]?.[action.lang.toLowerCase()],
                            didInvalidate: false,
                            isFetching: false,
                            articleId: action.articleId,
                        },
                    },
                },
            };

        case '@BLOG/GET_BLOG_ARTICLE_FAILURE':
            return {
                ...state,
                blogArticleRequests: {
                    ...state.blogArticleRequests,
                    [action.articleKey.toLowerCase()]: {
                        ...state.blogArticleRequests[action.articleKey.toLowerCase()],
                        [action.lang.toLowerCase()]: {
                            ...state.blogArticleRequests[action.articleKey.toLowerCase()]?.[action.lang.toLowerCase()],
                            didInvalidate: true,
                            isFetching: false,
                        },
                    },
                },
            };

        default:
            return state;
    }
};
